import React, { useEffect, useRef } from 'react';
import { Modal, Form, Button, Radio, Space, Input } from 'antd';
import { injectIntl } from 'react-intl';
import Icon from '@ant-design/icons';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';

import { lockUnlockDeliveryItem, callDeliveryItem } from 'services/live-ops';
import {
  ENTER_CONSIGNEE_FEEDBACK,
  UNASSIGN_LIVE_OPS
} from 'constants/live-ops';

import { notify } from 'components/Notify/Notify';

import { ReactComponent as CloseIcon } from 'assets/imgRevamp/CloseIcon.svg';

import './CallModal.less';

const CallModal = (props) => {
  const { intl, close, id, refTableList, refCard, onSuccess } = props;
  const formRef = useRef();

  const unlockService = async () => {
    const payload = {
      updateType: UNASSIGN_LIVE_OPS
    };
    try {
      await lockUnlockDeliveryItem(id, payload);
    } catch (err) {
      notify(err.message);
    }
  };

  const handleOnOk = async (values) => {
    let payload = {
      actionType: ENTER_CONSIGNEE_FEEDBACK,
      ...pickBy({ ...values }, identity),
      consigneeReached: values.consigneeReached === '0' ? false : true
    };

    try {
      const res = await callDeliveryItem(id, payload);
      notify(res.message, 'success');
      if (refTableList) {
        refTableList.current();
        // refCard();
      }
      if (onSuccess) {
        onSuccess();
      }
      close();
    } catch (err) {
      notify(err.message);
    }
  };

  const handleOnCancel = () => {
    unlockService();
    close();
  };

  return (
    <Modal
      {...props}
      wrapClassName="br-call-modal"
      title={null}
      footer={null}
      width={null}
      closeIcon={
        <Icon component={CloseIcon} onClick={() => handleOnCancel()} />
      }
    >
      <>
        <div className="br-call-modal__heading">
          {intl.formatMessage({
            id: 'live_ops.call_modal.title'
          })}
        </div>
      </>
      <Form ref={formRef} className="br-call-modal__form" onFinish={handleOnOk}>
        <Form.Item
          name="consigneeReached"
          rules={[
            {
              label: intl.formatMessage({
                id: 'live_ops.call_modal.error'
              }),
              required: true
            }
          ]}
        >
          <Radio.Group>
            <Space direction="vertical">
              <Radio value={'0'}>
                <span className="br-call-modal__form__checkbox">
                  {intl.formatMessage({
                    id: 'live_ops.call_modal.checkbox_reach'
                  })}
                </span>
              </Radio>
              <Radio value={'1'}>
                <span className="br-call-modal__form__checkbox">
                  {intl.formatMessage({
                    id: 'live_ops.call_modal.checkbox_feedback'
                  })}
                </span>
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="consigneeFeedback"
          className="br-call-modal__form__textarea"
        >
          <Input.TextArea
            maxLength={500}
            placeholder={intl.formatMessage({
              id: 'live_ops.call_modal.placeholder'
            })}
            autoSize={{ minRows: 3.1, maxRows: 3.1 }}
          />
        </Form.Item>
        <div className="display-flex br-call-modal__form__footer">
          <Button onClick={handleOnCancel}>
            {intl.formatMessage({
              id: 'common.cancel'
            })}
          </Button>
          <Button type="primary" htmlType="submit">
            {intl.formatMessage({
              id: 'common.submit'
            })}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
export default injectIntl(CallModal);
