import { useIntl } from 'react-intl';
import { Typography } from 'antd';
import Icon from '@ant-design/icons';
import classnames from 'classnames';

import { MAP_DELIVERY_TYPES } from 'constants/hubs';

import MultiPackagesCount from 'components/MultiPackages/components/MultiPackagesCount/MultiPackagesCount';

import { ReactComponent as TrashIcon } from 'assets/imgRevamp/trash.svg';
import { ReactComponent as ChevronDown } from 'assets/bosta-icons/Chevron-down.svg';

const { Paragraph } = Typography;

const DeliveryItem = ({
  delivery,
  businessName,
  showExtraDataInReceivedTable,
  useWeights,
  handleSizeChange,
  handleOnRemoveReceived,
  isMultiPackagePanelActive
}) => {
  const intl = useIntl();

  return (
    <div className="br-new-swapping-container__panel-content">
      <span className="br-new-swapping-container__panel__tracking-number">
        {delivery.trackingNumber}
        {delivery.multiPackages && (
          <Icon
            component={ChevronDown}
            className={classnames(
              'br-new-swapping-conatiner__multipackage-collapse ant-icon-md',
              {
                'br-new-swapping-conatiner__multipackage-collapse__active':
                  isMultiPackagePanelActive
              }
            )}
          />
        )}
      </span>
      {delivery.multiPackages && <MultiPackagesCount delivery={delivery} />}
      <span>
        {MAP_DELIVERY_TYPES[delivery.type] ||
          intl.formatMessage({
            id: `common.empty_field`
          })}
      </span>
      {showExtraDataInReceivedTable && (
        <>
          <span>{delivery?.state?.value}</span>
          {useWeights ? (
            <span className="br-new-swapping-container-editable-text">
              <Paragraph
                editable={{
                  onChange: (value) =>
                    handleSizeChange({
                      value,
                      delivery,
                      businessName
                    }),
                  triggerType: ['text', 'icon']
                }}
              >
                {intl.formatMessage(
                  { id: 'common.weight_kg' },
                  {
                    weight: delivery.packageWeight
                  }
                )}
              </Paragraph>
            </span>
          ) : (
            <span>{delivery.pricingPackageSize?.name}</span>
          )}

          <span>
            <TrashIcon
              onClick={() => handleOnRemoveReceived(businessName, delivery)}
            />
          </span>
        </>
      )}
    </div>
  );
};

export default DeliveryItem;
