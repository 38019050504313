import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { Form, Select, Tag, Button, Checkbox } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import classnames from 'classnames';

import { openModal } from 'utils/modal';
import { convertUTCTimeToLocalTime } from 'utils/helpers';
import { DELIVERY_TYPES_CODES, PACKAGE_TYPES } from 'constants/shipments';
import { ContextWrapper } from 'contexts/wrapper.context';

import RequestPickupModal from 'components/Shipments/CreateEditOrder/RequestPickupModal/RequestPickupModal';
import CardHeader from 'components/Shipments/CreateEditOrder/CardHeader/CardHeader';

import './RequestPickup.less';

class RequestPickup extends Component {
  state = {
    isLoading: false,
    isPickupRequired: false,
    isBulkyOptionChanged: true
  };

  static contextType = ContextWrapper;

  componentDidUpdate(previousProps, previousState) {
    const { orderData, setIsPickupSelected } = this.props;

    if (previousProps.orderData !== orderData) {
      if (orderData && orderData.pickupRequestId) {
        setIsPickupSelected(true);
      }
    }
  }

  handlePickupChange = async (pickupRequestId) => {
    const { getPickups, selectedBusiness, formRef } = this.props;

    await getPickups(selectedBusiness?._id);
    formRef.current.setFieldsValue({
      pickupRequestId
    });
    this.setState({ isPickupSelected: true });
  };

  handleCreateNewPickup = () => {
    const { selectedBusiness, setIsPickupCreated } = this.props;
    const { countryInfo } = this.context;

    const pickupCutoffTime = convertUTCTimeToLocalTime(
      countryInfo.latestPickupHourInUtc
    );

    if (selectedBusiness)
      openModal(RequestPickupModal, {
        businessId: selectedBusiness._id,
        pickupCutoffTime,
        onSuccess: (pickupRequestId) => {
          this.handlePickupChange(pickupRequestId);
          setIsPickupCreated(true);
        }
      });
  };

  handleOnSelectBulky = async (e) => {
    const { selectedPickup } = this.state;
    if (e.target.checked) {
      this.setState({
        selectedPickup: {
          locationName: {
            ...selectedPickup.locationName,
            packageType: PACKAGE_TYPES[2].value
          },
          isBulkyOptionChanged: true
        }
      });
    } else {
      this.setState({
        selectedPickup: {
          isBulkyOptionChanged: false
        }
      });
    }
  };

  handleOnChangePickupLocation = () => {
    const { setIsPickupSelected } = this.props;
    setIsPickupSelected(true);
  };

  render() {
    const {
      intl,
      orderProps,
      packageType,
      isPickupSelected,
      pickups,
      formRef
    } = this.props;
    const { isLoading, isPickupRequired } = this.state;

    return (
      <div className="br-new-order__request-pickup br-new-order-card">
        {orderProps?.type !== DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP &&
          orderProps?.type !== DELIVERY_TYPES_CODES.CASH_COLLECTION && (
            <>
              <CardHeader
                leftTitle={
                  <>
                    {intl.formatMessage({
                      id: 'shipments.new_order.request_pickup.title'
                    })}
                    {!isPickupRequired && (
                      <span className="br-form-count__optional-label">
                        {intl.formatMessage({
                          id: 'form.optional_label'
                        })}
                      </span>
                    )}
                  </>
                }
                optional
                cardNumber={3}
              />

              <div className="br-new-order-card__content">
                {!isPickupRequired && (
                  <Tag className="br-request-pickup__skip-note">
                    {intl.formatMessage({
                      id: `shipments.new_order.request_pickup.skip_pickup_create_note`
                    })}
                  </Tag>
                )}
                <div className="br-form-row__half-width">
                  <Form.Item
                    name="pickupRequestId"
                    label={intl.formatMessage({
                      id: `shipments.new_order.form_labels.select_from_scheduled_pickups`
                    })}
                  >
                    <Select
                      className="br-pickups__pickup-settings__form__selector"
                      placeholder={intl.formatMessage({
                        id: `form.select_placeholder`
                      })}
                      disabled={!pickups.length || isLoading}
                      showSearch
                      filterOption={(input, option) =>
                        (option.children[0] &&
                          option.children[0]
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0) ||
                        (option.children[2] &&
                          option.children[2]
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 2)
                      }
                      optionFilterProp="children"
                      getPopupContainer={(trigger) =>
                        trigger.parentElement.parentElement.parentElement
                          .parentElement.parentElement
                      }
                      onChange={this.handleOnChangePickupLocation}
                    >
                      {pickups.map((pickup) => (
                        <Select.Option
                          autoComplete={`${Math.random()}`}
                          key={pickup._id}
                          value={pickup.puid}
                        >
                          {pickup?.locationName}
                          <div className="br-pickups__pickup-date">
                            {dayjs(new Date(pickup?.scheduledDate)).format(
                              'DD MMM, YYYY'
                            )}
                          </div>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <div
                    className={classnames('br-customer-details_create-pickup', {
                      'br-customer-details_create-pickup__button':
                        !isPickupSelected ||
                        packageType !== PACKAGE_TYPES[2].value
                    })}
                  >
                    {isPickupSelected &&
                      formRef.current &&
                      formRef.current.getFieldValue('packageType') ===
                        PACKAGE_TYPES[2].value && (
                        <Form.Item
                          name="bulkyTypeSelected"
                          className="br-form-without-label"
                          valuePropName="checked"
                        >
                          <Checkbox>
                            {intl.formatMessage({
                              id: `pickups.create_edit_pickup.form_labels.package_type`
                            })}
                          </Checkbox>
                        </Form.Item>
                      )}
                    <Button
                      onClick={this.handleCreateNewPickup}
                      className="br-add-button"
                    >
                      <PlusOutlined />
                      {intl.formatMessage({
                        id: `shipments.new_order.actions.create_new_pickup`
                      })}
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
      </div>
    );
  }
}

export default injectIntl(withRouter(RequestPickup));
