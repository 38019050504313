import React, { useEffect, useState, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Table, Modal, Select, Tag, InputNumber, Form, Input } from 'antd';
import { Button, Col, Row } from 'react-bootstrap';
import { debounce, get as getValue } from 'lodash';
import { withRouter } from 'react-router';
import { getAllPriceSize } from 'services/priceTiers';
import { beep, getHub, getTransfer, warning } from '../../../actions';
import { notify } from 'components/Notify/Notify';
import styles from '../styles.module.scss';
import { receiveTransfer } from '../../../actions/HubsActions';
import { createCSV } from '../../../utils';
import { getDeliveryPendingActions, showNotification } from 'utils/hubs';
import { scanDeliveries } from 'services/hubs';
import {
  ACTION_TYPES,
  ACTION_TYPES_TAG_COLOR,
  DEFAULT_PACKAGE_WEIGHT,
  PACKAGE_SIZES,
  DELIVERIES_SCAN_TYPES,
  REJECTED_RETURN
} from 'constants/hubs';
import { isFulfillmentOrder } from 'utils/shipmentDetails';
import { IS_SAUDI } from 'constants/helper';
import { openModal } from 'utils/modal';
import BRScanningPopup from 'components/BRScanningPopup/BRScanningPopup';
import { RETURN_REJECTED_EXCEPTION_CODES } from 'constants/shipments';

/**
 * details item (label: value)
 * @param name
 * @param value
 * @param children
 * @returns {*}
 * @constructor
 */
const DetailsItem = ({ name, value, children }) => (
  <>
    <div md={2} xs={3}>
      <span className="mr-2">
        <b>{`${name}:`}</b>
      </span>
    </div>
    <div md={2} xs={3}>
      <p style={{ display: 'inline' }}>{value || children}</p>
      {children}
    </div>
  </>
);

const useWeights = IS_SAUDI;

const ReceiveFromHub = ({
  match,
  fetchIncomingTransferItems,
  currentTransferItems,
  history,
  playBeep,
  playWarning,
  intl
}) => {
  const [loading, setLoading] = useState(false);
  const [itemsToReceived, setItemsToReceived] = useState([]);
  const [itemsReceived, setItemsReceived] = useState([]);
  const [packageSize, setPackageSize] = useState(0);
  const [pricingPackageSize, setPricingPackageSize] = useState();
  const [allPricingPackageSize, setAllPricingPackageSize] = useState([]);
  const [packageWeight, setPackageWeight] = useState(null);

  const inputRef = useRef(null);
  const formRef = useRef();

  const getAllPriceSizes = async () => {
    const priceSize = await getAllPriceSize();
    setAllPricingPackageSize(
      priceSize?.data?.sizes.map((item) => {
        const oldPricing = ['Normal', 'White Bag'].indexOf(item.name);
        return {
          ...item,
          name:
            item.alias && item.alias !== item.name
              ? `${item.alias} (${item.name})`
              : item.name,
          value: item.name,
          oldPricing: oldPricing === -1 ? 0 : oldPricing
        };
      })
    );
    setPackageSize(0);
    setPricingPackageSize(
      priceSize?.data?.sizes.find((item) => item.name === 'Normal').name
    );
  };

  useEffect(() => {
    !useWeights && getAllPriceSizes();
    inputRef?.current?.focus({
      cursor: 'start'
    });
  }, []);

  useEffect(() => {
    setItemsToReceived((prevState) => [
      ...prevState,
      ...getValue(currentTransferItems, 'deliveriesToBeTransferred', [])
    ]);

    return () => {
      setItemsToReceived([]);
      setItemsReceived([]);
    };
  }, [currentTransferItems]);

  useEffect(() => {
    fetchIncomingTransferItems({ _id: match.params.id });
  }, [fetchIncomingTransferItems]);

  const scanDelivery = async ({ trackingNumber, callback }) => {
    try {
      const payload = {
        trackingNumbers: [trackingNumber],
        searchType: DELIVERIES_SCAN_TYPES.SORTING_RECEIVE_ORDER_SCAN
      };

      const { data } = await scanDeliveries(payload);
      callback?.(data);
    } catch (error) {
      notify(error.message);
    }
  };

  const changeSearchFor = async (value) => {
    setLoading(true);
    const item = itemsToReceived.filter((el) => el.trackingNumber === value)[0];
    if (itemsReceived.length >= 50) {
      notify(
        'You have reached 50 items the maximum number of package per seal, please stop scanning',
        'error',
        true
      );
    } else if (item) {
      if (useWeights) {
        item.packageWeight = packageWeight || DEFAULT_PACKAGE_WEIGHT;
      }
      let actionType;
      await scanDelivery({
        trackingNumber: item.trackingNumber,
        callback: (data) => {
          setPackageWeight(null);
          if (
            data[0]?.state?.exception &&
            RETURN_REJECTED_EXCEPTION_CODES.includes(
              data[0].state.exception[0]?.code
            )
          ) {
            openModal(BRScanningPopup, {
              actionTitle: intl.formatMessage({
                id: 'hubs.receive_from_hub.rejected_return_error'
              }),
              bgClassName: 'bg-red',
              closeAutomatically: true,
              automaticCloseCallback: () => inputRef.current.focus()
            });

            actionType = REJECTED_RETURN;
          } else if (getDeliveryPendingActions(data[0])) {
            actionType = getDeliveryPendingActions(data[0]);
            showNotification(actionType, data[0]);
          }
        }
      });
      setItemsReceived((prevState) => {
        const found = prevState.filter((el) => el._id === item._id).length > 0;
        if (!found) {
          return [...prevState, { ...item, actionType }];
        }

        notify(
          `This tracking number: ${item.trackingNumber} is already added!`,
          'error',
          true
        );
        return [...prevState];
      });

      setItemsToReceived((prevState) => {
        return prevState.filter(
          (el) => el.trackingNumber !== item.trackingNumber
        );
      });
      formRef?.current?.setFieldsValue({ trackingNumber: '' });
      playBeep();
    } else {
      playWarning();
      notify(`Not found in this seal.`, 'error', true);

      // call the api again to LOG this action
      await scanDelivery({ trackingNumber: value });
    }
    setLoading(false);
    inputRef.current.focus();
  };

  // const handleChange = changeSearchFor;
  const handleTrackingChange = debounce((evt) => {
    if (evt.target.value.length > 2) {
      changeSearchFor(evt.target.value);
    }
  }, 500);

  const handleConfirmReceiving = async () => {
    let content = '';
    if (useWeights) {
      content = intl.formatMessage({
        id: 'hubs.receive_new_pickup.modal.weight_confirm'
      });
    } else {
      if (
        itemsReceived.some((element) => element.packageSize !== packageSize) &&
        packageSize
      ) {
        content =
          'Are you sure you want to you want to update the package size ?';
      } else {
        content = 'Are you sure you entered the correct size  ?';
      }
    }

    Modal.confirm({
      content: <div>{content}</div>,
      okButtonProps: { className: 'primaryButton' },
      cancelButtonProps: { className: 'secondaryButton' },
      cancelText: 'Cancel',
      onOk: async () => {
        await receiveTransfer({
          _id: match.params.id,
          transferredDeliveries: itemsReceived.map((el) => el._id),
          packageSize,
          pricingPackageSize: pricingPackageSize || PACKAGE_SIZES[0].value
          // packageWeight: itemsReceived[0].packageWeight
        });
        createCSV(
          itemsReceived.map(({ trackingNumber }) => ({ trackingNumber })),
          { trackingNumber: 'TN' },
          `hub-transfer received from ${currentTransferItems?.originWarehouse?.name}`
        );
        history.goBack();
      },
      onCancel: () => {}
    });
  };

  const renderTrackingNumber = (delivery) => (
    <>
      {delivery?.trackingNumber}{' '}
      {isFulfillmentOrder(delivery) && (
        <span className="fulfillment-orders__flag">
          {intl.formatMessage({
            id: 'fulfillment.fulfillment_flag'
          })}
        </span>
      )}
    </>
  );

  return (
    <div className="br-receive-from-hub__container">
      <div className="display-flex mb-3">
        <DetailsItem
          name="Sent From"
          value={getValue(currentTransferItems, 'originWarehouse.name', '')}
        />
      </div>
      <div className="display-flex mb-3">
        <DetailsItem
          name="Seal Numbers"
          value={getValue(currentTransferItems, 'sealNumbers')}
        />
      </div>
      {!useWeights && (
        <Row className="mb-3">
          <h6 className="my-2">
            <span className="mx-3">
              Hint: please sort packages by size berfore you start scanning them
              scan packages of the same size only before you confirn receiving
              pakcage
            </span>
          </h6>
        </Row>
      )}
      <Row className="mb-3">
        <Col md={2} xs={3}>
          <span className="mr-2">
            <b>
              {intl.formatMessage({
                id: `${
                  useWeights
                    ? 'shipments.new_order.order_details.package_weight'
                    : 'hubs.receive_new_pickup.pickups_tab_actions.package_size'
                }`
              })}
            </b>
          </span>
        </Col>
        <Col md={4} xs={4}>
          {useWeights ? (
            <InputNumber
              className="full-width"
              placeholder={intl.formatMessage({
                id: 'hubs.receive_new_pickup.pickups_tab_actions.weight_placeholder'
              })}
              min={1}
              step={1}
              precision={0}
              value={packageWeight}
              onChange={(value) => {
                setPackageWeight(value);
              }}
              onBlur={() => {
                packageWeight &&
                  inputRef.current.focus({
                    cursor: 'start'
                  });
              }}
            />
          ) : (
            <Select
              value={pricingPackageSize}
              className={styles.selectWitdhPickup}
              onChange={(evt) => {
                const selectedPackageSize = allPricingPackageSize.find(
                  (item) => item.value === evt
                );
                setPackageSize(selectedPackageSize.oldPricing);
                setPricingPackageSize(selectedPackageSize.name);
              }}
            >
              {allPricingPackageSize?.map((item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={2} xs={3}>
          <span className="mr-2">
            <b>Scan/Enter Tracking No.:</b>
          </span>
        </Col>
        <Col md={4} xs={4}>
          <Form ref={formRef}>
            <Form.Item name="trackingNumber">
              <Input
                disabled={loading}
                onChange={handleTrackingChange}
                ref={inputRef}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <h4 className="my-2">
            <span className="mx-2">
              Remaining Packages:
              {itemsToReceived.length}
            </span>
          </h4>
          <Table
            // rowSelection={rowSelection}
            className="mt-3"
            style={{ overflow: 'auto' }}
            columns={[
              {
                dataIndex: '',
                title: 'Tracking Number',
                render: renderTrackingNumber
              },
              // {
              //   dataIndex: 'packageSize',
              //   title: 'Package Size',
              //   render: value => allPackageSize[value]?.label,
              // },
              {
                dataIndex: `${
                  useWeights ? 'packageWeight' : 'pricingPackageSize'
                }`,
                title: `${
                  useWeights ? 'Package Weight' : 'Pricing Package Size'
                }`,
                render: (value) => value
              }
            ]}
            dataSource={itemsToReceived}
            pagination={false}
          />
        </Col>
        <Col sm={6}>
          <h4 className="my-2">
            Number of Scanned Packages: {itemsReceived?.length || 0}
          </h4>

          <Table
            // rowSelection={rowSelection}
            className="mt-3"
            style={{ overflow: 'auto' }}
            columns={[
              {
                dataIndex: '',
                title: 'Tracking Number',
                render: renderTrackingNumber
              },
              // {
              //   dataIndex: 'packageSize',
              //   title: 'Package Size',
              //   render: value =>
              //     packageSize !== null
              //       ? allPackageSize[packageSize]?.label
              //       : allPackageSize[value]?.label,
              // },
              {
                dataIndex: `${
                  useWeights ? 'packageWeight' : 'pricingPackageSize'
                }`,
                title: `${
                  useWeights ? 'Package Weight' : 'Pricing Package Size'
                }`,
                render: (value) => pricingPackageSize || value
              },
              {
                dataIndex: 'actionType',
                title: 'Type',
                render: (actionType) => (
                  <Tag color={ACTION_TYPES_TAG_COLOR[actionType]}>
                    {ACTION_TYPES[actionType]}
                  </Tag>
                )
              }
            ]}
            dataSource={itemsReceived}
            pagination={false}
          />
        </Col>
      </Row>

      <div className={styles.hubButtons}>
        <Button className="primaryButton" onClick={handleConfirmReceiving}>
          Confirm Receiving
        </Button>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchHub: (data) => dispatch(getHub(data)),
  fetchIncomingTransferItems: (data) => dispatch(getTransfer(data)),
  playBeep: () => dispatch(beep()),
  playWarning: () => dispatch(warning())
});

const mapStateToProps = ({ hubs }) => {
  return {
    ...hubs
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(ReceiveFromHub)));
