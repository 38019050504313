import SingleInfoCard from '../SingleCardInfo/SingleCardInfo';
import './GeneralInfoCard.less';
const GeneralInfoCard = ({ Icon, title, total, cardsData }) => {
  return (
    <div className="general-info__container">
      <div className="general-info__header">
        {Icon && <Icon />}
        <h3 className="font-18">{title}</h3>
        {total && <h3 className="general-info__total font-18">{total}</h3>}
      </div>
      <div className="general-info__data-container">
        {cardsData.map((card) => {
          return <SingleInfoCard title={card.title} data={card.data} />;
        })}
      </div>
    </div>
  );
};

export default GeneralInfoCard;
