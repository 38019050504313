import React, { useEffect, useState, useRef, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import {
  Modal,
  Form,
  DatePicker,
  Checkbox,
  Menu,
  Button,
  Dropdown
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { DAYS, REPEATED_TYPE_ONE_TIME, WEEKLY_VALUE } from 'constants/pickups';
import { disabledDate } from 'utils/pickups';
// import { sendSegment } from 'utils/segment';

import './CreatePickupRecurrenceFormModal.less';

const CreatePickupRecurrenceFormModal = (props) => {
  const { intl, onOk, close, values, pickupDate } = props;
  const [checkedList, setCheckedList] = useState([]);
  const [formValues, setFormValues] = useState({
    repeatedType: null,
    startDate: null,
    endDate: null
  });
  const [repeatedType, setRepeatedType] = useState('');
  const [form] = Form.useForm();

  useEffect(() => {
    if (values !== null) {
      setCheckedList(values.repeatedType === WEEKLY_VALUE ? values.days : []);
      setRepeatedType(() =>
        values.repeatedType !== REPEATED_TYPE_ONE_TIME
          ? values.repeatedType === WEEKLY_VALUE
            ? `Every Week ${values.days.map((e) => e)}`
            : 'Every Day'
          : ''
      );

      setFormValues({
        repeatedType: values.repeatedType,
        startDate:
          values.repeatedType !== REPEATED_TYPE_ONE_TIME
            ? dayjs(new Date(values.startDate))
            : null,
        endDate:
          values?.endDate === undefined ? null : dayjs(new Date(values.endDate))
      });

      form.setFieldsValue({
        repeatedType: values.repeatedType,
        startDate:
          values.repeatedType !== REPEATED_TYPE_ONE_TIME
            ? dayjs(new Date(values.startDate))
            : null,
        endDate:
          values?.endDate === undefined ? null : dayjs(new Date(values.endDate))
      });
    }
    if (pickupDate) {
      setFormValues((prev) => ({
        ...prev,
        startDate: pickupDate
      }));

      form.setFieldsValue({
        startDate: dayjs(new Date(pickupDate))
      });
    }
  }, []);

  const handleSelectChange = useCallback((evt, checkedValues) => {
    if (evt.target.checked === true) {
      setCheckedList((prev) => [...prev, checkedValues]);
    } else {
      const index = checkedList.indexOf(checkedValues);
      if (index > -1) {
        setCheckedList((prev) =>
          prev.filter(function (item) {
            return item !== checkedValues;
          })
        );
      }
    }
  });

  const handleFormChange = useCallback((evt, fieldName) => {
    setFormValues((prev) => {
      return { ...prev, [fieldName]: evt };
    });
  });

  const onFinish = useCallback((values) => {
    onOk({
      ...formValues,
      days:
        formValues.repeatedType !== WEEKLY_VALUE
          ? DAYS.map((item) => item.value)
          : checkedList
    });
    close();
  });

  useEffect(() => {
    if (checkedList.length !== 0) {
      setRepeatedType(() => `Every Week ${checkedList.map((e) => e)}`);
      form.setFieldsValue({
        repeatedType: 'Weekly'
      });
      handleFormChange('Weekly', 'repeatedType');
    }
  }, [checkedList]);

  const handleOnClickEveryDay = useCallback(() => {
    setCheckedList([]);
    setRepeatedType('Every Day');
    form.setFieldsValue({
      repeatedType: 'Daily'
    });
    handleFormChange('Daily', 'repeatedType');
  });

  const everyWeekMenu = useCallback(() => {
    return (
      <Menu
        onClick={(e) => e.domEvent.stopPropagation()}
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
      >
        <Menu.Item onClick={handleOnClickEveryDay} key="1">
          {intl.formatMessage({
            id: 'pickups.repeated_modal.every_day'
          })}
        </Menu.Item>
        <Menu.SubMenu
          popupClassName="br-repeated-modal__form__reptead-type__all-week"
          title={intl.formatMessage({
            id: 'pickups.repeated_modal.every_week'
          })}
        >
          {DAYS.map((item) => (
            <Menu.Item
              key={item.value}
              onClick={(e) => e.domEvent.stopPropagation()}
            >
              <div>
                <Checkbox
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(evt) => handleSelectChange(evt, item.value)}
                  checked={checkedList.includes(item.value)}
                >
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {item.label}
                  </span>
                </Checkbox>
              </div>
            </Menu.Item>
          ))}
        </Menu.SubMenu>
      </Menu>
    );
  }, [checkedList]);

  const handleOnChangeEndDate = (evt) => {
    if (evt > formValues.endDate) {
      handleFormChange(undefined, 'endDate');

      form.setFieldsValue({
        endDate: undefined
      });
    }
    handleFormChange(evt, 'startDate');
  };

  const handleStopRepeated = useCallback(() => {
    onOk({
      repeatedType: REPEATED_TYPE_ONE_TIME
    });
    setFormValues({
      repeatedType: null,
      endDate: null,
      startDate: null
    });
    form.resetFields();
    setCheckedList([]);
    // sendSegment('E_PICKUP_EDIT_REMOVE_REPETITION');
    close();
  });

  return (
    <Modal
      {...props}
      className="br-repeated-modal"
      title={null}
      footer={null}
      onCancel={close}
      width={null}
    >
      <>
        <div className="br-repeated-modal__title">
          {intl.formatMessage({
            id: 'pickups.repeated_modal.title'
          })}
        </div>
        <div className="br-repeated-modal__sub-title">
          {intl.formatMessage({
            id: 'pickups.repeated_modal.sub_title'
          })}
        </div>
        <Form
          onFinish={onFinish}
          className="br-repeated-modal__form"
          form={form}
        >
          <Form.Item
            name="startDate"
            className="br-form-row"
            label={intl.formatMessage({
              id: `pickups.repeated_modal.form_labels.start_date`
            })}
            rules={[{ required: true }]}
            placeholder={intl.formatMessage({
              id: `pickups.repeated_modal.form_placeholders.date_placeholder`
            })}
          >
            <DatePicker
              value={formValues.startDate}
              className="br-repeated-modal__form__date-input"
              onChange={(evt) => handleOnChangeEndDate(evt)}
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              disabledDate={(current) =>
                disabledDate(current, new Date(), props.pickupCutoffTime)
              }
            />
          </Form.Item>

          <Form.Item
            name="repeatedType"
            className="br-form-row br-repeated-modal__form__reptead-type"
            label={intl.formatMessage({
              id: `pickups.repeated_modal.form_labels.reptead_type`
            })}
            rules={[{ required: true }]}
          >
            <Dropdown
              overlayClassName="br-repeated-modal__form__reptead-type__dropdown"
              overlay={everyWeekMenu}
              trigger={['click']}
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
            >
              <Button>
                {repeatedType}
                <DownOutlined />
              </Button>
            </Dropdown>
          </Form.Item>

          <Form.Item
            name="endDate"
            className="br-form-row"
            label={intl.formatMessage({
              id: `pickups.repeated_modal.form_labels.end_date`
            })}
            rules={[{ required: true }]}
            placeholder={intl.formatMessage({
              id: `pickups.repeated_modal.form_placeholders.date_placeholder`
            })}
          >
            <DatePicker
              value={formValues.endDate}
              onChange={(evt) => handleFormChange(evt, 'endDate')}
              disabledDate={(current) =>
                disabledDate(
                  current,
                  new Date(dayjs(formValues.startDate).add(1, 'days'))
                )
              }
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
            />
          </Form.Item>
          <div className="br-repeated-modal__form__footer">
            {values && (
              <Button type="danger" onClick={handleStopRepeated}>
                {intl.formatMessage({
                  id: 'pickups.actions.stop'
                })}
              </Button>
            )}
            <Button onClick={close}>
              {intl.formatMessage({
                id: 'common.cancel'
              })}
            </Button>
            <Button type="primary" htmlType="submit">
              {intl.formatMessage({
                id: pickupDate
                  ? 'common.save'
                  : 'pickups.actions.create_repeated'
              })}
            </Button>
          </div>
        </Form>
      </>
    </Modal>
  );
};
export default injectIntl(CreatePickupRecurrenceFormModal);
