import { injectIntl } from 'react-intl';
import { Modal, Button } from 'antd';

import { updateOrder } from 'services/shipments';

import { notify } from 'components/Notify/Notify';

import './ArchiveOrder.less';

const ArchiveOrder = ({ intl, close, title, id, ...rest }) => {
  const ArchiveOrder = async () => {
    const updateType = 'archive';
    try {
      const payload = {
        updateType
      };
      const res = await updateOrder({ id, payload });
      if (res?.message) {
        notify(res?.message, 'success');
      }
      close();
    } catch (error) {
      notify(error.message);
    }
  };

  return (
    <Modal
      {...rest}
      title={intl.formatMessage({ id: 'shipments.actions.confirm_archive' })}
      footer={null}
      centered
      onCancel={close}
    >
      <div className="br-archive-order-confirmation-modal">{title}</div>
      <div className="br-archive-order-confirmation-modal__footer">
        <Button onClick={close}>
          {intl.formatMessage({ id: 'common.cancel' })}
        </Button>
        <Button type="primary" onClick={ArchiveOrder}>
          {intl.formatMessage({ id: 'common.confirm' })}
        </Button>
      </div>
    </Modal>
  );
};

export default injectIntl(ArchiveOrder);
