import http from 'utils/http';

export const createRoute = (payload) => {
  return http.post('/routes/create', payload);
};

export const updateRoute = (routeId, payload) => {
  return http.put(`/routes/${routeId}/update`, payload);
};

export const getRunSheetPrintCode = (routeId) => {
  return http.get(`/routes/${routeId}/runSheet`);
};

export const getManifestsRunSheetPrintCode = (routeId) => {
  return http.get(`/routes/${routeId}/manifests-sheet`);
};

export const generateRoutesAutomatically = (payload) => {
  return http.post('routes/routes-automatic-generation', payload);
};

export const mergeRoutes = (payload) => {
  return http.put('/routes/merge-routes', payload);
};

export const splitRoutes = (payload) => {
  return http.put('/routes/split-routes', payload);
};

export const createMultiRoutes = (payload) => {
  return http.post('routes/create-multi-routes', payload);
};

export const fetchYesterdayRoutes = (hubId) => {
  return http.get(`routes/${hubId}/yesterday-routes`);
};

export const getReturnManifestDetails = ({ routeId, returnManifestId }) => {
  return http.get(
    `/routes/${routeId}/return-manifest-details/${returnManifestId}`
  );
};

export const mergeDeliveries = (payload) => {
  return http.put('/routes/merge-deliveries', payload);
};

export const exportUnDebriefedRoutesData = (payload) => {
  return http.get(`/routes/export-csv`, payload);
};

export const assignPickupsToRoute = ({ starId, payload }) => {
  return http.post(`/routes/stars/${starId}/assign-pickups`, payload);
};

export const exportRoutes = ({ exportType, payload }) => {
  return http.post(`/routes/exportSheet?type=${exportType}`, payload);
};

export const deleteRoute = ({ routeId }) => {
  return http.delete(`/routes/${routeId}`);
};

export const assignStarToRoute = ({ routeId, payload }) => {
  return http.put(`/routes/${routeId}/star`, payload);
};
