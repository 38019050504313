import { useEffect, useRef, useState } from 'react';
import { Form, Input, Collapse, InputNumber } from 'antd';
import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';

import {
  deliveryTypes,
  locatedInHubState,
  transitionState
} from '../constants';
import {
  DATE_RANGE_FILTERS_KEYS,
  deliveriesStates
} from 'constants/Deliveries';
import { DATE_FORMAT } from 'constants/date-picker';
import { getAllPriceSize } from 'services/priceTiers';
import { getHubShelves } from 'services/hubs';

import { exportXlsx } from 'services/deliveries';
import { cleanEmptyString, isUserAuthorized } from 'utils/helpers';
import { MARKETPLACE, DELIVERIES_PAYMENT_METHODS } from 'constants/shipments';
import { getSubAccounts } from 'services/business';
import { ACL_MATRIX } from 'common/permissions';
import aclMatrix from 'common/aclMatrix';

import BRButton from 'components/BRButton/BRButton';
import SearchCheckbox from './components/SearchCheckbox/SearchCheckbox';
import SearchSelect from './components/SearchSelect/SearchSelect';
import { notify } from 'components/Notify/Notify';
import SearchDateRangePicker from './components/SearchDateRangePicker/SearchDateRangePicker';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BusinessSelector from 'components/BRSelectors/BusinessSelector/BusinessSelector';
import InternationalOrdersTag from 'components/InternationalOrders/components/InternationalOrdersTag/InternationalOrdersTag';

import { ReactComponent as SllrOrder } from 'assets/bosta-icons/Sllr-Order.svg';

import './DeliveriesSearch.less';

const DeliveriesSearch = ({
  intl,
  handleFormSubmit,
  handleResetForm,
  hubs,
  stars,
  isLoading,
  formatOptions,
  formatCurrentFilterBeforeSearch
}) => {
  const [packageSizes, setPackageSizes] = useState([]);
  const [selectedHubs, setSelectedHubs] = useState([]);
  const [selectedHubShelves, setSelectedHubShelves] = useState([]);
  const [selectedBusinesses, setSelectedBusinesses] = useState([]);
  const [selectedBusinessSubAccounts, setSelectedBusinessSubAccounts] =
    useState([]);
  const [isExporting, setIsExporting] = useState(false);

  const formRef = useRef();

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  useEffect(() => {
    getPackageSizes();
  }, []);

  useEffect(() => {
    if (selectedHubs?.length === 1) {
      const firstHubId = selectedHubs[0];
      getShelvesForFirstHubSelected(firstHubId);
    } else {
      formRef?.current?.resetFields(['shelfNames']);
      setSelectedHubShelves([]);
    }
  }, [selectedHubs]);

  useEffect(() => {
    if (selectedBusinesses.length === 1) {
      const businessId = selectedBusinesses[0];
      getBusinessSubAccounts(businessId);
    } else {
      formRef?.current?.resetFields(['subAccountId']);
      setSelectedBusinessSubAccounts([]);
    }
  }, [selectedBusinesses]);

  const getShelvesForFirstHubSelected = async (hubId) => {
    try {
      const { warehouse } = await getHubShelves(hubId);
      if (warehouse?.shelves) {
        setSelectedHubShelves(
          warehouse.shelves.map((shelf) => ({ value: shelf, label: shelf }))
        );
      } else {
        formRef?.current?.resetFields(['shelfNames']);
        setSelectedHubShelves([]);
      }
    } catch (error) {
      notify(error?.message);
    }
  };

  const getBusinessSubAccounts = async (businessId) => {
    try {
      const { subAccounts = [] } = await getSubAccounts({ businessId });
      setSelectedBusinessSubAccounts(subAccounts);
    } catch (error) {
      notify(error.message);
    }
  };

  const getPackageSizes = async () => {
    try {
      const { data } = await getAllPriceSize();

      if (data) {
        setPackageSizes(
          data?.sizes?.map((size) => ({ value: size?.name, label: size?.name }))
        );
      }
    } catch (error) {
      notify(error.message);
    }
  };

  const formatFormData = (values) => {
    const dateRangeKeys = DATE_RANGE_FILTERS_KEYS.flat();

    Object.keys(cleanEmptyString(values)).forEach((key) => {
      if (dateRangeKeys.includes(key)) {
        const dateStartKey = `${key}Start`;
        const dateEndKey = `${key}End`;

        values[dateStartKey] = dayjs(values[key][0]).format(DATE_FORMAT);
        values[dateEndKey] = dayjs(values[key][1]).format(DATE_FORMAT);

        delete values[key];
      }
    });

    if (values.sllrOrders) {
      values.creationSrc = MARKETPLACE;
      delete values.sllrOrders;
    }

    return values;
  };

  const handleOnFinish = (values) => {
    handleFormSubmit(formatFormData(values));
  };

  const formatMultipleSearchValue = (value) => {
    return value?.trim().split(/[, ]+/).join(',');
  };

  const handleExport = async () => {
    setIsExporting(true);
    const formattedFromValues = formatFormData(
      formRef.current.getFieldsValue()
    );
    let trackingNumbers;
    let barcodeRef;
    let internationalTrackingNumbers;

    if (formattedFromValues?.trackingNumbers) {
      trackingNumbers = formatMultipleSearchValue(
        formattedFromValues.trackingNumbers
      );
    } else if (formattedFromValues?.barcodeRef) {
      barcodeRef = formatMultipleSearchValue(formattedFromValues.barcodeRef);
    } else if (formattedFromValues?.internationalTrackingNumbers) {
      internationalTrackingNumbers = formatMultipleSearchValue(
        formattedFromValues.internationalTrackingNumbers
      );
    }

    const payload = formatCurrentFilterBeforeSearch(
      cleanEmptyString({
        ...formattedFromValues,
        trackingNumbers,
        barcodeRef,
        internationalTrackingNumbers
      })
    );

    try {
      if (Object.keys(payload || {}).length) {
        const { msg } = await exportXlsx(payload);
        notify(msg, 'success');
      } else {
        notify(intl.formatMessage({ id: 'deliveries.export_error' }));
      }
    } catch (error) {
      notify(error.message);
    }
    setIsExporting(false);
  };

  const handleClearClick = () => {
    formRef.current.resetFields();
    handleResetForm();
  };

  return (
    <Collapse className="br-deliveries-search__container">
      <Collapse.Panel
        header={intl.formatMessage({
          id: 'deliveries.search_filters.title'
        })}
      >
        <LoadingWrapper loading={isLoading || isExporting}>
          <Form ref={formRef} onFinish={handleOnFinish}>
            <div className="br-deliveries-search__groups-container">
              <div className="br-deliveries-search__group">
                <div className="br-deliveries-search__group-row">
                  <Form.Item
                    name="trackingNumbers"
                    label={intl.formatMessage({
                      id: 'deliveries.search_filters.tracking_number'
                    })}
                  >
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'deliveries.search_filters_placeholder.tracking_number'
                      })}
                    />
                  </Form.Item>
                  <Form.Item
                    name="internationalTrackingNumbers"
                    label={intl.formatMessage({
                      id: 'deliveries.search_filters.international_tracking'
                    })}
                  >
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'deliveries.search_filters_placeholder.tracking_number'
                      })}
                    />
                  </Form.Item>
                  <Form.Item
                    name="type"
                    label={intl.formatMessage({
                      id: 'deliveries.search_filters.delivery_type'
                    })}
                  >
                    <SearchSelect
                      placeholder={intl.formatMessage({ id: 'common.select' })}
                      options={deliveryTypes}
                    />
                  </Form.Item>
                  <Form.Item
                    name="packageSize"
                    label={intl.formatMessage({
                      id: 'deliveries.search_filters.package_size'
                    })}
                  >
                    <SearchSelect
                      mode="multiple"
                      placeholder={intl.formatMessage({ id: 'common.select' })}
                      options={packageSizes}
                    />
                  </Form.Item>
                  <Form.Item
                    name="stateCodes"
                    label={intl.formatMessage({
                      id: 'deliveries.search_filters.delivery_state'
                    })}
                  >
                    <SearchSelect
                      mode="multiple"
                      placeholder={intl.formatMessage({ id: 'common.select' })}
                      options={deliveriesStates}
                    />
                  </Form.Item>
                </div>
                <div className="br-deliveries-search__group-row">
                  <Form.Item
                    name="hubs"
                    label={intl.formatMessage({
                      id: 'deliveries.search_filters.hub'
                    })}
                  >
                    <SearchSelect
                      mode="multiple"
                      onChange={setSelectedHubs}
                      placeholder={intl.formatMessage({ id: 'common.select' })}
                      options={formatOptions('_id', 'name', hubs)}
                    />
                  </Form.Item>
                  <Form.Item
                    name="inTransit"
                    label={intl.formatMessage({
                      id: 'deliveries.search_filters.in_transit_between_hubs'
                    })}
                  >
                    <SearchSelect
                      placeholder={intl.formatMessage({ id: 'common.select' })}
                      options={transitionState}
                    />
                  </Form.Item>
                  <Form.Item
                    name="exceptionBy"
                    label={intl.formatMessage({
                      id: 'deliveries.search_filters.exception_by'
                    })}
                  >
                    <SearchSelect
                      mode="multiple"
                      placeholder={intl.formatMessage({ id: 'common.select' })}
                      options={formatOptions(
                        '_id',
                        'profile.firstName,profile.lastName',
                        stars.data
                      )}
                    />
                  </Form.Item>
                  <Form.Item
                    name="barcodeRef"
                    label={intl.formatMessage({
                      id: 'deliveries.search_filters.smart_sticker_no'
                    })}
                  >
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'deliveries.search_filters_placeholder.smart_sticker_no'
                      })}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="br-deliveries-search__group">
                <div className="br-deliveries-search__group-row">
                  {isUserAuthorized(aclMatrix.BUSINESSES, [
                    ACL_MATRIX.BUSINESSES_LIST
                  ]) && (
                    <>
                      <Form.Item
                        name="businessId"
                        label={intl.formatMessage({
                          id: 'deliveries.search_filters.business'
                        })}
                      >
                        <BusinessSelector
                          mode="multiple"
                          onChange={setSelectedBusinesses}
                          placeholder={intl.formatMessage({
                            id: 'common.select'
                          })}
                        />
                      </Form.Item>
                      {!!selectedBusinessSubAccounts.length && (
                        <Form.Item
                          name="subAccountId"
                          label={intl.formatMessage({
                            id: 'deliveries.search_filters.sub_account'
                          })}
                        >
                          <SearchSelect
                            mode="multiple"
                            placeholder={intl.formatMessage({
                              id: 'common.select'
                            })}
                            options={formatOptions(
                              '_id',
                              'name',
                              selectedBusinessSubAccounts
                            )}
                          />
                        </Form.Item>
                      )}
                    </>
                  )}
                  <Form.Item
                    name="id"
                    label={intl.formatMessage({
                      id: 'deliveries.search_filters.business_id'
                    })}
                  >
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'deliveries.search_filters_placeholder.business_id'
                      })}
                    />
                  </Form.Item>
                  <Form.Item
                    name="businessReference"
                    label={intl.formatMessage({
                      id: 'deliveries.search_filters.business_reference'
                    })}
                  >
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'deliveries.search_filters_placeholder.business_reference'
                      })}
                    />
                  </Form.Item>
                  <Form.Item
                    name="is_invalid_business"
                    label={intl.formatMessage({
                      id: 'deliveries.search_filters.invalid_business'
                    })}
                  >
                    <SearchSelect
                      placeholder={intl.formatMessage({ id: 'common.select' })}
                      options={locatedInHubState}
                    />
                  </Form.Item>
                </div>
                <div className="br-deliveries-search__group-row">
                  <Form.Item
                    name="customersFirstNames"
                    label={intl.formatMessage({
                      id: 'deliveries.search_filters.customer_first_name'
                    })}
                  >
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'deliveries.search_filters_placeholder.customer_first_name'
                      })}
                    />
                  </Form.Item>
                  <Form.Item
                    name="customersLastNames"
                    label={intl.formatMessage({
                      id: 'deliveries.search_filters.customer_last_name'
                    })}
                  >
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'deliveries.search_filters_placeholder.customer_last_name'
                      })}
                    />
                  </Form.Item>
                  <Form.Item
                    name="mobilePhones"
                    label={intl.formatMessage({
                      id: 'deliveries.search_filters.customer_phone'
                    })}
                  >
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'deliveries.search_filters_placeholder.customer_phone'
                      })}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="br-deliveries-search__group">
                {DATE_RANGE_FILTERS_KEYS.map((row) => (
                  <div className="br-deliveries-search__group-row">
                    {row.map((key) => (
                      <SearchDateRangePicker
                        name={key}
                        label={intl.formatMessage({
                          id: `deliveries.search_filters.${key}`
                        })}
                      />
                    ))}
                  </div>
                ))}
              </div>
              <div className="br-deliveries-search__group">
                <div className="br-deliveries-search__group-row">
                  <SearchCheckbox
                    name="isFulfilled"
                    className="br-deliveries-search__fulfillment-checkbox"
                    extraContent={
                      <div className="fulfillment-orders__flag">
                        {intl.formatMessage({
                          id: 'fulfillment.fulfillment_flag'
                        })}
                      </div>
                    }
                    label={intl.formatMessage({
                      id: 'deliveries.search_filters.fxf_orders'
                    })}
                  />
                  <SearchCheckbox
                    name="sllrOrders"
                    className="br-deliveries-search__fulfillment-checkbox"
                    extraContent={<SllrOrder />}
                    label={intl.formatMessage({
                      id: 'deliveries.search_filters.sllr_orders'
                    })}
                  />
                  <SearchCheckbox
                    name="isInternationalOrders"
                    className="br-deliveries-search__fulfillment-checkbox"
                    extraContent={<InternationalOrdersTag />}
                    label={intl.formatMessage({
                      id: 'deliveries.search_filters.international_orders'
                    })}
                  />
                </div>
                <div className="br-deliveries-search__group-row">
                  <Form.Item
                    name="starId"
                    label={intl.formatMessage({
                      id: 'deliveries.search_filters.stars'
                    })}
                  >
                    <SearchSelect
                      mode="multiple"
                      placeholder={intl.formatMessage({ id: 'common.select' })}
                      options={formatOptions(
                        '_id',
                        'profile.firstName,profile.lastName',
                        stars.data
                      )}
                    />
                  </Form.Item>
                  <Form.Item
                    name="numberOfAttempts"
                    label={intl.formatMessage({
                      id: 'deliveries.search_filters.number_of_attempts'
                    })}
                  >
                    <InputNumber min={0} />
                  </Form.Item>
                  <Form.Item
                    name="locatedInHub"
                    label={intl.formatMessage({
                      id: 'deliveries.search_filters.located_in_hub'
                    })}
                  >
                    <SearchSelect
                      placeholder={intl.formatMessage({ id: 'common.select' })}
                      options={locatedInHubState}
                    />
                  </Form.Item>
                  <Form.Item
                    name="shelfNames"
                    label={intl.formatMessage({
                      id: 'deliveries.search_filters.hub_shelves'
                    })}
                  >
                    <SearchSelect
                      placeholder={intl.formatMessage({ id: 'common.select' })}
                      disabled={!selectedHubShelves?.length}
                      options={selectedHubShelves}
                    />
                  </Form.Item>
                </div>
                <div className="br-deliveries-search__group-row">
                  <Form.Item
                    name="onHold"
                    label={intl.formatMessage({
                      id: 'deliveries.search_filters.on_hold'
                    })}
                  >
                    <SearchSelect
                      placeholder={intl.formatMessage({ id: 'common.select' })}
                      options={locatedInHubState}
                    />
                  </Form.Item>
                  <Form.Item
                    name="assignedHub"
                    label={intl.formatMessage({
                      id: 'deliveries.search_filters.assigned_hub'
                    })}
                  >
                    <SearchSelect
                      placeholder={intl.formatMessage({ id: 'common.select' })}
                      options={formatOptions('_id', 'name', hubs)}
                    />
                  </Form.Item>
                </div>
                <div className="br-deliveries-search__group-row">
                  <Form.Item
                    name="paymentMethod"
                    label={intl.formatMessage({
                      id: 'deliveries.search_filters.payment_methods'
                    })}
                  >
                    <SearchSelect
                      placeholder={intl.formatMessage({ id: 'common.select' })}
                      options={formatOptions(
                        'value',
                        'value',
                        DELIVERIES_PAYMENT_METHODS[
                          userInfo?.country?.code || 'EG'
                        ]
                      )}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="br-deliveries-search__actions">
              <BRButton
                type="primary"
                label={intl.formatMessage({ id: 'common.filter' })}
                htmlType="submit"
              />
              <BRButton
                type="primary"
                label={intl.formatMessage({ id: 'common.export' })}
                onClick={handleExport}
              />
              <BRButton
                label={intl.formatMessage({ id: 'common.clear' })}
                onClick={handleClearClick}
              />
            </div>
          </Form>
        </LoadingWrapper>
      </Collapse.Panel>
    </Collapse>
  );
};

export default injectIntl(DeliveriesSearch);
