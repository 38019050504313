import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { Form, Input, Select, Checkbox, Tooltip, Divider, Tag } from 'antd';
import classnames from 'classnames';

import { phoneNumberRule } from 'utils/forms';
import { getAreasInfo } from 'services/cities';
import { getLocaleFromLocalStorage } from 'utils/intl-wrapper';
import { LOCALE } from 'constants/intl-wrapper';
import { PHONE_NUMBER_PLACEHOLDER } from 'common/countries/constants/forms';
import { FIRST_LINE_MIN_LENGTH } from 'constants/shipments';

import { DELIVERY_TYPES_CODES } from 'containers/Deliveries/constants';
import { notify } from 'components/Notify/Notify';
import CardHeader from 'components/Shipments/CreateEditOrder/CardHeader/CardHeader';
import BRFormInputCounter from 'components/BRFormInputCounter/BRFormInputCounter';
import BRFormInputPhoneNumber from 'components/BRFormInputPhoneNumber/BRFormInputPhoneNumber';
import BRArea, { AVAILABILITY } from 'components/BRArea/BRArea';

import TooltipsIcon from 'assets/imgRevamp/informationButton.svg';

import './CustomerDetails.less';

const CustomerDetails = (props) => {
  const [areas, setAreas] = useState([]);
  const [areasLoading, setAreasLoading] = useState(false);
  const [language, setLanguage] = useState(LOCALE.EN);

  const handleCityChange = async (cityId) => {
    try {
      setAreasLoading(true);
      const areas = await getAreasInfo(cityId);
      setAreas(areas);
      setAreasLoading(false);
    } catch (error) {
      notify(error.message);
    }
  };

  const resetDistrictField = () => {
    if (props.formRef) {
      props.formRef.current.setFieldsValue({ districtId: null });
    }
  };

  useEffect(() => {
    const { orderData } = props;
    setLanguage(getLocaleFromLocalStorage());
    if (orderData !== null && areas.length === 0 && areasLoading === false) {
      handleCityChange(
        orderData.type.code === DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP ||
          orderData.type.code === DELIVERY_TYPES_CODES.CASH_COLLECTION
          ? orderData?.pickupAddress?.city?._id
          : orderData?.dropOffAddress?.city?._id
      );
    }
  }, [props]);

  const { intl, cities, isCitiesLoading, handleOnChangeForm } = props;

  return (
    <>
      <div className="br-new-order-card br-customer-details">
        <CardHeader
          leftTitle={intl.formatMessage({
            id: `shipments.new_order.customer_details.title`
          })}
          cardNumber={2}
        />
        <div className="br-new-order-card__content">
          <div className="br-customer-details__customer-info">
            <p className="br-new-order__card-content-title">
              {intl.formatMessage({
                id: 'shipments.new_order.customer_details.customer_info'
              })}
            </p>
            <div className="br-form-row">
              <Form.Item
                name="fullName"
                rules={[
                  {
                    required: true,
                    max: 50,
                    label: intl.formatMessage({
                      id: 'contact_form_labels.full_name'
                    })
                  }
                ]}
              >
                <BRFormInputCounter
                  label={intl.formatMessage({
                    id: 'contact_form_labels.full_name'
                  })}
                  maxCount={50}
                >
                  <Input
                    maxLength={50}
                    autoComplete={`${Math.random()}`}
                    allowClear
                  />
                </BRFormInputCounter>
              </Form.Item>
            </div>
            <div className="br-form-row">
              <Form.Item
                name="phone"
                label={intl.formatMessage({
                  id: 'contact_form_labels.phone'
                })}
                validateTrigger="onBlur"
                rules={[
                  {
                    label: intl.formatMessage({
                      id: 'contact_form_labels.phone'
                    }),
                    required: true
                  },
                  phoneNumberRule({
                    message: intl.formatMessage({
                      id: 'form.phone_not_valid'
                    }),
                    internationlNumbers: true
                  })
                ]}
              >
                <BRFormInputPhoneNumber
                  placeholder={PHONE_NUMBER_PLACEHOLDER}
                  filedName="phone"
                  handleOnChangeForm={handleOnChangeForm}
                  autoComplete={`${Math.random()}`}
                />
              </Form.Item>
            </div>
            <div className="br-form-row">
              <Form.Item
                name="secondPhone"
                label={
                  <>
                    {intl.formatMessage({
                      id: 'contact_form_labels.secondary_phone'
                    })}
                    <Tooltip
                      title={intl.formatMessage({
                        id: 'shipments.new_order.tooltips.secondry_phone_tootltip'
                      })}
                      getPopupContainer={(trigger) =>
                        trigger.parentElement.parentElement.parentElement
                          .parentElement
                      }
                    >
                      <img src={TooltipsIcon} alt="tootltip-note" />
                    </Tooltip>
                  </>
                }
                validateTrigger="onBlur"
                rules={[
                  phoneNumberRule({
                    message: intl.formatMessage({
                      id: 'form.phone_not_valid'
                    }),
                    landlineNumbers: true
                  })
                ]}
              >
                <BRFormInputPhoneNumber
                  optional
                  placeholder={PHONE_NUMBER_PLACEHOLDER}
                  filedName="secondPhone"
                  handleOnChangeForm={handleOnChangeForm}
                  autoComplete={`${Math.random()}`}
                />
              </Form.Item>
            </div>
            <Form.Item
              className="br-form-text-area-label"
              name="notes"
              rules={[
                {
                  max: 200,
                  label: intl.formatMessage({
                    id: 'shipments.new_order.customer_details.order_notes'
                  })
                }
              ]}
            >
              <BRFormInputCounter
                label={intl.formatMessage({
                  id: 'shipments.new_order.customer_details.order_notes'
                })}
                maxCount={200}
                optional
              >
                <Input.TextArea
                  placeholder={intl.formatMessage({
                    id: 'shipments.new_order.form_placeholder.delivery_notes_placeholder'
                  })}
                  autoSize={{ minRows: 3.1, maxRows: 3.1 }}
                  autoComplete={`${Math.random()}`}
                  maxLength={200}
                  allowClear
                />
              </BRFormInputCounter>
            </Form.Item>
          </div>
          <Divider type="vertical" className="br-customer-details_divider" />
          <div className="br-customer-details__customer-address">
            <p className="br-new-order__card-content-title">
              {intl.formatMessage({
                id: 'shipments.new_order.customer_details.address_info'
              })}
            </p>
            <div className="br-form-row br-form-row__city-area">
              <Form.Item
                name="cityCode"
                label={intl.formatMessage({
                  id: 'location_form_labels.city'
                })}
                rules={[{ required: true }]}
              >
                <Select
                  dropdownClassName="br-customer-details__city-dropdown"
                  dropdownStyle={{ minWidth: null }}
                  placeholder={intl.formatMessage({
                    id: `form.select_placeholder`
                  })}
                  showSearch
                  filterOption={(input, option) =>
                    option.children.props.children[0]
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) => {
                    handleCityChange(value);
                    resetDistrictField();
                  }}
                  autoComplete={`${Math.random()}`}
                  loading={isCitiesLoading}
                >
                  {cities?.map((city) => (
                    <Select.Option
                      disabled={!city?.dropOffAvailability}
                      key={city._id}
                      value={city._id}
                    >
                      <div
                        className={classnames(
                          'display-flex justify-space-between',
                          {
                            'br-uncovered-area': !city?.dropOffAvailability
                          }
                        )}
                      >
                        {language === LOCALE.AR ? city?.nameAr : city?.name}
                        {!city?.dropOffAvailability && (
                          <Tag className="ant-tag-light-gray">
                            {intl.formatMessage({
                              id: 'form.uncovered_zone'
                            })}
                          </Tag>
                        )}
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <BRArea
                availability={AVAILABILITY.DROPOFF}
                areas={areas}
                loading={areasLoading}
                disabled={!props.formRef.current?.getFieldValue('cityCode')}
              />
            </div>
            <Form.Item
              name="firstLine"
              rules={[
                {
                  required: true,
                  label: intl.formatMessage({
                    id: 'location_form_labels.street'
                  })
                },
                {
                  min: FIRST_LINE_MIN_LENGTH,
                  message: intl.formatMessage(
                    {
                      id: 'form.field_min_length'
                    },
                    {
                      label: intl.formatMessage({
                        id: 'location_form_labels.street'
                      }),
                      count: FIRST_LINE_MIN_LENGTH
                    }
                  )
                }
              ]}
            >
              <BRFormInputCounter
                label={intl.formatMessage({
                  id: 'location_form_labels.street'
                })}
                maxCount={250}
              >
                <Input
                  autoComplete={`${Math.random()}`}
                  placeholder={intl.formatMessage({
                    id: 'shipments.new_order.form_placeholder.street_placeholder'
                  })}
                  maxLength={250}
                  allowClear
                />
              </BRFormInputCounter>
            </Form.Item>
            <div className="br-form-row br-form-row__building-floor-apartment">
              <Form.Item
                name="buildingNumber"
                label={
                  <>
                    {intl.formatMessage({
                      id: 'location_form_labels.building'
                    })}
                    <span className="br-form-optional-label">
                      {intl.formatMessage({
                        id: 'form.optional_label'
                      })}
                    </span>
                  </>
                }
                rules={[{ max: 25 }]}
              >
                <Input
                  autoComplete={`${Math.random()}`}
                  placeholder={intl.formatMessage({
                    id: 'shipments.new_order.form_placeholder.building_placeholder'
                  })}
                  maxLength={25}
                  allowClear
                />
              </Form.Item>
              <Form.Item
                name="floor"
                label={
                  <>
                    {intl.formatMessage({
                      id: 'location_form_labels.floor'
                    })}
                    <span className="br-form-optional-label">
                      {intl.formatMessage({
                        id: 'form.optional_label'
                      })}
                    </span>
                  </>
                }
                rules={[
                  {
                    max: 25,
                    label: intl.formatMessage({
                      id: 'location_form_labels.floor'
                    })
                  }
                ]}
              >
                <Input
                  maxLength={25}
                  placeholder={intl.formatMessage({
                    id: 'shipments.new_order.form_placeholder.floor_placeholder'
                  })}
                  autoComplete={`${Math.random()}`}
                />
              </Form.Item>
              <Form.Item
                name="apartment"
                label={
                  <>
                    {intl.formatMessage({
                      id: 'location_form_labels.apartment'
                    })}
                    <span className="br-form-optional-label">
                      {intl.formatMessage({
                        id: 'form.optional_label'
                      })}
                    </span>
                  </>
                }
                rules={[
                  {
                    max: 25,
                    label: intl.formatMessage({
                      id: 'location_form_labels.apartment'
                    })
                  }
                ]}
              >
                <Input
                  maxLength={25}
                  placeholder={intl.formatMessage({
                    id: 'shipments.new_order.form_placeholder.apartment_placeholder'
                  })}
                  autoComplete={`${Math.random()}`}
                />
              </Form.Item>
            </div>
            <Form.Item
              name="secondLine"
              rules={[
                {
                  max: 50,
                  label: intl.formatMessage({
                    id: 'location_form_labels.landmarks'
                  })
                }
              ]}
            >
              <BRFormInputCounter
                label={intl.formatMessage({
                  id: 'location_form_labels.landmarks'
                })}
                maxCount={50}
                optional
              >
                <Input
                  autoComplete={`${Math.random()}`}
                  placeholder={intl.formatMessage({
                    id: 'shipments.new_order.form_placeholder.landmarks_placeholder'
                  })}
                  maxLength={50}
                  allowClear
                />
              </BRFormInputCounter>
            </Form.Item>
            <Form.Item
              name="isWorkAddress"
              valuePropName="checked"
              className="br-customer-details__work-address br-form-without-label"
            >
              <Checkbox>
                {intl.formatMessage({
                  id: 'shipments.new_order.form_labels.work_address'
                })}
                <Tooltip
                  title={intl.formatMessage({
                    id: 'shipments.new_order.tooltips.work_Address_tootip'
                  })}
                >
                  <img src={TooltipsIcon} alt="tooltip-note" />
                </Tooltip>
              </Checkbox>
            </Form.Item>
          </div>
        </div>
      </div>
    </>
  );
};

export default injectIntl(withRouter(CustomerDetails));
