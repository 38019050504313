import React, { useState, useRef } from 'react';
import { injectIntl } from 'react-intl';
import {
  Modal,
  InputNumber,
  DatePicker,
  Form,
  Button,
  // Checkbox,
  Select
} from 'antd';
import dayjs from 'dayjs';

import {
  TARGET_TYPES,
  PROMOTION_SUBTYPES,
  VALUE_TYPES,
  DEFAULT_SUBTYPE
} from 'constants/promotions';

import '../Promotions.less';

const AddPromotionModal = ({
  intl,
  onSuccess,
  close,
  isTierPromotion = false,
  ...restProps
}) => {
  const [selectedSubtype, setSelectedSubtype] = useState(
    PROMOTION_SUBTYPES[0].value
  );
  const [selectedValueType, setSelectedValueType] = useState(
    VALUE_TYPES[0].value
  );
  const [volumeCap, setVolumeCap] = useState({
    min: undefined,
    max: undefined
  });

  const formRef = useRef();

  const handleSubmit = ({
    subType,
    activeAt,
    inactiveAt,
    minVolumeCap,
    maxVolumeCap,
    volumeCapIntervalInDays,
    durationIntervalInDays,
    value
    // target
  }) => {
    const payload = {
      subType: DEFAULT_SUBTYPE,
      ...(selectedSubtype === PROMOTION_SUBTYPES[0].value
        ? isTierPromotion
          ? {
              durationIntervalInDays
            }
          : {
              activeAt: dayjs(activeAt).startOf('day').format(),
              inactiveAt: dayjs(inactiveAt).endOf('day').format()
            }
        : {
            minVolumeCap,
            maxVolumeCap,
            volumeCapIntervalInDays
          }),
      value: value / 100,
      valueType: VALUE_TYPES[0].value,
      percentage: value / 100,
      target: [TARGET_TYPES[0].value]
    };
    onSuccess(payload);
    close();
  };

  const disabledDate = (current) => {
    const inactiveAt = formRef.current.getFieldValue('inactiveAt');
    if (inactiveAt) {
      return current > dayjs(inactiveAt).startOf('day');
    }
    return current && current < dayjs().endOf('day');
  };

  const disabledExpiryDate = (current) => {
    const activeAt = formRef.current.getFieldValue('activeAt');
    return current < dayjs(activeAt).endOf('day');
  };

  return (
    <Modal title="Add Promotion" footer={null} {...restProps}>
      <Form
        onFinish={handleSubmit}
        initialValues={{
          activeAt: dayjs().add(1, 'd'),
          // target: [TARGET_TYPES[0].value],
          // subType: PROMOTION_SUBTYPES[0].value,
          valueType: VALUE_TYPES[0].value
        }}
        ref={formRef}
      >
        {/* <Form.Item
          label={intl.formatMessage({
            id: 'promotions.modal.promotion_type'
          })}
          name="subType"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Select
            onChange={(value) => {
              setSelectedSubtype(value);
            }}
          >
            {PROMOTION_SUBTYPES.map(({ label, value }) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item> */}
        {selectedSubtype === PROMOTION_SUBTYPES[0].value ? (
          isTierPromotion ? (
            <Form.Item
              label={intl.formatMessage({
                id: 'promotions.modal.duration'
              })}
              name="durationIntervalInDays"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <InputNumber
                className="full-width"
                placeholder={intl.formatMessage({
                  id: 'promotions.modal.placeholder.duration'
                })}
                min={1}
                step={1}
                prefix={intl.formatMessage({
                  id: 'pickups.pickup_details.repetition_info.days'
                })}
              />
            </Form.Item>
          ) : (
            <>
              <Form.Item
                label={intl.formatMessage({
                  id: 'promotions.modal.activation_date'
                })}
                name="activeAt"
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <DatePicker
                  placeholder={intl.formatMessage({
                    id: 'promotions.modal.placeholder.enter_date'
                  })}
                  disabledDate={disabledDate}
                  showToday={false}
                  format="ll"
                />
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({
                  id: 'promotions.modal.expiry_date'
                })}
                name="inactiveAt"
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <DatePicker
                  placeholder={intl.formatMessage({
                    id: 'promotions.modal.placeholder.enter_date'
                  })}
                  disabledDate={disabledExpiryDate}
                  showToday={false}
                  format="ll"
                />
              </Form.Item>
            </>
          )
        ) : (
          <>
            <div className="display-flex">
              <Form.Item
                label={intl.formatMessage({
                  id: 'promotions.modal.min_volume_cap'
                })}
                name="minVolumeCap"
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <InputNumber
                  className="full-width"
                  placeholder={intl.formatMessage({
                    id: 'promotions.modal.placeholder.volume_cap'
                  })}
                  min={1}
                  step={1}
                  onChange={(value) => {
                    setVolumeCap({ ...volumeCap, min: value });
                  }}
                />
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({
                  id: 'promotions.modal.max_volume_cap'
                })}
                name="maxVolumeCap"
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <InputNumber
                  className="full-width"
                  placeholder={intl.formatMessage({
                    id: 'promotions.modal.placeholder.volume_cap'
                  })}
                  min={volumeCap?.min || 1}
                  step={1}
                  onChange={(value) => {
                    setVolumeCap({ ...volumeCap, max: value });
                  }}
                />
              </Form.Item>
            </div>
            <Form.Item
              label={intl.formatMessage({
                id: 'promotions.modal.interval'
              })}
              name="volumeCapIntervalInDays"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <InputNumber
                className="full-width"
                placeholder={intl.formatMessage({
                  id: 'promotions.modal.placeholder.interval'
                })}
                min={1}
                step={1}
                prefix={intl.formatMessage({
                  id: 'pickups.pickup_details.repetition_info.days'
                })}
              />
            </Form.Item>
          </>
        )}
        {/* <Form.Item
          label={intl.formatMessage({
            id: 'promotions.modal.promotion_value_type'
          })}
          name="valueType"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Select
            onChange={(value) => {
              setSelectedValueType(value);
            }}
          >
            {VALUE_TYPES.map(({ label, value }) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item> */}
        {selectedValueType === VALUE_TYPES[0].value ? (
          <Form.Item
            label={intl.formatMessage({
              id: 'promotions.modal.percentage'
            })}
            name="value"
            rules={[
              {
                required: true
              }
            ]}
          >
            <InputNumber
              className="full-width"
              placeholder={intl.formatMessage({
                id: 'promotions.modal.placeholder.percentage'
              })}
              min={1}
              max={100}
              step={1}
              formatter={(value) => value && `${value}%`}
            />
          </Form.Item>
        ) : (
          <Form.Item
            label={intl.formatMessage({
              id: 'pricing.amount'
            })}
            name="value"
            rules={[
              {
                required: true
              }
            ]}
          >
            <InputNumber
              className="full-width"
              placeholder={intl.formatMessage({
                id: 'pricing.amount'
              })}
              min={1}
              step={1}
            />
          </Form.Item>
        )}
        {/* <Form.Item
          name="target"
          label={intl.formatMessage({
            id: 'promotions.modal.target'
          })}
          rules={[
            {
              required: true
            }
          ]}
        >
          <Checkbox.Group options={TARGET_TYPES} />
        </Form.Item> */}
        <div className="display-flex justify-flex-end">
          <Button className="br-promotions-modal__button" onClick={close}>
            {intl.formatMessage({
              id: 'common.cancel'
            })}
          </Button>
          <Button type="primary" htmlType="submit">
            {intl.formatMessage({
              id: 'promotions.modal.button.add'
            })}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default injectIntl(AddPromotionModal);
