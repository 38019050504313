import { injectIntl } from 'react-intl';
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import classnames from 'classnames';

import { MAP_DELIVERY_TYPES } from 'constants/hubs';

import DeliveryItem from './components/DeliveryItem/DeliveryItem';
import MutliPackageDeliveryItem from './components/MutliPackageDeliveryItem/MutliPackageDeliveryItem';

import './NewSwappingContainer.less';

const { Panel } = Collapse;

const NewSwappingContainer = ({
  list = {},
  scannedList = {},
  title1,
  title2,
  isMissedFirstMilePickups,
  intl,
  fmPickupsCount,
  showExtraDataInReceivedTable = false,
  useWeights,
  handleOnRemoveReceived,
  groupByBusiness = true,
  handleSizeChange
}) => {
  const renderDeliveryItem = ({ delivery, businessName }) => {
    const DeliveryComponent = delivery.multiPackages
      ? MutliPackageDeliveryItem
      : DeliveryItem;

    return (
      <DeliveryComponent
        delivery={delivery}
        businessName={businessName}
        showExtraDataInReceivedTable={showExtraDataInReceivedTable}
        useWeights={useWeights}
        handleSizeChange={handleSizeChange}
        handleOnRemoveReceived={handleOnRemoveReceived}
      />
    );
  };

  return (
    <>
      <div className="br-swapping-container">
        <div className="br-swapping-container__content left">
          <span className="br-swapping-container__title body-medium">
            {title1}
            {isMissedFirstMilePickups && (
              <span>
                {intl.formatMessage(
                  {
                    id: `hubs.packages_debrief.new_fm_pickup_table.missed_pickup`
                  },
                  {
                    count: fmPickupsCount
                  }
                )}
              </span>
            )}
          </span>
          <Collapse
            bordered={false}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            className="br-swapping-container__collapse-container"
          >
            {Object.keys(list).map(
              (businessName) =>
                (list[businessName].deliveries?.length ||
                  list[businessName].deliveriesCount) && (
                  <Panel
                    header={
                      <span className="br-new-swapping-container__panel_title">
                        {businessName} (
                        {list[businessName].deliveriesCount ||
                          list[businessName].deliveries?.length}
                        )
                        {isMissedFirstMilePickups && (
                          <span>
                            {intl.formatMessage(
                              {
                                id: `hubs.packages_debrief.new_fm_pickup_table.missed_pickup`
                              },
                              {
                                count:
                                  list[businessName].deliveriesCount ||
                                  list[businessName].deliveries?.length
                              }
                            )}
                          </span>
                        )}
                      </span>
                    }
                    key={list[businessName].businessId}
                    className="site-collapse-custom-panel"
                    collapsible={
                      !list[businessName].deliveries?.length ? 'disabled' : null
                    }
                    showArrow={
                      !list[businessName].deliveries?.length ? false : true
                    }
                  >
                    {list[businessName].deliveries?.map((delivery) => (
                      <div
                        className={classnames(
                          'br-new-swapping-container__panel-content',
                          {
                            'br-new-swapping-container__panel-content-missing':
                              isMissedFirstMilePickups
                          }
                        )}
                      >
                        <span> {delivery.trackingNumber}</span>
                        <span>
                          {MAP_DELIVERY_TYPES[delivery.type] ||
                            intl.formatMessage({
                              id: `common.empty_field`
                            })}
                        </span>
                      </div>
                    ))}
                  </Panel>
                )
            )}
          </Collapse>
        </div>
        <div className="br-swapping-container__content right">
          <span className="br-swapping-container__title body-medium">
            {title2}
          </span>
          <Collapse
            bordered={false}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            className="br-swapping-container__collapse-container"
          >
            {groupByBusiness
              ? Object.keys(scannedList).map(
                  (businessName) =>
                    (scannedList[businessName].deliveries?.length ||
                      scannedList[businessName].deliveriesCount) && (
                      <Panel
                        header={
                          <span className="br-new-swapping-container__panel_title">
                            {businessName} (
                            {scannedList[businessName].deliveriesCount ||
                              scannedList[businessName].deliveries?.length}
                            )
                          </span>
                        }
                        key={businessName}
                        className="site-collapse-custom-panel"
                        collapsible={
                          !scannedList[businessName].deliveries?.length
                            ? 'disabled'
                            : null
                        }
                        showArrow={
                          !scannedList[businessName].deliveries?.length
                            ? false
                            : true
                        }
                      >
                        {scannedList[businessName].deliveries?.map((delivery) =>
                          renderDeliveryItem({ delivery, businessName })
                        )}
                      </Panel>
                    )
                )
              : scannedList.map((delivery) =>
                  renderDeliveryItem({
                    delivery,
                    businessName: delivery.sender?.name
                  })
                )}
          </Collapse>
        </div>
      </div>
    </>
  );
};

export default injectIntl(NewSwappingContainer);
