import { useEffect, useState, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { Select, Form, Button, Checkbox, Tooltip, Image, Tabs } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import classnames from 'classnames';

import { getHubs, getHubStars } from 'services/hubs';
import { isAdminGroupUser, isUserAuthorized } from 'utils/helpers';
import {
  getAllCashierContent,
  applyAcctionOnRecord,
  cashierBulkDeposit,
  accountantBulkApprove,
  exportCashierPage,
  accountantSettleHub,
  getCashierLogs,
  printCashierReceipt,
  getFinanceRecords,
  exportFinancePage
} from 'services/cashier';

import { FORMAT_DATE, FORMAT_DATE_END_OF_DAY } from 'utils/helpers';
import { STAR_MAP_DEFAULT_DATE_FORMAT } from 'constants/stars';
import {
  IN_TRANSIT,
  INVESTIGATION,
  COLLECTED,
  DEPOSITED,
  PENDING,
  REJECTED,
  CASHIER_ROLE,
  ACCOUNTANT_ROLE,
  APPROVE_ACTION,
  ACTIVE_ROUTE,
  STATES_COLOR,
  SETTLED,
  FILTERS
} from 'constants/cashier';
import { openModal } from 'utils/modal';
import { showBill } from 'utils';
import {
  checkSearchInputRegex,
  getStateReciptsCount,
  processSelectedRows
} from 'utils/cashier';

import BRHeader from 'components/BRHeader/BRHeader';
import Container from 'components/Container/index';
import CashierDateFilter from 'components/Cashier/components/CashierDateFilter/CashierDateFilter';
import BRTable from 'components/BRTable/BRTable';
import { notify } from 'components/Notify/Notify';
import CashierBulkActionModal from 'components/Cashier/components/CashierBulkActionModal/CashierBulkActionModal';
import CashierRejectModal from './components/CashierRejectModal/CashierRejectModal';
import CashierLogs from './components/CashierLogs/CashierLogs';
import CashierApproveModal from './components/CashierApproveModal/CashierApproveModal';
import CashierReceiptNumberInfoDrawer from './components/CashierReceiptNumberInfoDrawer/CashierReceiptNumberInfoDrawer';
import BRButton from 'components/BRButton/BRButton';
import FinanceSettleModal from './components/FinanceSettleModal/FinanceSettleModal';

import { ReactComponent as RecieptIcon } from 'assets/imgRevamp/cashier-recipt-icon.svg';
import { ReactComponent as PendingIcon } from 'assets/imgRevamp/cashier-pending-icon.svg';
import { ReactComponent as DepostedIcon } from 'assets/imgRevamp/cashier-deposted-icon.svg';
import { ReactComponent as CollectedIcon } from 'assets/imgRevamp/cashier-collected-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/bosta-icons/Close.svg';
import { ReactComponent as CheckIcon } from 'assets/bosta-icons/Check.svg';
import { ReactComponent as RetryArrow } from 'assets/bosta-icons/retry-arrow.svg';
import { ReactComponent as PrinterIcon } from 'assets/bosta-icons/Printer.svg';
import { ReactComponent as DoubleCheckIcon } from 'assets/imgRevamp/cycles-states-column-double-check.svg';
import { ReactComponent as TooltipsIcon } from 'assets/imgRevamp/info-circle.svg';

import './Cashier.less';
import { getCurrency } from 'common/countries/countries-mapping';

const Cashier = ({ intl }) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const [allHubs, setAllHubs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedHub, setSelectedHub] = useState(
    isUserAuthorized(['HUB_CASHIER']) ? userInfo?.warehouseInfo?._id : null
  );
  const [hubStars, setHubStars] = useState([]);
  const [selectedStar, setSelectedStar] = useState(null);
  const [count, setCount] = useState(0);
  const [tableSelectedRows, setTableSelectedRows] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    createdAtStart: dayjs().format(STAR_MAP_DEFAULT_DATE_FORMAT),
    createdAtEnd: dayjs().format(STAR_MAP_DEFAULT_DATE_FORMAT)
  });
  const [summaryCardsInfo, setSummaryCardsInfo] = useState([]);
  const [filters, setFilters] = useState(null);
  const [showFinanceView, setShowFinanceView] = useState(
    isUserAuthorized(['ACCOUNTANT', 'SUPER_ADMIN'])
  );
  const [visible, setVisible] = useState({});
  const [accountantImageVisible, setAccountantImageVisible] = useState({});
  const [cashierLogs, setCashierLogs] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [toggleRowSelection, setToggleRowSelection] = useState(false);
  const [showSignatureSuccess, setShowSignatureSuccess] = useState(false);
  const [cashierSignatureURL, setCashierSignatureURL] = useState(
    userInfo?.cashierSignatureURL
  );

  const refreshTable = useRef();

  const internationalNumberFormat = new Intl.NumberFormat('en-US');

  const { TabPane } = Tabs;

  const onTabChange = (key) => {};

  useEffect(() => {
    getAllHubs();
  }, []);

  useEffect(() => {
    if (selectedHub) {
      getStarsInTheSelectedHub(selectedHub);
    }
    setSelectedStar();
  }, [selectedHub]);

  let summaryCards = [
    {
      title: intl.formatMessage({ id: 'cashier.pending' }),
      className: 'br-cashier-icon-yellow',
      icon: <PendingIcon />,
      state: PENDING,
      visible: !showFinanceView
    },
    {
      title: intl.formatMessage({ id: 'cashier.collected' }),
      className: 'br-cashier-icon-green',
      icon: <CollectedIcon />,
      state: COLLECTED,
      visible: !showFinanceView
    },
    {
      title: intl.formatMessage({ id: 'cashier.deposted' }),
      className: 'br-cashier-icon-blue',
      icon: <DepostedIcon />,
      state: DEPOSITED,
      visible: true
    },
    {
      title: intl.formatMessage({ id: 'cashier.settled' }),
      className: 'br-cashier-icon-green',
      icon: <CollectedIcon />,
      state: SETTLED,
      visible: showFinanceView
    }
  ];

  const onCashierReject = (record) => {
    openModal(CashierRejectModal, {
      onFinish: (data) => handleCashierReject(record, data)
    });
  };

  const handleCashierReject = async (record, data) => {
    try {
      const payload = {
        state: REJECTED,
        rejection_reason: data?.rejectionReason,
        receipt_number: record?.receiptNo
      };
      await applyAcctionOnRecord(record?.recordId, payload);
      updateTable();
    } catch (error) {
      notify(error.message);
    }
  };

  const handleOnPrintReceipt = async (record) => {
    try {
      const { data } = await printCashierReceipt({
        id: record?.recordId,
        cashierSignatureURL
      });
      showBill(data);
    } catch (error) {
      notify(error.message);
    }
  };

  const onApproveOrReEvaluate = (record) => {
    openModal(CashierApproveModal, {
      record,
      onFinish: (payload) => handleOnCashierApproveRequest(payload, record),
      stars: hubStars
    });
  };

  const handleOnCashierApproveRequest = async (payload, record) => {
    try {
      await applyAcctionOnRecord(record?.recordId, payload);
      updateTable();
    } catch (error) {
      notify(error.message);
    }
  };
  const FINANCE_TABLE_COLUMNS = [
    {
      title: intl.formatMessage({ id: 'cashier.columns.hub' }),
      dataIndex: 'hub',
      render: (hub) => (
        <div className="br-cashier__star-column-container">
          <span className="br-cashier__star-column-name finance">
            {hub?.name}
          </span>
          <span className="br-cashier__star-column-phone finance">
            {hub?.receiptNo}
          </span>
        </div>
      )
    },
    {
      title: intl.formatMessage({
        id: 'cashier.finance_table_columns.deposited_date'
      }),
      dataIndex: 'date',
      render: (date) => (
        <div className="br-cashier__star-column-container">
          <span className="br-cashier__star-column-name">
            {dayjs(date).tz().format('MMM D, YYYY')}
          </span>
          <span className="br-cashier__star-column-phone">
            {dayjs(date).tz().format('h:mm A')}
          </span>
        </div>
      )
    },
    {
      title: intl.formatMessage({ id: 'cashier.columns.status' }),
      dataIndex: 'state',
      render: (state, record) => (
        <div
          className={`br-cashier__state-column-container ${STATES_COLOR[state]}`}
        >
          {state}
        </div>
      )
    },
    {
      title: intl.formatMessage({
        id: 'cashier.finance_table_columns.safe_box'
      }),
      dataIndex: 'safeBox'
    },
    {
      title: intl.formatMessage({
        id: 'cashier.finance_table_columns.remaining'
      }),
      dataIndex: 'remaining',
      render: (amount) => (
        <span
          className={classnames('br-fincnce-remaining-amount', {
            'br-fincnce-remaining-amount-green': amount > 0,
            'br-fincnce-remaining-amount-red': amount < 0
          })}
        >
          {amount > 0 && '+'}
          {parseFloat(amount)}
        </span>
      )
    },
    {
      title: intl.formatMessage({
        id: 'cashier.finance_table_columns.deposited_claim'
      }),
      dataIndex: 'depositedClaim'
    },
    {
      title: intl.formatMessage({
        id: 'cashier.finance_table_columns.received_amount'
      }),
      dataIndex: 'receivedAmount',
      render: (amount, record) => (record?.state === DEPOSITED ? 'N/A' : amount)
    },
    {
      title: intl.formatMessage({ id: 'cashier.finance_table_columns.pod' }),
      dataIndex: 'pod',
      render: (pod) => pod && <Image src={pod} width={40} height={40} />
    },
    {
      dataIndex: 'actions',
      render: (_, record) =>
        record.state === DEPOSITED && (
          <div className="br-cashier__actions-container">
            <BRButton
              type="primary"
              onClick={() => settleHubAmount(record)}
              disabled={record?.intransit === 'N/A'}
              label={intl.formatMessage({ id: 'cashier.columns.settle' })}
            />
          </div>
        )
    }
  ];

  const COLUMNS = [
    {
      title: intl.formatMessage({ id: 'cashier.columns.receipt_number' }),
      dataIndex: 'receiptNo',
      render: (receiptNo, record) => (
        <span
          className="br-cashier-table__receipt-no-text body-medium"
          onClick={() => showReceiptDrawer(record)}
        >
          {receiptNo}
        </span>
      )
    },
    {
      title: intl.formatMessage({ id: 'cashier.columns.star_info' }),
      dataIndex: 'star',
      render: (star) => (
        <div className="br-cashier__star-column-container">
          <span
            className="br-cashier__star-column-name"
            onClick={() =>
              window.open(
                `stars/all/${star?.id}/cash-records?startDate=${dayjs(
                  dateFilter?.createdAtStart
                )
                  .utc()
                  .format()}&endDate=${dayjs(dateFilter?.createdAtEnd)
                  .utc()
                  .format()}`
              )
            }
          >
            {star?.name}
          </span>
          <span className="br-cashier__star-column-phone">{star?.id}</span>
        </div>
      )
    },
    {
      title: intl.formatMessage({ id: 'cashier.columns.date' }),
      dataIndex: 'date',
      render: (date) => (
        <div className="br-cashier__star-column-container">
          <span className="br-cashier__star-column-name">
            {dayjs(date).tz().format('MMM D, YYYY')}
          </span>
          <span className="br-cashier__star-column-phone">
            {dayjs(date).tz().format('h:mm A')}
          </span>
        </div>
      )
    },
    {
      title: intl.formatMessage({ id: 'cashier.columns.status' }),
      dataIndex: 'status',
      render: (state, record) => (
        <div
          className={`br-cashier__state-column-container ${
            STATES_COLOR[state]
          } ${[PENDING, REJECTED].includes(state) ? 'state-upper-case' : ''}`}
        >
          <span>{state}</span>
          {state === COLLECTED && <DoubleCheckIcon />}
          {state === REJECTED && (
            <Tooltip title={record?.rejectionReason}>
              <TooltipsIcon />
            </Tooltip>
          )}
        </div>
      )
    },
    {
      title: intl.formatMessage({ id: 'cashier.columns.star_wallet' }),
      dataIndex: 'amount',
      render: (amount, record) =>
        record?.status === ACTIVE_ROUTE ? (
          'N/A'
        ) : (
          <span
            className={classnames({
              'br-cashier__liability-amount': parseFloat(amount) !== 0
            })}
          >
            {parseFloat(amount)}
          </span>
        )
    },
    {
      title: intl.formatMessage({ id: 'cashier.columns.cod' }),
      dataIndex: 'cod_amount',
      render: (cod_amount, record) =>
        record?.status === ACTIVE_ROUTE ? 'N/A' : parseFloat(cod_amount)
    },
    {
      title: intl.formatMessage({ id: 'cashier.columns.pos' }),
      dataIndex: 'ccod_amount',
      render: (ccod_amount, record) =>
        record?.status === ACTIVE_ROUTE ? 'N/A' : parseFloat(ccod_amount)
    },
    {
      title: intl.formatMessage({ id: 'cashier.columns.bank_transfer' }),
      dataIndex: 'bt_amount',
      render: (bt_amount, record) =>
        record?.status === ACTIVE_ROUTE ? 'N/A' : parseFloat(bt_amount)
    },
    {
      title: intl.formatMessage({ id: 'cashier.columns.cod_collected' }),
      dataIndex: 'collectedAmounts',
      render: (collectedAmounts, record) =>
        [ACTIVE_ROUTE, PENDING].includes(record?.status) ? (
          'N/A'
        ) : record?.status === REJECTED ? (
          0
        ) : (
          <span
            className={classnames({
              'br-cashier__collected-amount':
                parseFloat(collectedAmounts?.cashCollected) !== 0
            })}
          >
            {parseFloat(collectedAmounts?.cashCollected)}
          </span>
        )
    },
    {
      dataIndex: 'actions',
      render: (_, record) => renderActionsColumn(record)
    }
  ];

  const getAllHubs = async () => {
    setIsLoading(true);
    try {
      const { result } = await getHubs();
      setAllHubs(result || []);
      const {
        data: { data }
      } = await getCashierLogs();
      setCashierLogs(data);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const getStarsInTheSelectedHub = async (value) => {
    try {
      const { message } = await getHubStars(value);
      setHubStars(message);
    } catch (error) {
      notify(error.message);
    }
  };

  const formatCashierRecords = (data) => {
    let formattedArray = [];
    data.forEach((record) => {
      formattedArray.push({
        recordId: record?.id,
        _id: JSON.stringify(record),
        receiptNo: record?.receipt_number || 'N/A',
        star: {
          name: record?.star_name,
          phone: record?.star_phone,
          id: record?.star_id
        },
        date:
          record?.state === ACTIVE_ROUTE
            ? record?.createdAt
            : record?.cashier_last_action_update_at,
        amount: record?.amount,
        ccod_amount: record?.ccod_amount ?? 0,
        cod_amount:
          parseFloat(record?.amount ?? 0) -
          parseFloat(record?.ccod_amount ?? 0) -
          parseFloat(record?.bt_amount ?? 0),
        bt_amount: record?.bt_amount ?? 0,
        status: record?.state,
        rejectionReason: record?.rejection_reason || '',
        inTransit_image: record?.deposited_receipt,
        posId: record?.pos_id,
        collectedAmounts: {
          cashCollected: record?.cod_collected_amount ?? 0,
          ccodCollected: record?.ccod_collected_amount ?? 0,
          btCollected: record?.bt_collected_amount ?? 0
        }
      });
    });
    return formattedArray;
  };

  const formatAccountantRecords = (data) => {
    let formattedArray = [];
    data.forEach((record) => {
      formattedArray.push({
        _id: record?.id,
        hub: { name: record?.hub_name, receiptNo: record?.receipt_number },
        date: record?.updatedAt,
        state: record?.state,
        safeBox: parseFloat(record?.safe_box_amount),
        remaining:
          parseFloat(record?.safe_box_amount ?? 0) -
          parseFloat(record?.deposited_claim_amount ?? 0),
        depositedClaim: parseFloat(record?.deposited_claim_amount),
        receivedAmount: parseFloat(record?.received_amount),
        pod: record?.deposited_receipt
      });
    });
    return formattedArray;
  };

  const getCashierRecords = async ({ page, limit, searchInputText, state }) => {
    try {
      const {
        data: { data, summary, count, starsCount }
      } = await getAllCashierContent({
        page,
        limit,
        ...(searchInputText ? { receipt_number: searchInputText } : {}),
        ...(state?.length ? { state: state.join(',') } : {}),
        hub_id: selectedHub,
        star_id: selectedStar,
        start_date: dateFilter.createdAtStart,
        end_date: dateFilter.createdAtEnd
      });
      setCount(starsCount);
      setSummaryCardsInfo(summary);
      setFilters({
        ...(searchInputText ? { receipt_number: searchInputText } : {}),
        ...(state?.length ? { state: state.join(',') } : {}),
        hub_id: selectedHub,
        star_id: selectedStar,
        start_date: dateFilter.createdAtStart,
        end_date: dateFilter.createdAtEnd
      });
      return {
        count: count,
        list: formatCashierRecords(data).reverse()
      };
    } catch (error) {
      notify(error.message);
      return {
        count: 0,
        list: []
      };
    }
  };

  const getFinanceTableInfo = async ({ page, limit, searchInputText }) => {
    let searchPayload = {};
    if (searchInputText) {
      searchPayload = checkSearchInputRegex(searchInputText, allHubs);
    }
    try {
      const {
        data: { data, summary, count }
      } = await getFinanceRecords({
        page,
        limit,
        ...searchPayload,
        start_date: dateFilter.createdAtStart,
        end_date: dateFilter.createdAtEnd
      });
      setCount(count);
      setSummaryCardsInfo(summary);
      setFilters({
        ...searchPayload,
        start_date: dateFilter.createdAtStart,
        end_date: dateFilter.createdAtEnd
      });
      return {
        count: count,
        list: formatAccountantRecords(data)
      };
    } catch (error) {
      notify(error.message);
      return {
        count: 0,
        list: []
      };
    }
  };

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = refreshMethod;
  };

  const updateTable = () => {
    refreshTable.current();
  };

  const handleSelectedRows = (keySelected) => {
    setTableSelectedRows(keySelected);
  };

  const handleOnCancelClick = async () => {
    if (isUserAuthorized(['ACCOUNTANT', 'SUPER_ADMIN'])) {
      await setShowFinanceView(true);
    }
    await setSelectedHub(
      isUserAuthorized('HUB_CASHIER') ? userInfo?.warehouseInfo?._id : null
    );
    await setSelectedStar(null);
    updateTable();
  };

  const handleOnSearchClick = async () => {
    if (selectedHub) await setShowFinanceView(false);
    updateTable();
  };

  const handleOnDateChange = async (values) => {
    const dateRange = {
      createdAtStart: values?.pickedUpAtStart,
      createdAtEnd: values?.pickedUpAtEnd
    };
    await setDateFilter({ ...dateRange });
    updateTable();
  };

  const handleCashierBulkAction = (record) => {
    openModal(CashierBulkActionModal, {
      records: record?.length ? record : tableSelectedRows,
      onFinish: (payload) => handleOnCashierBulkActionFinish(payload)
    });
  };

  const handleOnCashierBulkActionFinish = async (payload) => {
    try {
      await cashierBulkDeposit({
        ...payload,
        hub_id: userInfo?.warehouseInfo?._id,
        hub_name: userInfo?.warehouseInfo?.name
      });
      updateTable();
    } catch (error) {
      notify(error.message);
    }
  };

  const handleExportClick = async () => {
    try {
      const res = await exportCashierPage(filters);
      notify(res.message, 'success');
    } catch (error) {
      notify(error.message);
    }
  };
  const handleFinanceExportClick = async () => {
    try {
      const res = await exportFinancePage(filters);
      notify(res.message, 'success');
    } catch (error) {
      notify(error.message);
    }
  };

  const settleHubAmount = (record) => {
    openModal(FinanceSettleModal, {
      record,
      onFinish: (amount) => handleOnAcceptSettleHub(record, amount)
    });
  };

  const handleOnAcceptSettleHub = async (record, amount) => {
    try {
      await accountantSettleHub({
        id: record?._id.toString(),
        amount
      });
      updateTable();
    } catch (error) {
      notify(error.message);
    }
  };

  const showReceiptDrawer = (record) => {
    setOpen(true);
    setSelectedRecord(record);
  };

  const onCloseDrawer = () => {
    setOpen(false);
  };

  const renderActionsColumn = (record) => {
    return (
      <div className="br-cashier__actions-container">
        {isUserAuthorized(['HUB_CASHIER', 'SUPER_ADMIN']) &&
        record.status === PENDING ? (
          <>
            <BRButton
              onClick={() => onCashierReject(record)}
              label={<CloseIcon />}
              className="br-cashier__reject-button"
            />
            <BRButton
              type="primary"
              onClick={() => onApproveOrReEvaluate(record)}
              label={<CheckIcon />}
            />
          </>
        ) : isUserAuthorized(['HUB_CASHIER', 'SUPER_ADMIN']) &&
          record.status === REJECTED ? (
          <BRButton
            onClick={() => onApproveOrReEvaluate(record)}
            label={<RetryArrow />}
          />
        ) : [COLLECTED, DEPOSITED, INVESTIGATION].includes(record?.status) ? (
          <>
            <BRButton
              className="br-cashier__print-receipt-button"
              onClick={() => handleOnPrintReceipt(record)}
              label={<PrinterIcon />}
            />

            {isUserAuthorized(['HUB_CASHIER', 'SUPER_ADMIN']) &&
              record.status === COLLECTED && (
                <BRButton
                  className="br-cashier__print-receipt-button"
                  onClick={() => handleCashierBulkAction([record._id])}
                  label="Deposit"
                  type={'primary'}
                />
              )}
          </>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const renderTableTitle = () =>
    tableSelectedRows?.length ? (
      <div className="br-orders-table__print-selected-rows-container">
        <span className="br-orders-table__title-count">
          <Checkbox
            indeterminate
            onChange={() => {
              setToggleRowSelection(!toggleRowSelection);
            }}
          />
          {`${tableSelectedRows?.length} selected`}
        </span>

        <BRButton
          label="Deposit"
          className="br-orders-table__title__delete-btn-two"
          onClick={handleCashierBulkAction}
        />
      </div>
    ) : (
      intl.formatMessage(
        {
          id: 'cashier.table_title'
        },
        { count: internationalNumberFormat.format(count) }
      )
    );

  const handleOnSignatureClick = () => {
    openModal(CashierBulkActionModal, {
      isSignature: true,
      onFinish: handleOnCashierSignature
    });
  };

  const handleOnCashierSignature = (payload) => {
    try {
      localStorage.setItem(
        'userInfo',
        JSON.stringify({
          ...userInfo,
          cashierSignatureURL: payload
        })
      );
      setCashierSignatureURL(payload);
      setShowSignatureSuccess(true);
      setTimeout(() => {
        setShowSignatureSuccess((prev) => !prev);
      }, [1000]);
      notify('Signature submitted successfully', 'success');
    } catch (error) {
      notify(error.message);
    }
  };
  return (
    <Container
      header={
        <BRHeader title={intl.formatMessage({ id: 'sidebar.cashier' })} />
      }
      content={
        <Tabs defaultActiveKey="1" onChange={onTabChange}>
          <TabPane
            tab={intl.formatMessage({ id: 'cashier.cashier_tab_title' })}
            key="1"
          >
            <CashierReceiptNumberInfoDrawer
              open={open}
              onClose={onCloseDrawer}
              record={selectedRecord}
              actions={renderActionsColumn}
            />
            <div className="br-cashier-container">
              <div className="br-cashier__header-container">
                <div className="br-cashier__header__content">
                  <span className="br-cashier__header-title">
                    {intl.formatMessage({ id: 'cashier.title' })}
                  </span>
                  <span className="br-cashier__header-subtitle">
                    {intl.formatMessage({ id: 'cashier.sub_title' })}
                  </span>
                </div>
                <div className="br-cashier__cashier-date-action-container">
                  {isUserAuthorized(['HUB_CASHIER']) && (
                    <BRButton
                      label={
                        <span className="br-signature__container">
                          {showSignatureSuccess && <CheckOutlined />}
                          {intl.formatMessage({ id: 'cashier.signature' })}
                        </span>
                      }
                      type="primary"
                      onClick={handleOnSignatureClick}
                    />
                  )}

                  <CashierDateFilter
                    isActive={!isLoading}
                    filterData={dateFilter}
                    setFilterData={handleOnDateChange}
                  />
                </div>
              </div>
              <div className="br-cashier__filter-summary-container">
                <div className="br-cashier__filter-content">
                  <Form.Item
                    label={intl.formatMessage({
                      id: 'stars.create_edit_star.form_labels.hub'
                    })}
                  >
                    <Select
                      showSearch
                      size="large"
                      className="br-star-logs-hubs-filter__dropdown"
                      placeholder={intl.formatMessage({
                        id: 'hubs.rto_modal.select_hub_modal.dropdown_placeholder'
                      })}
                      value={selectedHub}
                      onSelect={setSelectedHub}
                      optionFilterProp="children"
                      loading={isLoading}
                      disabled={
                        isLoading ||
                        (!isAdminGroupUser() &&
                          isUserAuthorized(['HUB_CASHIER']))
                      }
                    >
                      {allHubs.map((option, index) => (
                        <Select.Option key={index} value={option._id}>
                          {option.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {!showFinanceView && (
                    <Form.Item
                      label={intl.formatMessage({
                        id: 'hubs.packages_debrief.star_label'
                      })}
                    >
                      <Select
                        showSearch
                        size="large"
                        className="br-star-logs-stars-filter__dropdown"
                        placeholder={intl.formatMessage({
                          id: 'star_map.select_star'
                        })}
                        value={selectedStar}
                        onSelect={setSelectedStar}
                        optionFilterProp="children"
                        loading={isLoading}
                        disabled={isLoading}
                      >
                        {hubStars.length &&
                          hubStars.map((option, index) => (
                            <Select.Option key={index} value={option._id}>
                              {option?.profile.firstName.replace(' ', '')}{' '}
                              {option?.profile.lastName}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  )}

                  <Button onClick={handleOnCancelClick}>
                    {intl.formatMessage({ id: 'common.cancel' })}
                  </Button>
                  <Button type="primary" onClick={handleOnSearchClick}>
                    {intl.formatMessage({ id: 'common.search' })}
                  </Button>
                </div>

                <div className="br-cashier__summary-content">
                  {!showFinanceView && (
                    <div className="br-cashier__summary-header">
                      <div className="br-cashier__summary-icon">
                        <RecieptIcon />
                      </div>

                      <div className="br-cashier__summary-card-values">
                        <p className="br-cashier__summary-card-values__title">
                          {intl.formatMessage({ id: 'cashier.recepits' })}
                        </p>
                        <div className="br-cashier__summary-card-values__value-content">
                          <span className="br-cashier__summary-card-values__value-content__text">
                            {intl.formatMessage({ id: 'cashier.rejected' })}
                            <span className="br-cashier__card-value">
                              {getStateReciptsCount(REJECTED, summaryCardsInfo)}
                            </span>
                          </span>
                          <span className="br-cashier__summary-card-values__value-content__text">
                            {intl.formatMessage({ id: 'cashier.collected' })}
                            <span className="br-cashier__card-value">
                              {getStateReciptsCount(
                                COLLECTED,
                                summaryCardsInfo
                              )}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="br-cashuer__summary-status-container">
                    {summaryCards.map((card, index) => {
                      if (!card.visible) return;
                      let value = 0;
                      let amount = 0;
                      const selectedCard = summaryCardsInfo.filter(
                        (cardInfo) => cardInfo?.state === card?.state
                      );
                      if (selectedCard.length) {
                        value = selectedCard[0].count;
                        if (showFinanceView) {
                          amount =
                            selectedCard[0][
                              `${card.state.toLowerCase()}_amount`
                            ];
                        } else {
                          amount = selectedCard[0].amount;
                        }
                      }
                      return (
                        <div className="br-cashier__summary-header" key={index}>
                          <div
                            className={`br-cashier__summary-icon ${
                              card.className || ''
                            }`}
                          >
                            {card.icon}
                          </div>
                          <div className="br-cashier__summary-card-values">
                            <p className="br-cashier__summary-card-values__title">
                              {card.title}
                            </p>
                            <span className="br-cashier__card-value">
                              {value}
                              {[COLLECTED, DEPOSITED, SETTLED].includes(
                                card.state
                              ) && (
                                <span
                                  className={`br-cashier__state-column-container br-cashier-summary-cards-tag ${
                                    STATES_COLOR[card.state]
                                  } caption-medium`}
                                >
                                  {getCurrency(parseFloat(amount)).localized}
                                </span>
                              )}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              {showFinanceView ? (
                <BRTable
                  className="br-orders-table"
                  searchPlaceholder={intl.formatMessage({
                    id: 'cashier.finance_placeholder'
                  })}
                  title={`${count} Receipts`}
                  columns={FINANCE_TABLE_COLUMNS}
                  pageLimit={50}
                  pageSizeOptions={[50, 100, 200]}
                  listFunction={getFinanceTableInfo}
                  onRowClick={() => {}}
                  emptySearchContent={{
                    title: intl.formatMessage({
                      id: 'cashier.empty_search_title'
                    }),
                    subtitle: intl.formatMessage({
                      id: 'cashier.empty_search_sub_title'
                    })
                  }}
                  showFilter={false}
                  shareMethods={acceptMethods}
                  exportListFileFunction={handleFinanceExportClick}
                  showSearch
                  hideFilterButton
                  showExport
                />
              ) : (
                <BRTable
                  expandable={{
                    indentSize: 0,
                    defaultExpandAllRows: true,
                    expandIcon: () => null
                  }}
                  className="br-orders-table"
                  searchPlaceholder={intl.formatMessage({
                    id: 'cashier.search_placeholder'
                  })}
                  title={renderTableTitle()}
                  columns={COLUMNS}
                  showSearch
                  pageLimit={50}
                  pageSizeOptions={[50, 100, 200]}
                  listFunction={getCashierRecords}
                  onRowClick={() => {}}
                  emptySearchContent={{
                    title: intl.formatMessage({
                      id: 'cashier.empty_search_title'
                    }),
                    subtitle: intl.formatMessage({
                      id: 'cashier.empty_search_sub_title'
                    })
                  }}
                  allowSelection
                  showSearchFilter={true}
                  handleSelectedRows={handleSelectedRows}
                  rowSelectionProps={{
                    getCheckboxProps: (record) => ({
                      disabled:
                        (isUserAuthorized(['HUB_CASHIER']) &&
                          record.status !== 'Collected') ||
                        (isUserAuthorized(['ACCOUNTANT', 'SUPER_ADMIN']) &&
                          record.status !== 'In-transit')
                    })
                  }}
                  shareMethods={acceptMethods}
                  exportListFileFunction={handleExportClick}
                  showExport
                  processSelectedRows={processSelectedRows}
                  resetSelection={toggleRowSelection}
                  tableFilters={FILTERS}
                />
              )}
            </div>
          </TabPane>
          {isUserAuthorized(['ACCOUNTANT', 'SUPER_ADMIN', 'HUB_CASHIER']) && (
            <TabPane
              tab={intl.formatMessage({ id: 'cashier.log_tab_title' })}
              key="2"
            >
              <CashierLogs cashierLogs={cashierLogs} />
            </TabPane>
          )}
        </Tabs>
      }
    />
  );
};

export default injectIntl(Cashier);
