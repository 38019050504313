import dayjs from 'dayjs';
import get from 'lodash/get';

import {
  ALL_COUNTRIES_CODES,
  COUNTRIES_CODE_NAME
} from 'common/countries/constants/countries';
import { DEFAULT_PAGE_SIZE } from 'constants/common';

import { fmt } from 'components/IntlWrapper/IntlWrapper';


const userInfo = JSON.parse(localStorage.getItem('userInfo'));

export const cleanEmptyString = (obj) => {
  if (Array.isArray(obj)) {
    return obj
      .map((v) => (v && typeof v === 'object' ? cleanEmptyString(v) : v))
      .filter((v) => !(v === '' || v === undefined || v === null));
  }
  return Object.entries(obj)
    .map(([k, v]) => [k, v && typeof v === 'object' ? cleanEmptyString(v) : v])
    .reduce(
      (a, [k, v]) =>
        v === '' || v === undefined || v === null ? a : ((a[k] = v), a),
      {}
    );
};

export const disabledDateSla = (current, date) => {
  const friday = dayjs().startOf(new Date(current).get('month')).day('Friday');
  return (
    (current && current.valueOf() > date.setDate(date.getDate() + 1)) ||
    new Date(friday).getDay() === new Date(current).getDay()
  );
};

export const secondsToTime = (secs) => {
  const divisor_for_minutes = secs % (60 * 60);
  const minutes = Math.floor(divisor_for_minutes / 60);

  const divisor_for_seconds = divisor_for_minutes % 60;
  const seconds = Math.ceil(divisor_for_seconds);

  return ` ${minutes < 10 ? `0${minutes}` : minutes}: ${seconds < 10 ? `0${seconds}` : seconds
    }`;
};

export const convertUTCTimeToLocalTime = (utcTime) => {
  return dayjs(dayjs().utc().hour(utcTime)).tz().hour();
};

export const numberRegex = /^\d+$/;

export const countDown = (values) => {
  const { timer, setTimer, setDisabled } = values;
  return setInterval(() => {
    if (timer > 0) {
      setTimer(timer - 1);
    }
    if (timer === 0) {
      setDisabled(false);
    }
  }, 1000);
};

export const TWO_MINUTES = 120;

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const FORMAT_DATE_TO_UTC = (date) => {
  if (dayjs.isDayjs(date)) {
    return date.utc().format();
  }
  return dayjs.utc(date).format();
};

export const FORMAT_DATE_TO_UTC_END_OF_DAY = (date) => {
  return dayjs.utc(date).endOf('day').format();
};

export const FORMAT_DATE = (date) => {
  if (dayjs.isDayjs(date)) {
    return date.format();
  }
  return dayjs(date).format();
};

export const FORMAT_DATE_END_OF_DAY = (date) => {
  return dayjs(date).endOf('day').format();
};

export const isUserAuthorized = (
  accessRoles,
  permissions,
  { countries = ALL_COUNTRIES_CODES } = {
    countries: ALL_COUNTRIES_CODES
  }
) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  if (userInfo?.adminGroup) {
    return permissions
      ? permissions.some((permission) =>
        get(userInfo.adminGroup.permissions, permission?.join('.'))
      ) && countries.includes(userInfo?.country?.code)
      : true;
  } else {
    return (
      userInfo?.roles?.some(
        (el) => accessRoles && accessRoles?.indexOf(el) !== -1
      ) && countries.includes(userInfo?.country?.code)
    );
  }
};

export const convertArrayToNestedObject = (array, value) => {
  //  This function to deal with handlechangeform function
  // if it takes array, It converts the array to nested object so it can set form fields
  const result = {};
  let nestedObj = result;
  array.forEach((key, index) => {
    nestedObj[key] = {};
    if (index === array.length - 1) {
      nestedObj[key] = value;
    } else {
      nestedObj = nestedObj[key];
    }
  });
  return result;
};

export const capitalizeFirstLetter = (string) => {
  if (!string) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const seperateNumberDigits = (number) => {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatBigMoneyValues = (number) => {
  if (!number) {
    return 0;
  } else if (number < 100000) {
    return seperateNumberDigits(number);
  }
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ];
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return number >= item.value;
    });

  return item
    ? seperateNumberDigits(Math.round((number / item.value) * 1000) / 1000) +
    item.symbol
    : '0';
};

export const capitalizaeFirstLetterOfSentence = (sentence) => {
  if (!sentence) {
    return '';
  }

  const arr = sentence?.toLowerCase().split(' ');
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  return arr.join(' ');
};

export const dates = (value, format) => {
  const date = format ? dayjs(value).tz().format(format) : dayjs(value).tz();
  return date;
};

export const convertSecondsToTime = (seconds = 0) => {
  return new Date(seconds * 1000).toISOString().substring(11, 19);
};

export const trimObjectStrings = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(trimObjectStrings);
  }

  const trimmedObject = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (typeof value === 'string') {
        trimmedObject[key] = value.trim();
      } else {
        trimmedObject[key] = trimObjectStrings(value);
      }
    }
  }

  return trimmedObject;
};

export const openInNewTab = (url) => {
  window.open(url, '_blank');
};

export const isDefaultBostaCourier = (roles) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  if (!isUserAuthorized(roles)) {
    return true;
  } else {
    return userInfo?.vendor?.isDefaultCourier ||
      userInfo?.vendor?.isDefaultCourier === undefined
      ? true
      : false;
  }
};

export const checkDate = (date) => {
  if (dayjs(date).isToday()) {
    return fmt({ id: `date_picker.filter_option.today` });
  } else if (dayjs(date).isYesterday()) {
    return fmt({ id: `date_picker.filter_option.yesterday` });
  } else if (dayjs(date).isTomorrow()) {
    return fmt({ id: `date_picker.filter_option.tomorrow` });
  } else {
    return;
  }
};

export const checkSmartDate = (
  date,
  format = 'ddd, DD MMM',
  showFormattedDateTime = false
) => {
  const formattedDate = checkDate(date);
  if (formattedDate) {
    return `${formattedDate}${showFormattedDateTime ? `, ${dates(date, 'h:mm A')}` : ''
      }`;
  } else {
    return dates(date, format);
  }
};

export const isAdminGroupUser = () => userInfo?.adminGroup;

export const getAdminGroupId = () => userInfo.adminGroup._id;

export const isVendor = () => {
  if (userInfo?.vendor) {
    return !userInfo?.vendor?.isDefaultCourier;
  }

  return false;
};

export const isDSPVendor = () => userInfo?.vendor?.isDSP;

export const excelSerialDateToDate = (serialDate) => {
  const excelBaseDate = new Date(Date.UTC(1899, 11, 30));
  const date = new Date(excelBaseDate.getTime() + serialDate * 86400000);

  return dayjs(date);
};

export const ENGLISH_CHARS = /[a-zA-Z0-9]/;

export const isArabic = (text) => /[\u0600-\u06FF]/.test(text);

export const getPaginationCount = ({ page, result = [], pageSize = DEFAULT_PAGE_SIZE }) => {
  if (result.length === pageSize) {
    return page * pageSize + 1;
  } else {
    return page * pageSize;
  }
};