import React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Modal } from 'antd';
import BRButton from 'components/BRButton/BRButton';

import './ConfirmationModal.less';

const ConfirmationModal = ({
  intl,
  close,
  modalAction,
  title,
  content,
  confirmButtonLabel,
  cancelButtonLabel,
  confirmButtonType,
  confirmButtonProps,
  cancelAction = false,
  showConfirmButton = true,
  ...props
}) => {
  const confirmAction = () => {
    modalAction();
    close();
  };

  const onCancel = () => {
    if (cancelAction) {
      cancelAction();
    }
    close();
  };

  return (
    <Modal
      title={title}
      onCancel={onCancel}
      width={null}
      wrapClassName="br-confirmation-modal"
      footer={
        <>
          <BRButton
            onClick={close}
            label={
              cancelButtonLabel ||
              intl.formatMessage({
                id: 'common.cancel'
              })
            }
            className="button"
          />

          {showConfirmButton && (
            <BRButton
              className="button"
              type={confirmButtonType || 'destructive-primary'}
              label={
                confirmButtonLabel ||
                intl.formatMessage({
                  id: 'common.delete'
                })
              }
              onClick={confirmAction}
              {...confirmButtonProps}
            />
          )}
        </>
      }
      {...props}
    >
      <span className="body">{content}</span>
    </Modal>
  );
};

export default injectIntl(ConfirmationModal);
