import React, { useState, useEffect, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { Select, Progress, Button, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment';

import { getHubs } from 'services/hubs';
import {
  getHubScores,
  getStarPerformanceThirdParty,
  exportStarsPerformanceTable
} from 'services/bib';
import { STAR_MAP_DEFAULT_DATE_FORMAT } from 'constants/stars';
import { getCurrency } from 'common/countries/countries-mapping';
import { HUB_LEADER, WAREHOUSE_CLERK } from 'common/aclMatrix';
import { isDefaultBostaCourier } from 'utils/helpers';
import { getUserWarehouseScope } from 'common/scopes';

import { notify } from 'components/Notify/Notify';
import Container from 'components/Container';
import BRHeader from 'components/BRHeader/BRHeader';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import HubPerformanceDateFilter from './HubPerformanceDateFilter/HubPerformanceDateFilter';
import BRTable from 'components/BRTable/BRTable';

import { ReactComponent as Filters } from 'assets/bosta-icons/Filters.svg';

import './HubPerformance.less';

const HubPerformance = ({ intl, location }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dateFilter, setDateFilter] = useState({
    dateFrom: moment().format(STAR_MAP_DEFAULT_DATE_FORMAT),
    dateTo: moment().format(STAR_MAP_DEFAULT_DATE_FORMAT)
  });
  const [allHubs, setAllHubs] = useState([]);
  const [filterMenuVisible, setFilterMenuVisible] = useState(false);
  const [selectedHub, setSelectedHub] = useState(null);
  const [hubAnalyticsInfo, setHubAnalyticsInfo] = useState({});
  const [defaultDate, setDefaultDate] = useState([]);
  const [count, setCount] = useState(0);
  const [historyView, setHistoryView] = useState(false);

  const { Option } = Select;
  const refreshTable = useRef();
  useEffect(() => {
    getAllHubs();
    getHubIdFromQuery();
  }, []);

  useEffect(() => {
    if (selectedHub) {
      getHubAnalytics();
      updateTable();
    }
  }, [selectedHub, dateFilter]);

  const getHubIdFromQuery = async () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const query = new URLSearchParams(location.search);
    const hubId = query.get('hubId');
    const date = {
      dateFrom: query.get('startDate'),
      dateTo: query.get('endDate')
    };
    await setSelectedHub(userInfo?.warehouseInfo?._id || null);
    // if (!isDefaultBostaCourier(['HUB_LEADER', 'WAREHOUSE_CLERK'])) {
    //   await setSelectedHub(userInfo?.warehouseInfo?._id || null);
    // } else {
    //   await setSelectedHub(hubId || null);
    // }
    if (date.dateFrom && date.dateTo) {
      await setDateFilter(date);
      await setDefaultDate([moment(date.dateFrom), moment(date.dateTo)]);
    }
    updateTable();
  };

  const getAllHubs = async () => {
    setIsLoading(true);
    try {
      const { result } = await getHubs();
      setAllHubs(getUserWarehouseScope(result || []));
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const getHubAnalytics = async () => {
    setIsLoading(true);
    try {
      const { hubScores } = await getHubScores({
        hubId: selectedHub,
        ...dateFilter
      });
      setHubAnalyticsInfo(hubScores ? hubScores : {});
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const onChange = (value) => {
    setSelectedHub(value);
  };

  const scoreCardsContent = [
    {
      title: intl.formatMessage({
        id: 'bib.hub_performance.score_card.daily_volume'
      }),
      value: hubAnalyticsInfo?.assignedVolume || 'N/A',
      isPercentage: false
    },
    {
      title: intl.formatMessage({
        id: 'bib.hub_performance.score_card.dsr'
      }),
      value: hubAnalyticsInfo?.deliverySuccessRate || 'N/A',
      isPercentage: true
    },
    {
      title: intl.formatMessage({
        id: 'bib.hub_performance.score_card.asr'
      }),
      value: hubAnalyticsInfo?.attemptSuccessRate || 'N/A',
      isPercentage: true
    },
    {
      title: intl.formatMessage({
        id: 'bib.hub_performance.score_card.fda'
      }),
      value: hubAnalyticsInfo?.fdaSuccessRate || 'N/A',
      isPercentage: true
    },
    {
      title: intl.formatMessage({
        id: 'bib.hub_performance.score_card.business_sla'
      }),
      value: hubAnalyticsInfo?.businessPromiseSuccessRate || 'N/A',
      isPercentage: true
    },
    {
      title: intl.formatMessage({
        id: 'bib.hub_performance.score_card.route_dispatch_time'
      }),
      value: hubAnalyticsInfo?.routesDispatchRate || 'N/A',
      isPercentage: true
    },
    {
      title: intl.formatMessage({
        id: 'bib.hub_performance.score_card.total_score'
      }),
      value: hubAnalyticsInfo?.score || 'N/A',
      isPercentage: true
    },
    {
      title: intl.formatMessage({
        id: 'bib.hub_performance.score_card.rank'
      }),
      value: hubAnalyticsInfo?.rank || 'N/A',
      isPercentage: false
    }
  ];

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = refreshMethod;
  };

  const updateTable = () => {
    refreshTable.current();
  };

  const filterOptionsMenu = () => {
    return (
      <>
        <div
          className="br-analytics-filter__cities-header"
          onClick={(e) => e.stopPropagation()}
        >
          <Button onClick={() => {}}>
            {intl.formatMessage({
              id: `common.cancel`
            })}
          </Button>
          <span> {intl.formatMessage({ id: 'cashier.states.title' })}</span>
          <Button
            type="primary"
            onClick={() => {}}
            // disabled={disableApplyButton}
            className="br-star-logs__apply-filter-action"
          >
            {intl.formatMessage({ id: 'common.apply' })}
          </Button>
        </div>

        <Menu trigger={['click']} onClick={(e) => e.domEvent.stopPropagation()}>
          <Menu.Item onClick={(e) => e.domEvent.stopPropagation()}>
            <div>
              <div className="br-star-logs__filter-menu-content">zeez</div>
            </div>
          </Menu.Item>
        </Menu>
      </>
    );
  };

  const filterComponent = () => {
    return (
      <div className="br-star-logs__filter-component-container">
        <Dropdown
          visible={filterMenuVisible}
          onVisibleChange={setFilterMenuVisible}
          overlay={filterOptionsMenu()}
          trigger={['click']}
          getPopupContainer={(trigger) => trigger.parentElement}
          overlayClassName="br-analytics-filter__cities-dropdown"
          destroyPopupOnHide
          placement="bottomRight"
        >
          <Button
            loading={isLoading}
            className="br-analytics-filter__cities-button"
            onClick={() => setFilterMenuVisible(!filterMenuVisible)}
          >
            <Filters />
            <span className="br-analytics-filter__cities-dropdown-title">
              {intl.formatMessage({ id: 'common.filters' })}
            </span>

            <DownOutlined />
          </Button>
        </Dropdown>
      </div>
    );
  };

  const formatStarRecords = (data = []) => {
    let formattedArray = [];
    data.forEach((record) => {
      formattedArray.push({
        star: { name: record.star_name, phone: record.star_phone },
        attendance: {
          value: record.attendance,
          workedDays: record.worked_days,
          allDays: record.all_days
        },
        ofd: record.route_ofd,
        deliverySr: record.delivery_sr,
        fakeExceptions: record.fake_exceptions,
        shortage: record.shortage,
        total: record.total_score,
        routeId: record.route_id
      });
    });
    return formattedArray;
  };

  const getStarPerformance = async ({ searchInputText }) => {
    try {
      if (!selectedHub) {
        return {
          count: 0,
          list: []
        };
      }
      const { result } = await getStarPerformanceThirdParty({
        warehouseId: selectedHub,
        ...dateFilter,
        ...(searchInputText && { starId: searchInputText?.split(',') })
      });
      setCount(result?.length || 0);
      return {
        count: result?.length || 0,
        list: formatStarRecords(result)
      };
    } catch (error) {
      notify(error.message);
    }
  };

  const handleExportClick = async (data, { searchInputText }) => {
    try {
      const { message } = await exportStarsPerformanceTable({
        warehouseId: selectedHub,
        ...dateFilter,
        ...(searchInputText && { starId: searchInputText?.split(',') })
      });
      notify(message, 'success');
    } catch (error) {
      notify(error.message);
    }
  };
  const COLUMNS = [
    {
      title: intl.formatMessage({
        id: 'bib.hub_performance.star_performance_table.star_name'
      }),
      dataIndex: 'star',
      render: (star) => (
        <div className="br-hub-performance__star-column-content">
          <span className="br-hub-performance__star-column-content-name">
            {star?.name}
          </span>
          <span className="br-hub-performance__star-column-content-phone">
            {star?.phone}
          </span>
        </div>
      )
    },
    {
      title: intl.formatMessage({
        id: 'bib.hub_performance.star_performance_table.route_id'
      }),
      dataIndex: 'routeId',
      render: (routeId) => (routeId ? routeId : 'N/A')
    },
    {
      title: intl.formatMessage({
        id: 'bib.hub_performance.star_performance_table.attendance'
      }),
      dataIndex: 'attendance',
      render: ({ value, workedDays, allDays }) => (
        <div className="br-hub-performance__attendance-column-content">
          <span>{value ? value : 'N/A'}</span>
          {historyView && (
            <span>
              {intl.formatMessage(
                {
                  id: 'bib.hub_performance.star_performance_table.out_of'
                },
                { value: workedDays, outOfDays: allDays ?? 30 }
              )}
            </span>
          )}
        </div>
      )
    },
    {
      title: intl.formatMessage({
        id: 'bib.hub_performance.star_performance_table.ofd'
      }),
      dataIndex: 'ofd',
      render: (ofd) => ofd
    },
    {
      title: intl.formatMessage({
        id: 'bib.hub_performance.star_performance_table.delivery_sr'
      }),
      dataIndex: 'deliverySr',
      render: (deliverySr) => (deliverySr ? deliverySr : 'N/A')
    },
    {
      title: intl.formatMessage({
        id: 'bib.hub_performance.star_performance_table.fake_exception'
      }),
      dataIndex: 'fakeExceptions',
      render: (fakeExceptions) => fakeExceptions
    },
    {
      title: intl.formatMessage({
        id: 'bib.hub_performance.star_performance_table.shortage'
      }),
      dataIndex: 'shortage',
      render: (shortage) => (shortage ? shortage : 'N/A')
    },
    {
      title: intl.formatMessage({
        id: 'bib.hub_performance.star_performance_table.total'
      }),
      dataIndex: 'total',
      render: (total) => (total ? total : 'N/A')
    }
    /*{
      dataIndex: 'actions',
      render: (_, record) => (
        <Button>
          {intl.formatMessage({
            id: 'bib.hub_performance.star_performance_table.view_logs'
          })}
        </Button>
      )
    }*/
  ];

  return (
    <Container
      header={
        <BRHeader
          title={intl.formatMessage({
            id: 'sidebar.hub_performance'
          })}
        />
      }
      content={
        <div className="br-hub-performance-container">
          <LoadingWrapper loading={isLoading}>
            <div className="br-hub-performance__hub-date-filter-container">
              <div className="br-hub-performance__hub-filter-container">
                <span className="br-hub-performance__hub-filter-title">
                  {intl.formatMessage({
                    id: 'bib.hub_performance.hub_filter_title'
                  })}
                </span>
                <Select
                  showSearch
                  placeholder={intl.formatMessage({
                    id: 'bib.hub_performance.hub_filter_placeholder'
                  })}
                  onChange={onChange}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  value={selectedHub}
                  disabled={
                    !isDefaultBostaCourier([HUB_LEADER, WAREHOUSE_CLERK])
                  }
                >
                  {allHubs?.map((option, index) => (
                    <Option key={index} value={option._id}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <HubPerformanceDateFilter
                setDateFilter={setDateFilter}
                defaultDate={defaultDate}
                setHistoryView={setHistoryView}
              />
            </div>

            <div className="br-hub-performance__card-container">
              <div className="br-hub-performance__card-rank-container">
                {/* <div className="br-hub-performance__card-previous-after-rank">
                <span className="br-hub-performance__card-previous-after-rank-title">
                  {intl.formatMessage({
                    id: 'bib.hub_performance.cards.previous_hub_title'
                  })}
                </span>
                <span className="br-hub-performance__card-previous-after-rank-percent">
                  88%
                </span>
                <span className="br-hub-performance__card-previous-after-subtitle">
                  {intl.formatMessage(
                    {
                      id: 'bib.hub_performance.cards.rank'
                    },
                    {
                      rank: 5
                    }
                  )}
                </span>
              </div> */}
                <div className="br-hub-performance__card__progress">
                  <Progress
                    type="circle"
                    width={140}
                    strokeWidth={4}
                    percent={
                      Math.floor(
                        (parseFloat(hubAnalyticsInfo?.score) / 10) * 10
                      ) || 0
                    }
                    gapDegree={150}
                    gapPosition="bottom"
                    format={(percent) => `${percent} %`}
                  />
                  <span className="br-hub-performance__card__progress__subtitle">
                    {intl.formatMessage(
                      {
                        id: 'bib.hub_performance.cards.assigned_order_title'
                      },
                      {
                        assignedOrders: hubAnalyticsInfo?.assignedVolume || 0
                      }
                    )}
                  </span>
                </div>
                {/* <div className="br-hub-performance__card-previous-after-rank br-after-hub">
                <span className="br-hub-performance__card-previous-after-rank-title">
                  {intl.formatMessage({
                    id: 'bib.hub_performance.cards.next_hub_title'
                  })}
                </span>
                <span className="br-hub-performance__card-previous-after-rank-percent">
                  88%
                </span>
                <span className="br-hub-performance__card-previous-after-subtitle">
                  {intl.formatMessage(
                    {
                      id: 'bib.hub_performance.cards.rank'
                    },
                    {
                      rank: 5
                    }
                  )}
                </span>
              </div> */}
              </div>
              <div className="br-hub-performance__higlight-card-container">
                <span className="br-hub-performance__hitlight-title">
                  {intl.formatMessage({
                    id: 'bib.hub_performance.highlight.title'
                  })}
                </span>
                <div className="br-hub-performance__highlight-cards-content">
                  <div className="br-hub-performance__highlight-card-info-content">
                    <div className="br-hub-performance__highlight-card-info-missed-deliveries">
                      <span className="br-hub-performance__highlight-card-info-missed-deliveries-value">
                        {hubAnalyticsInfo?.missedDeliveries || 0}
                      </span>
                      <span className="br-hub-performance__highlight-card-info-missed-deliveries-title">
                        {intl.formatMessage({
                          id: 'bib.hub_performance.highlight.missed_deliveries'
                        })}
                      </span>
                    </div>
                    {/* <div className="br-hub-performance__highlight-card-info-missed-deliveries-extra-info">
                    <div className="br-hub-performance__highlight-card-info-missed-deliveries-extra-info-zero-attempts">
                      <span className="br-hub-performance__highlight-card-info-missed-deliveries-extra-info-value">
                        {hubAnalyticsInfo?.missed_zero_attempt || 0}
                      </span>
                      <span className="br-hub-performance__highlight-card-info-missed-deliveries-extra-info-title">
                        {intl.formatMessage({
                          id: 'bib.hub_performance.highlight.zero_attempts'
                        })}
                      </span>
                    </div>
                    <div className="br-hub-performance__highlight-card-info-missed-deliveries-extra-info-sla">
                      <span className="br-hub-performance__highlight-card-info-missed-deliveries-extra-info-value">
                        {hubAnalyticsInfo?.missed_sla || 0}
                      </span>
                      <span className="br-hub-performance__highlight-card-info-missed-deliveries-extra-info-title">
                        {intl.formatMessage({
                          id: 'bib.hub_performance.highlight.sla'
                        })}
                      </span>
                    </div>
                  </div> */}
                  </div>
                  <div className="br-hub-performance__highlight-card-cost-content">
                    <div className="br-hub-performance__highlight-card-info-missed-deliveries">
                      <span className="br-hub-performance__highlight-card-info-missed-deliveries-value">
                        {hubAnalyticsInfo?.costPerShipment || 0}{' '}
                        {getCurrency().type}
                      </span>
                      <span className="br-hub-performance__highlight-card-info-missed-deliveries-title">
                        {intl.formatMessage({
                          id: 'bib.hub_performance.highlight.cost_per_shipment'
                        })}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="br-hub-performance__score-card-container">
              {scoreCardsContent.map((scoreCard) => (
                <div className="br-hub-performance__score-card-content">
                  <span className="br-hub-performance__score-card-title">
                    {scoreCard.title}
                  </span>
                  <span className="br-hub-performance__score-card-value">
                    {Math.floor((parseFloat(scoreCard.value) / 10) * 10) ||
                      'N/A'}
                    {scoreCard.isPercentage && '%'}
                  </span>
                </div>
              ))}
            </div>

            {
              <div className="br-hub-performance__table-container">
                <span className="br-hub-performance__table-header-text">
                  {intl.formatMessage({
                    id: 'bib.hub_performance.star_performance_table.header'
                  })}
                </span>
                <BRTable
                  className="br-orders-table"
                  searchPlaceholder={intl.formatMessage({
                    id: 'bib.hub_performance.star_performance_table.search_star_placeholder'
                  })}
                  title={intl.formatMessage(
                    {
                      id: 'bib.hub_performance.star_performance_table.title'
                    },
                    {
                      count: count
                    }
                  )}
                  columns={COLUMNS}
                  showSearch
                  pageLimit={50}
                  pageSizeOptions={[50, 100, 200]}
                  listFunction={getStarPerformance}
                  onRowClick={() => {}}
                  emptySearchContent={{
                    title: intl.formatMessage({
                      id: 'cashier.empty_search_title'
                    }),
                    subtitle: intl.formatMessage({
                      id: 'cashier.empty_search_sub_title'
                    })
                  }}
                  showSearchFilter={false}
                  shareMethods={acceptMethods}
                  showFilter={false}
                  exportListFileFunction={handleExportClick}
                  showExport
                />
              </div>
            }
          </LoadingWrapper>
        </div>
      }
    />
  );
};

export default injectIntl(withRouter(HubPerformance));
