import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';
import { Tooltip } from 'antd';

import { REJECTED } from 'constants/cashier';

import BRSearchableTable from 'components/BRSearchableTable/BRSearchableTable';

import { ReactComponent as TooltipsIcon } from 'assets/imgRevamp/info-circle.svg';

import './CashierLogs.less';

const CashierLogs = ({ cashierLogs = [], intl }) => {
  const [formattedLogs, setFormattedLogs] = useState([]);

  const formatStarLogs = (logs) => {
    let formattedLogs = [];
    logs?.map((log) => {
      formattedLogs.push({
        time: dayjs(log?.createdAt).tz().format('DD MMM YYYY  h:mm a'),
        event: log?.action,
        receipt_number: log?.receipt_number,
        amount: log?.amount,
        takenBy: log?.user_name,
        rejectionReason: log?.rejection_reason
      });
    });
    return formattedLogs;
  };

  const DataLogColumns = [
    {
      title: intl.formatMessage({
        id: `stars.star_details.table_columns.date_time`
      }),
      dataIndex: 'time'
    },
    {
      title: intl.formatMessage({
        id: `stars.star_details.table_columns.event_description`
      }),
      dataIndex: 'event',
      render: (event, record) =>
        event === REJECTED ? (
          <span className="br-cashier-logs__rejected-event">
            {event}
            <Tooltip title={record?.rejectionReason}>
              <TooltipsIcon />
            </Tooltip>
          </span>
        ) : (
          event
        )
    },
    {
      title: intl.formatMessage({
        id: `stars.star_details.table_columns.receipt_number`
      }),
      dataIndex: 'receipt_number'
    },
    {
      title: intl.formatMessage({
        id: `stars.star_details.table_columns.amount`
      }),
      dataIndex: 'amount'
    },
    {
      title: intl.formatMessage({
        id: `stars.star_details.table_columns.taken_by`
      }),
      dataIndex: 'takenBy'
    }
  ];

  useEffect(() => {
    if (cashierLogs?.length) {
      setFormattedLogs(formatStarLogs(cashierLogs));
    }
  }, [cashierLogs]);
  return (
    <>
      <BRSearchableTable
        className="br-data-log-table"
        title={intl.formatMessage({
          id: `cashier.logs.table_title`
        })}
        columns={DataLogColumns}
        listData={formattedLogs}
        onRowClick={() => {}}
        withOutCheckBoxes
      />
    </>
  );
};

export default injectIntl(CashierLogs);
