import React, { useEffect, useState, useRef, useContext } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Select, Button, Tabs, Modal } from 'antd';
import Icon from '@ant-design/icons';
import { CloseCircleFilled } from '@ant-design/icons';
import classnames from 'classnames';
import dayjs from 'dayjs';

import { getStarLiabilities } from 'services/stars';
import {
  getHubStars,
  deliveriesDebrief,
  getNextWorkingDay,
  endDeliveryDebrief,
  setMissedPickupsDeliveries
} from 'services/hubs';
import {
  BUSINESS_PICKUP,
  SOHO_PICKUP,
  COMPLETED_PICKUPS,
  COMPLETED_ORDERS_CODES,
  PENDING_ORDERS_CODES,
  PENDING_PICKUPS,
  EXCEPTION_ORDERS_CODES,
  LAST_MILE_PICKUPS_CODES,
  RECEIVED_AT_WAREHOUSE_CODE,
  COMPLETED_ORDERS_REACHES_FINAL_STATES_CODES
} from 'constants/hubs';
import { COUNTRY_CURRENCY } from 'constants/helper';
import { TAB_KEYS } from 'constants/debrief';
import { isReturnOrder } from 'utils/shipmentDetails';
import { openModal } from 'utils/modal';
import { getCurrency, isCcodVisible } from 'common/countries/countries-mapping';
import {
  isUserAuthorized,
  isDefaultBostaCourier,
  isAdminGroupUser
} from 'utils/helpers';
import aclMatrix, { VENDOR_MANAGER } from 'common/aclMatrix';
import { getCurrentUserHubInfo } from 'utils/hubs';
import { HubsContext } from 'contexts/hub.context';
import { hasWarehouseScope } from 'common/scopes';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import TasksTab from 'components/Hub/PackagesDebrief/PackagesDebriefTabs/TasksTab/TasksTab';
import ExceptionsTab from 'components/Hub/PackagesDebrief/PackagesDebriefTabs/ExceptionsTab/ExceptionsTab';
import PickupsTab from 'components/Hub/PackagesDebrief/PackagesDebriefTabs/PickupsTab/PickupsTab';
import DeliveredTab from 'components/Hub/PackagesDebrief/PackagesDebriefTabs/DeliveredTab/DeliveredTab';
import RTOTab from 'components/Hub/PackagesDebrief/PackagesDebriefTabs/RTOTab/RTOTab';
import CODTab from 'components/Hub/PackagesDebrief/PackagesDebriefTabs/CODTab/CODTab';
import SohoPickupTab from 'components/Hub/PackagesDebrief/PackagesDebriefTabs/SohoPickupTab/SohoPickupTab';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as RotateLeft } from 'assets/imgRevamp/rotate-left.svg';

import './PackagesDebrief.less';

const PackagesDebrief = ({ intl }) => {
  const {
    TASKS,
    EXCEPTIONS,
    PICKUPS,
    DELIVERED,
    RTO,
    COD,
    SOHO_PICKUPS,
    CCOD
  } = TAB_KEYS;

  const [isLoading, setIsLoading] = useState(false);
  const [isStarsLoading, setStarsLoading] = useState(false);
  const [hubs, setHubs] = useState([]);
  const [stars, setStars] = useState([]);
  const [starData, setStarData] = useState({
    starId: null,
    deliveries: [],
    pickups: [],
    routesIds: [],
    pickedupDeliveries: [],
    rtoDeliveries: [],
    pickedupSohoDeliveries: [],
    COD: []
  });
  const [starLiabilities, setStarLiabilities] = useState(0);
  const [startDebriefing, setStartDebriefing] = useState(false);
  const [tabsDataLengths, setTabsDataLengths] = useState({
    unrecievedExceptionOrders: 0,
    pickingUpLength: 0,
    unConfirmedCompletedOrders: 0,
    lastMilePickupsLength: 0,
    firstMilePickupsLength: 0,
    sohoPickupsLength: 0
  });
  const [nextWorkingDay, setNextWorkingDay] = useState('');
  const [currentUserHub, setCurrentUserHub] = useState(null);
  const [activeTabKey, setActiveTabKey] = useState(TASKS);
  const [isConfirmReceivingDisabled, setIsConfirmReceivingDisabled] =
    useState(false);
  const [isMissedFirstMilePickups, setIsMissedFirstMilePickups] =
    useState(false);
  const [fmMissedPickupsInfo, setFmMissedPickupsInfo] = useState({});

  const { scopedHubs } = useContext(HubsContext);

  const formRef = useRef('');
  const hasConfirmReceivingCallback = [
    EXCEPTIONS,
    PICKUPS,
    DELIVERED,
    SOHO_PICKUPS
  ].includes(activeTabKey);

  const resetData = (starId = null) => {
    setStartDebriefing(false);
    setStarLiabilities(0);
    setIsConfirmReceivingDisabled(true);
    setStarData({
      deliveries: [],
      pickups: [],
      routesIds: [],
      pickedupDeliveries: [],
      rtoDeliveries: [],
      pickedupSohoDeliveries: [],
      COD: [],
      starId
    });
    setTabsDataLengths({
      unrecievedExceptionOrders: 0,
      pickingUpLength: 0,
      unConfirmedCompletedOrders: 0,
      lastMilePickupsLength: 0,
      firstMilePickupsLength: 0,
      sohoPickupsLength: 0
    });
  };

  const getStarsOfHub = async (hubId) => {
    try {
      setStarsLoading(true);
      resetData();
      const { message } = await getHubStars(hubId);
      message &&
        setStars(message.filter((el) => el.emails?.[0]?.verified) || []);
      formRef.current.setFieldsValue({ starId: null });
      setStarsLoading(false);
    } catch (error) {
      setStarsLoading(false);
      notify(error.message);
    }
  };

  const handleGetNextWorkingDay = async () => {
    try {
      const nextDay = await getNextWorkingDay();
      setNextWorkingDay(dayjs(nextDay[0]?.dayDate).format('D MMM'));
    } catch (error) {
      notify(error.message);
    }
  };

  useEffect(() => {
    handleGetNextWorkingDay();
  }, []);

  useEffect(() => {
    if (!isAdminGroupUser() && isUserAuthorized([VENDOR_MANAGER])) {
      setHubs(scopedHubs);
    } else {
      const currentUserHub = getCurrentUserHubInfo();

      if (isAdminGroupUser() && hasWarehouseScope()) {
        setHubs(scopedHubs);
        if (scopedHubs.length === 1) {
          setCurrentUserHub(scopedHubs[0]);
          formRef.current.setFieldsValue({ hub: scopedHubs[0]._id });
          getStarsOfHub(scopedHubs[0]._id);
        }
      } else {
        setHubs(scopedHubs);
        if (currentUserHub?._id) {
          const currentHub = scopedHubs.find(
            (hub) => hub._id === currentUserHub._id
          );

          if (currentHub) {
            setCurrentUserHub(currentHub);
            formRef.current.setFieldsValue({ hub: currentHub._id });
            getStarsOfHub(currentHub._id);
          }
        }
      }
    }
  }, [scopedHubs]);

  const checkLoading = (loading) => {
    setIsLoading(loading);
  };

  const checkStarLiabilities = async (starId) => {
    let getStarId = null;
    if (starId) {
      getStarId = starId;
    } else {
      getStarId = formRef.current && formRef.current.getFieldValue('starId');
    }
    try {
      const { totalLiability } = await getStarLiabilities(getStarId);
      setStarLiabilities(totalLiability ?? 0);
    } catch (error) {
      setStarLiabilities(0);
      notify(error.message);
    }
  };

  const onFinish = async ({ starId }) => {
    checkStarLiabilities(starId);
    setIsMissedFirstMilePickups(false);
    try {
      setIsLoading(true);
      setStartDebriefing(true);
      const data = await deliveriesDebrief(starId);
      const {
        deliveries,
        pickups,
        routesIds,
        pickedupDeliveries,
        COD,
        rtoDeliveries,
        CCOD,
        CCODTotalAmount
      } = data;

      setStarData({
        deliveries: deliveries?.length ? deliveries : [],
        pickups: pickups?.length ? pickups : [],
        sohoPickups: pickups?.length
          ? pickups.filter((pickup) => pickup.type === SOHO_PICKUP)
          : [],
        routesIds: routesIds?.length ? routesIds : [],
        pickedupDeliveries: pickedupDeliveries?.length
          ? pickedupDeliveries.filter(
              (el) =>
                !RECEIVED_AT_WAREHOUSE_CODE.includes(el.state.code) &&
                el.pickupRequestType !== SOHO_PICKUP
            )
          : [],
        pickedupSohoDeliveries: pickedupDeliveries?.length
          ? pickedupDeliveries.filter(
              (el) =>
                !RECEIVED_AT_WAREHOUSE_CODE.includes(el.state.code) &&
                el.pickupRequestType === SOHO_PICKUP
            )
          : [],
        COD: COD?.length ? COD : [],
        rtoDeliveries: rtoDeliveries || [],
        CCOD: CCOD?.length ? CCOD : [],
        ccodTotalAmount: CCODTotalAmount ?? 0
      });

      let fmPickupsCount = 0;
      pickups.forEach((pickup) => {
        if (pickup.type !== SOHO_PICKUP) {
          if (pickup.pickupsCounter) {
            fmPickupsCount +=
              pickup.pickupsCounter - (pickup?.receivedOrdersCount ?? 0);
          } else if (pickup.deliveries?.length) {
            pickup.deliveries.forEach((delivery) => {
              if (!RECEIVED_AT_WAREHOUSE_CODE.includes(delivery.state.code)) {
                fmPickupsCount++;
              }
            });
          }
        }
      });
      setTabsDataLengths({
        pickingUpLength: pickups?.length
          ? pickups?.filter((el) => el.state === PENDING_PICKUPS).length
          : 0,
        unrecievedExceptionOrders: deliveries?.length
          ? deliveries?.filter((el) =>
              EXCEPTION_ORDERS_CODES.includes(el.state.code)
            ).length
          : 0,
        unConfirmedCompletedOrders: deliveries?.length
          ? deliveries?.filter(
              (el) =>
                COMPLETED_ORDERS_REACHES_FINAL_STATES_CODES.includes(
                  el.state.code
                ) &&
                !el?.isConfirmedDelivery &&
                !isReturnOrder(el) &&
                !el?.tickets?.length
            ).length
          : 0,
        firstMilePickupsLength: fmPickupsCount,
        lastMilePickupsLength: deliveries?.length
          ? deliveries?.filter(
              (el) =>
                LAST_MILE_PICKUPS_CODES.includes(el.state.code) &&
                el.pickupRequestType !== SOHO_PICKUP
            ).length
          : 0,
        sohoPickupsLength: pickedupDeliveries?.length
          ? pickedupDeliveries.filter(
              (el) =>
                !RECEIVED_AT_WAREHOUSE_CODE.includes(el.state.code) &&
                el.pickupRequestType === SOHO_PICKUP
            ).length
          : 0
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setStartDebriefing(false);
      notify(error.message);
    }
  };

  const confirmReceiving = {
    [EXCEPTIONS]: () => {},
    [PICKUPS]: () => {},
    [DELIVERED]: () => {},
    [SOHO_PICKUPS]: () => {}
  };

  const tabs = [
    {
      tabKey: TASKS,
      tabName: intl.formatMessage(
        {
          id: `hubs.packages_debrief.tabs.tasks`
        },
        {
          length:
            starData.pickups.filter(
              (el) =>
                el.state === PENDING_PICKUPS ||
                COMPLETED_PICKUPS.includes(el.state)
            ).length +
            starData.deliveries.filter(
              (el) =>
                PENDING_ORDERS_CODES.includes(el.state.code) ||
                COMPLETED_ORDERS_CODES.includes(el.state.code)
            ).length
        }
      ),
      tabContent: () => <TasksTab starData={starData} />
    },
    {
      tabKey: EXCEPTIONS,
      tabName: (
        <div className="br-package-debrief__tab-name">
          {intl.formatMessage(
            {
              id: `hubs.packages_debrief.tabs.exceptions`
            },
            {
              length: tabsDataLengths.unrecievedExceptionOrders
            }
          )}
          <span
            className={classnames('dot', {
              'success-dot':
                startDebriefing &&
                !isLoading &&
                tabsDataLengths.unrecievedExceptionOrders === 0
            })}
          ></span>
        </div>
      ),
      tabContent: () => (
        <ExceptionsTab
          starData={starData}
          getExceptions={() =>
            onFinish({ starId: formRef.current.getFieldValue('starId') })
          }
          starId={formRef.current && formRef.current.getFieldValue('starId')}
          hubName={
            currentUserHub?.name ||
            (formRef.current &&
              hubs.find((el) => el._id === formRef.current.getFieldValue('hub'))
                ?.name)
          }
          nextWorkingDay={nextWorkingDay}
          handleConfirmReceiving={confirmReceiving}
          setIsConfirmReceivingDisabled={setIsConfirmReceivingDisabled}
        />
      )
    },
    {
      tabKey: PICKUPS,
      tabName: (
        <div className="br-package-debrief__tab-name">
          {intl.formatMessage(
            {
              id: `hubs.packages_debrief.tabs.pickups`
            },
            {
              length: isDefaultBostaCourier([
                'VENDOR_MANAGER',
                'HUB_LEADER',
                'WAREHOUSE_CLERK'
              ])
                ? tabsDataLengths.lastMilePickupsLength +
                  tabsDataLengths.firstMilePickupsLength
                : tabsDataLengths.lastMilePickupsLength
            }
          )}
          <span
            className={classnames('dot', {
              'success-dot':
                startDebriefing &&
                !isLoading &&
                tabsDataLengths.firstMilePickupsLength === 0 &&
                tabsDataLengths.lastMilePickupsLength === 0
            })}
          ></span>
        </div>
      ),
      tabContent: () => (
        <PickupsTab
          starData={starData}
          hubName={
            currentUserHub?.name ||
            (formRef.current &&
              hubs.find((el) => el._id === formRef.current.getFieldValue('hub'))
                ?.name)
          }
          getPickups={() =>
            onFinish({ starId: formRef.current.getFieldValue('starId') })
          }
          handleConfirmReceiving={confirmReceiving}
          setIsConfirmReceivingDisabled={setIsConfirmReceivingDisabled}
          isMissedFirstMilePickups={isMissedFirstMilePickups}
          setFmMissedPickupsInfo={setFmMissedPickupsInfo}
          setPackageDebriefpageLoading={setIsLoading}
        />
      )
    },
    isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER])
      ? {
          tabKey: SOHO_PICKUPS,
          tabName: (
            <div className="br-package-debrief__tab-name">
              {intl.formatMessage(
                {
                  id: `hubs.packages_debrief.tabs.soho_pickups`
                },
                {
                  length: tabsDataLengths.sohoPickupsLength
                }
              )}
              <span
                className={classnames('dot', {
                  'success-dot':
                    startDebriefing &&
                    !isLoading &&
                    tabsDataLengths.sohoPickupsLength === 0
                })}
              ></span>
            </div>
          ),
          tabContent: () => (
            <SohoPickupTab
              starData={starData}
              getPickups={() =>
                onFinish({ starId: formRef.current.getFieldValue('starId') })
              }
              handleConfirmReceiving={confirmReceiving}
              setIsConfirmReceivingDisabled={setIsConfirmReceivingDisabled}
            />
          )
        }
      : null,
    {
      tabKey: DELIVERED,
      tabName: (
        <div className="br-package-debrief__tab-name">
          {intl.formatMessage(
            {
              id: `hubs.packages_debrief.tabs.delivered`
            },
            {
              length:
                starData?.deliveries?.filter(
                  (el) =>
                    COMPLETED_ORDERS_REACHES_FINAL_STATES_CODES.includes(
                      el.state.code
                    ) &&
                    !el?.isConfirmedDelivery &&
                    !isReturnOrder(el)
                ).length || 0
            }
          )}
          <span
            className={classnames('dot', {
              'success-dot':
                startDebriefing &&
                !isLoading &&
                !starData?.deliveries?.filter(
                  (el) =>
                    COMPLETED_ORDERS_REACHES_FINAL_STATES_CODES.includes(
                      el.state.code
                    ) &&
                    !el?.isConfirmedDelivery &&
                    !isReturnOrder(el)
                ).length
            })}
          ></span>
        </div>
      ),
      tabContent: () => (
        <DeliveredTab
          hubName={
            currentUserHub?.name ||
            (formRef.current &&
              hubs.find((el) => el._id === formRef.current.getFieldValue('hub'))
                ?.name)
          }
          starData={starData?.deliveries}
          routesIds={starData?.routesIds}
          getUpdatedData={() =>
            onFinish({ starId: formRef.current.getFieldValue('starId') })
          }
          handleConfirmReceiving={confirmReceiving}
          setIsConfirmReceivingDisabled={setIsConfirmReceivingDisabled}
        />
      )
    },
    {
      tabKey: RTO,
      tabName: intl.formatMessage(
        {
          id: `hubs.packages_debrief.tabs.rto`
        },
        {
          length: starData?.rtoDeliveries?.length || 0
        }
      ),
      tabContent: () => (
        <RTOTab
          rtoDeliveries={starData?.rtoDeliveries}
          routesIds={starData?.routesIds}
          hubName={
            currentUserHub?.name ||
            (formRef.current &&
              hubs.find((el) => el._id === formRef.current.getFieldValue('hub'))
                ?.name)
          }
          getUpdatedData={() =>
            onFinish({ starId: formRef.current.getFieldValue('starId') })
          }
        />
      )
    },
    {
      tabKey: COD,
      tabName: intl.formatMessage(
        {
          id: `hubs.packages_debrief.tabs.cod`
        },
        {
          totalCOD: (
            <span className="reversed-currency">
              {
                getCurrency(
                  <span>
                    {starLiabilities
                      ? Math.abs(starLiabilities - starData.ccodTotalAmount)
                      : starLiabilities}
                  </span>
                ).localized
              }
            </span>
          )
        }
      ),
      tabContent: () => (
        <CODTab
          starId={formRef.current && formRef.current.getFieldValue('starId')}
          isDataLoading={checkLoading}
          getUpdatedData={() => checkStarLiabilities()}
          codRecords={starData?.COD}
        />
      )
    },
    isCcodVisible()
      ? {
          tabKey: CCOD,
          tabName: intl.formatMessage(
            {
              id: `hubs.packages_debrief.tabs.ccod`
            },
            {
              totalCOD: `${intl.formatMessage(
                {
                  id: COUNTRY_CURRENCY
                },
                {
                  cod: starData.ccodTotalAmount || 0
                }
              )}`
            }
          ),
          tabContent: () => (
            <></>
            // <CCODTab
            //   starId={
            //     formRef.current && formRef.current.getFieldValue('starId')
            //   }
            //   isDataLoading={checkLoading}
            //   codRecords={starData?.CCOD}
            //   refreshTable={() =>
            //     onFinish({
            //       starId:
            //         formRef.current && formRef.current.getFieldValue('starId')
            //     })
            //   }
            // />
          )
        }
      : null
  ];

  const cards = () => {
    const totalTasks =
      starData?.deliveries?.length +
      starData.pickups?.filter((el) =>
        [BUSINESS_PICKUP, SOHO_PICKUP].includes(el.type)
      ).length;
    const completedOrders = starData?.deliveries?.filter((el) =>
      COMPLETED_ORDERS_CODES.includes(el.state.code)
    ).length;

    const completedPickups = starData.pickups?.filter((el) =>
      COMPLETED_PICKUPS.includes(el.state)
    ).length;

    const successRate =
      ((completedOrders + completedPickups) / totalTasks) * 100;
    return [
      {
        cardLabel: intl.formatMessage({
          id: `hubs.packages_debrief.total_tasks`
        }),
        cardValue: totalTasks || 0
      },
      {
        cardLabel: intl.formatMessage({
          id: `hubs.packages_debrief.pending_orders`
        }),
        cardValue: starData?.deliveries?.filter((el) =>
          PENDING_ORDERS_CODES.includes(el.state.code)
        ).length
      },
      {
        cardLabel: intl.formatMessage({
          id: `hubs.packages_debrief.pending_pickups`
        }),
        cardValue: starData.pickups?.filter(
          (el) => el.state === PENDING_PICKUPS
        ).length
      },
      {
        cardLabel: intl.formatMessage({
          id: `hubs.packages_debrief.success_rate`
        }),
        cardValue: `${successRate ? successRate.toFixed() : 0} %`
      }
    ];
  };

  const endDebriefingModals = (props) => {
    const { close, onSuccess, title, subtitle, extraText, cancelCtaText } =
      props;

    return (
      <Modal
        {...props}
        title={null}
        className="br-packages-debrief__end-debrief-modal"
        footer={
          <div className="br-packages-debrief__end-debrief-modal__footer display-flex">
            {onSuccess ? (
              <>
                <Button onClick={close}>{cancelCtaText || 'Back'}</Button>
                <Button
                  type="primary"
                  onClick={() => {
                    onSuccess && onSuccess();
                    close();
                  }}
                >
                  Yes
                </Button>
              </>
            ) : (
              <Button type="primary" onClick={close}>
                Ok
              </Button>
            )}
          </div>
        }
        onCancel={close}
      >
        <div className="br-packages-debrief__end-debrief-modal__content">
          <span className="br-packages-debrief__end-debrief-modal__content__title">
            {title}
          </span>
          <span>{subtitle}</span>
          <span>{extraText}</span>
        </div>
      </Modal>
    );
  };

  const checkDebriefingErrorsConditions = (modalContent) => {
    if (modalContent.length) {
      openModal(endDebriefingModals, modalContent);
    } else checkWarningsCondition();
  };

  const handleEndDebriefingModalContent = () => {
    let modalContent = {
      title: (
        <>
          <CloseCircleFilled /> Can't end debriefing{' '}
        </>
      )
    };
    if (tabsDataLengths.pickingUpLength > 0) {
      modalContent = {
        ...modalContent,
        length: tabsDataLengths.pickingUpLength,
        lengthText: 'pickup requests in state "Picking up"',
        extraText: 'Please ask the star to settle them to end the debriefing.'
      };
    } else if (tabsDataLengths.unrecievedExceptionOrders > 0) {
      modalContent = {
        ...modalContent,
        length: tabsDataLengths.unrecievedExceptionOrders,
        lengthText: 'Exception orders are not received at warehouse.',
        extraText: 'Please receive them to end the debriefing.'
      };
    } else if (tabsDataLengths.unConfirmedCompletedOrders > 0) {
      modalContent = {
        ...modalContent,
        length: tabsDataLengths.unConfirmedCompletedOrders,
        lengthText: 'completed orders are not confirmed.',
        extraText: 'Please confirm all completed orders to end the debriefing.'
      };
    } else if (tabsDataLengths.lastMilePickupsLength > 0) {
      modalContent = {
        ...modalContent,
        length: tabsDataLengths.lastMilePickupsLength,
        lengthText: 'last mile pickups are not received at warehouse.',
        extraText: 'Please receive them to end the debriefing.'
      };
    } else if (tabsDataLengths.firstMilePickupsLength > 0) {
      setIsMissedFirstMilePickups(true);
      modalContent = {
        ...modalContent,
        length: tabsDataLengths.firstMilePickupsLength,
        lengthText: intl.formatMessage({
          id: `hubs.packages_debrief.new_fm_pickup_table.fm_missed_end_debrief_error`
        }),
        onSuccess: createFmMissedPickupTickets,
        cancelCtaText: intl.formatMessage({
          id: `common.cancel`
        })
      };
    }
    checkDebriefingErrorsConditions({
      ...modalContent,
      subtitle: (
        <span>
          <span className="bold mr-1">{modalContent.length}</span>
          {modalContent.lengthText}
        </span>
      )
    });
  };

  const checkWarningsCondition = () => {
    if (starLiabilities > 0) {
      openModal(endDebriefingModals, {
        title: 'Warning',
        subtitle: (
          <span>
            Pending liability:
            {<span className="bold">{starLiabilities}</span>}{' '}
            {intl.formatMessage(
              {
                id: COUNTRY_CURRENCY
              },
              {
                cod: null
              }
            )}
          </span>
        ),
        extraText:
          'Are you sure you want to end the debriefing with pending liability?',
        onSuccess: () => endDebriefingForStar()
      });
    } else {
      endDebriefingForStar();
    }
  };

  const endDebriefingForStar = async () => {
    try {
      if (!starData?.routesIds?.length) {
        notify(
          "Can't end debriefing with no route ids, at least one route",
          'error'
        );
      } else {
        setIsLoading(true);
        const response = await endDeliveryDebrief({
          routeIds: starData.routesIds,
          starId: formRef.current.getFieldValue('starId')
        });
        // downloadAsXlsx(
        //   response,
        //   `Debriefed_${moment(new Date()).format('DD-MM-YYYY')}.xlsx`
        // );
        notify(
          'Debriefing report will be sent to your mail in a short time.',
          'success'
        );
        formRef.current.setFieldsValue({ starId: null });
        getStarsOfHub(formRef.current.getFieldValue('hub'));
        setStartDebriefing(false);
      }
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleStarChange = (id) => {
    resetData(id);
  };

  const createFmMissedPickupTickets = async () => {
    setIsLoading(true);
    try {
      let formattedPayload = {
        ticketsPayload: { pickups: [] },
        setMissedPickupsPayload: { pickups: [], creatrionSrc: 'dashboard' }
      };
      Object.values(fmMissedPickupsInfo).forEach((missedPickup) => {
        if (missedPickup.deliveries?.length) {
          let deliveriesArray = [];
          missedPickup.deliveries.forEach((delivery) => {
            deliveriesArray.push(delivery?.trackingNumber);
          });
          formattedPayload.ticketsPayload.pickups.push({
            pickupRequestId: missedPickup.pickupRequestId,
            trackingNumbers: deliveriesArray
          });
          formattedPayload.setMissedPickupsPayload.pickups.push({
            pickupRequestId: missedPickup.pickupRequestId,
            trackingNumbers: deliveriesArray
          });
        }
        if (missedPickup.deliveriesCount) {
          formattedPayload.ticketsPayload.pickups.push({
            pickupRequestId: missedPickup.pickupRequestId,
            receivedCounter:
              missedPickup.originalDeliveriesCount -
              missedPickup.deliveriesCount,
            starCounter: missedPickup.originalDeliveriesCount
          });
          formattedPayload.setMissedPickupsPayload.pickups.push({
            pickupRequestId: missedPickup.pickupRequestId,
            missedOrdersCount: missedPickup.deliveriesCount
          });
        }
      });
      // await createMissedPickupsTicket(formattedPayload.ticketsPayload);
      if (formattedPayload.setMissedPickupsPayload.pickups.length) {
        await setMissedPickupsDeliveries(
          formattedPayload.setMissedPickupsPayload
        );
      }
      setIsMissedFirstMilePickups(false);
      checkWarningsCondition();
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  return (
    <LoadingWrapper loading={isLoading}>
      <div className="br-packages-debrief__container">
        <h4 className="br-packages-debrief__title">
          {intl.formatMessage({
            id: `hubs.packages_debrief.title`
          })}
        </h4>
        <Form ref={formRef} onFinish={onFinish} scrollToFirstError>
          <div className="br-form-row br-packages-debrief__form">
            <Form.Item
              name="hub"
              rules={[{ required: true }]}
              label={intl.formatMessage({
                id: `hubs.packages_debrief.hub_label`
              })}
            >
              <Select
                placeholder={intl.formatMessage({
                  id: `hubs.packages_debrief.select`
                })}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={getStarsOfHub}
                getPopupContainer={(trigger) => trigger.parentElement}
                disabled={
                  isLoading ||
                  ((!isAdminGroupUser() || !hasWarehouseScope()) &&
                    currentUserHub)
                }
              >
                {hubs?.map((hub) => (
                  <Select.Option key={hub._id} value={hub._id}>
                    {hub.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="starId"
              rules={[{ required: true }]}
              label={intl.formatMessage({
                id: `hubs.packages_debrief.star_label`
              })}
            >
              <Select
                placeholder={intl.formatMessage({
                  id: `hubs.packages_debrief.select`
                })}
                showSearch
                filterOption={(input, option) =>
                  option.children
                    ?.join('')
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                getPopupContainer={(trigger) => trigger.parentElement}
                loading={isStarsLoading}
                disabled={isStarsLoading}
                onChange={handleStarChange}
              >
                {stars.map((star) => (
                  <Select.Option key={star._id} value={star._id}>
                    {star.profile.firstName.replace(' ', '')}{' '}
                    {star.profile.lastName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <span>
              {startDebriefing ? (
                <div className="br-packages-debrief__end-debrief-buttons">
                  <Button
                    disabled={isLoading}
                    className="br-packages-debrief__form__submit-button"
                    type="primary"
                    onClick={handleEndDebriefingModalContent}
                    danger
                  >
                    {intl.formatMessage({
                      id: `hubs.packages_debrief.actions.end_debriefing`
                    })}
                  </Button>
                  <Button
                    disabled={isLoading}
                    className="br-packages-debrief__form__submit-button br-packages-debrief__end-debrief-buttons__refresh"
                    type="text"
                    onClick={() =>
                      onFinish({
                        starId: formRef.current.getFieldValue('starId')
                      })
                    }
                    icon={<Icon component={RotateLeft} />}
                  >
                    {intl.formatMessage({
                      id: `hubs.packages_debrief.actions.refresh`
                    })}
                  </Button>
                </div>
              ) : (
                <Button
                  className="br-packages-debrief__form__submit-button"
                  type="primary"
                  htmlType="submit"
                >
                  {intl.formatMessage({
                    id: `hubs.packages_debrief.actions.start_debriefing`
                  })}
                </Button>
              )}
            </span>
          </div>
        </Form>
        <div className="display-flex  br-packages-debrief__cards">
          {cards().map(({ cardLabel, cardValue }, index) => {
            return (
              <div className="br-packages-debrief__card" key={index}>
                <span>{cardLabel}</span>
                <span>{cardValue}</span>
              </div>
            );
          })}
        </div>
        <Tabs
          defaultActiveKey={tabs[0].tabName}
          className="br-packages-debrief__tabs"
          onChange={setActiveTabKey}
          tabBarExtraContent={{
            right: (
              <Button
                disabled={
                  !hasConfirmReceivingCallback || isConfirmReceivingDisabled
                }
                type="primary"
                onClick={() => confirmReceiving[activeTabKey]()}
              >
                {intl.formatMessage({
                  id: `hubs.packages_debrief.actions.confirm_receiving`
                })}
              </Button>
            )
          }}
        >
          {tabs.map((tab) => {
            return (
              tab && (
                <Tabs.TabPane tab={tab.tabName} key={tab.tabKey}>
                  {tab.tabContent()}
                </Tabs.TabPane>
              )
            );
          })}
        </Tabs>
      </div>
    </LoadingWrapper>
  );
};

export default injectIntl(PackagesDebrief);
