import XlsxPopulate from 'xlsx-populate';
import { omitBy, isUndefined } from 'lodash';
import dayjs from 'dayjs';
import {
  COMPENSATION_TEAM,
  COMPENSATION_RECORD_STATUS,
  COMPENSATION_DEPARTMENTS
} from 'constants/wallet';

const NUMBER_OF_HEADERS_IN_EXCEL = 1;

const userInfo = JSON.parse(localStorage.getItem('userInfo'));

const BUSINESS_CONFIGURATION_REQUIRED_COLUMNS_NAME = [
  'Business ID',
  'Business Name',
  'Pricing Plan',
  'Payment Type',
  'Cash-out Frequency',
  'Payment Gateway',
  'Extra COD Fees',
  '0 COD Discount',
  'Insurance Fees',
  'Next Day Transfer Fees',
  'Credit Limit'
];

const EXCEL_COLUMNS = {
  BUSINESS_ID: 0,
  BUSINESS_NAME: 1,
  ADJUSMENT: 2,
  DESCRIPTION: 3
};

const constructWalletAdjusment = (adjustment) => {
  return (
    adjustment?.length &&
    adjustment[EXCEL_COLUMNS.BUSINESS_ID] &&
    adjustment[EXCEL_COLUMNS.ADJUSMENT] && {
      businessId: adjustment[EXCEL_COLUMNS.BUSINESS_ID]?.toString().trim(),
      amount: adjustment[EXCEL_COLUMNS.ADJUSMENT],
      name: adjustment[EXCEL_COLUMNS.BUSINESS_NAME]?.toString().trim(),
      description: adjustment[EXCEL_COLUMNS.DESCRIPTION]?.toString().trim()
    }
  );
};

const constructWalletLinkFinancialAccount = (financialAccount) => {
  return (
    financialAccount?.length &&
    financialAccount[2] && {
      internalId: financialAccount[0]?.toString().trim(),
      dueDate: financialAccount[1]?.toString().trim(),
      businessId: financialAccount[2]?.toString().trim(),
      businessName: financialAccount[3]?.toString().trim(),
      quantity: financialAccount[4]?.toString().trim(),
      amount: financialAccount[5]?.toString().trim(),
      vat: financialAccount[6]?.toString().trim(),
      total: financialAccount[7]?.toString().trim(),
      accountGroup: financialAccount[8]?.toString().trim(),
      account: financialAccount[9]?.toString().trim(),
      type: financialAccount[10]?.toString().trim(),
      paymentDate: financialAccount[11]?.toString().trim(),
      paymentStatus: financialAccount[12]?.toString().trim(),
      documentNumber: financialAccount[13]?.toString().trim(),
      documentDate: financialAccount[14]?.toString().trim(),
      trackingNumber: financialAccount[15]?.toString().trim()
    }
  );
};

const constructBusinessConfig = (adjustment) => {
  return {
    businessId: adjustment[EXCEL_COLUMNS.BUSINESS_ID]?.toString().trim()
  };
};

export const convertExcelWalletToJSON = (file) => {
  return new Promise((resolve, reject) => {
    XlsxPopulate.fromDataAsync(file).then((workbook) => {
      try {
        const sheet = workbook.sheet('Balance Adjustments');
        const sheetAdjusment = sheet
          .usedRange()
          .value()
          .slice(NUMBER_OF_HEADERS_IN_EXCEL);

        const adjusments = sheetAdjusment
          .filter((row) => Object.keys(omitBy(row, (item) => !item)).length)
          .map((row) =>
            row.map((cell) =>
              cell instanceof XlsxPopulate.RichText
                ? cell.text().replace(/undefined/g, '')
                : cell
            )
          )
          .map(constructWalletAdjusment);
        resolve(Object.values(omitBy(adjusments, isUndefined)));
      } catch (error) {
        if (error.message.includes('usedRange')) {
          error.message = 'Wrong template';
        }
        reject(error);
      }
    });
  });
};

export const convertExcelBusinessConfigurationToJSON = (file) => {
  return new Promise((resolve, reject) => {
    XlsxPopulate.fromDataAsync(file).then((workbook) => {
      try {
        const sheet = workbook.sheet('BusinessesPaymentInfo');

        const sheetAdjusment = sheet
          .usedRange()
          .value()
          .slice(NUMBER_OF_HEADERS_IN_EXCEL);
        const sheetAdjusment2 = sheet.usedRange().value();

        if (
          JSON.stringify(sheetAdjusment2[0]) !==
          JSON.stringify(BUSINESS_CONFIGURATION_REQUIRED_COLUMNS_NAME)
        ) {
          reject({
            message: 'Unsupported Template!'
          });
        }

        const adjusments = sheetAdjusment
          .filter((row) => Object.keys(omitBy(row, (item) => !item)).length)
          .map((row) =>
            row.map((cell) =>
              cell instanceof XlsxPopulate.RichText
                ? cell.text().replace(/undefined/g, '')
                : cell
            )
          )
          .map(constructBusinessConfig);
        resolve(adjusments);
      } catch (error) {
        if (error.message.includes('usedRange')) {
          error.message = 'Wrong template';
        }
        reject(error);
      }
    });
  });
};

export const formatDepartmentOrAssigneeName = ({
  loopArray = [],
  extra = null
}) => {
  const formattedArray = [];
  loopArray.forEach((department) => {
    formattedArray.push({
      departmentName: department?.departmentName,
      assigneeName: department?.assigneeName
    });
  });
  if (extra) {
    if (extra?.isMDApproved) {
      formattedArray.push({
        departmentName: '',
        assigneeName: 'MD'
      });
    }
    if (extra?.isCFOApproved) {
      formattedArray.push({
        departmentName: '',
        assigneeName: 'CFO'
      });
    }
  }
  return formattedArray;
};

export const formatCreditPricingInfo = (compensationLiability) => {
  let formattedCreditPricingObject = {};
  compensationLiability.forEach((record) => {
    formattedCreditPricingObject[
      COMPENSATION_DEPARTMENTS[record?.departmentName]
    ] = record?.liability?.liabilityAmount;
  });
  return formattedCreditPricingObject;
};

export const formatFleetExpApproval = ({
  record,
  requestsToSubmitQueue = {}
}) => {
  let formattedApprovalObject = {
    approved: false,
    rejected: false,
    isNA: false,
    showUndo: false
  };
  const requestsSubmitted = Object.keys(requestsToSubmitQueue);
  const { firstName, lastName } = userInfo?.profile;
  switch (record?.status) {
    case COMPENSATION_RECORD_STATUS.PENDING_FIRST_APPROVAL_STATUS:
      if (requestsSubmitted.includes(record?._id)) {
        formattedApprovalObject = {
          approved:
            requestsToSubmitQueue[record?._id].action ===
            COMPENSATION_RECORD_STATUS.APPROVED,
          rejected:
            requestsToSubmitQueue[record?._id].action ===
            COMPENSATION_RECORD_STATUS.REJECTED,
          isNA: false,
          showUndo: true,
          takenBy: `${firstName || ''} ${lastName || ''}`,
          rejectionReason: requestsToSubmitQueue[record?._id].rejectionReason
        };
      }
      break;
    case COMPENSATION_RECORD_STATUS.FIRST_APPROVED_STATUS:
      if (
        record.logs.length === 0 &&
        record?.currentAssignedTeam === COMPENSATION_TEAM.FINANCE_TEAM
      ) {
        formattedApprovalObject.isNA = true;
      } else {
        const approvedLog = record?.logs.filter(
          (log) =>
            log?.beforeActionType ===
              COMPENSATION_RECORD_STATUS.PENDING_FIRST_APPROVAL_STATUS &&
            log?.afterActionType ===
              COMPENSATION_RECORD_STATUS.FIRST_APPROVED_STATUS
        )[0];
        if (approvedLog) {
          formattedApprovalObject = {
            approved: true,
            rejected: false,
            isNA: false,
            showUndo: false,
            takenBy: approvedLog?.takenBy?.name
          };
        }
      }
      break;
    case COMPENSATION_RECORD_STATUS.APPROVED:
      const approvedLog = record?.logs.filter(
        (log) =>
          log?.beforeActionType ===
          COMPENSATION_RECORD_STATUS.PENDING_FIRST_APPROVAL_STATUS
      )[0];
      if (approvedLog) {
        formattedApprovalObject = {
          approved: true,
          rejected: false,
          isNA: false,
          showUndo: false,
          takenBy: approvedLog?.takenBy?.name
        };
      } else {
        formattedApprovalObject = {
          approved: false,
          rejected: false,
          isNA: true,
          showUndo: false
        };
      }
      break;
    case COMPENSATION_RECORD_STATUS.REJECTED:
      const requiredLog = record?.logs.filter(
        (log) =>
          log?.beforeActionType ===
          COMPENSATION_RECORD_STATUS.PENDING_FIRST_APPROVAL_STATUS
      )[0];
      if (requiredLog) {
        if (
          requiredLog.afterActionType ===
          COMPENSATION_RECORD_STATUS.FIRST_APPROVED_STATUS
        ) {
          formattedApprovalObject = {
            approved: true,
            rejected: false,
            isNA: false,
            showUndo: false,
            takenBy: requiredLog?.takenBy?.name
          };
        } else {
          formattedApprovalObject = {
            approved: false,
            rejected: true,
            isNA: false,
            showUndo: false,
            takenBy: requiredLog?.takenBy?.name,
            rejectionReason: requiredLog?.reason
          };
        }
      } else {
        formattedApprovalObject = {
          approved: false,
          rejected: false,
          isNA: true,
          showUndo: false
        };
      }
      break;
  }
  return formattedApprovalObject;
};

export const formatFinanceApproval = ({
  record,
  requestsToSubmitQueue = {}
}) => {
  let formattedApprovalObject = {
    approved: false,
    rejected: false,
    isNA: false,
    showUndo: false
  };
  const requestsSubmitted = Object.keys(requestsToSubmitQueue);
  const { firstName, lastName } = userInfo?.profile;
  switch (record?.status) {
    case COMPENSATION_RECORD_STATUS.FIRST_APPROVED_STATUS:
      if (requestsSubmitted.includes(record?._id)) {
        formattedApprovalObject = {
          approved:
            requestsToSubmitQueue[record?._id].action ===
            COMPENSATION_RECORD_STATUS.APPROVED,
          rejected:
            requestsToSubmitQueue[record?._id].action ===
            COMPENSATION_RECORD_STATUS.REJECTED,
          isNA: false,
          showUndo: true,
          takenBy: `${firstName || ''} ${lastName || ''}`,
          rejectionReason: requestsToSubmitQueue[record?._id].rejectionReason
        };
      }
      break;
    case COMPENSATION_RECORD_STATUS.PENDING_FIRST_APPROVAL_STATUS:
      break;
    case COMPENSATION_RECORD_STATUS.APPROVED:
      const approvedLog = record?.logs.filter(
        (log) =>
          log?.beforeActionType ===
          COMPENSATION_RECORD_STATUS.FIRST_APPROVED_STATUS
      )[0];
      if (approvedLog) {
        formattedApprovalObject = {
          approved: true,
          rejected: false,
          isNA: false,
          showUndo: false,
          takenBy: approvedLog?.takenBy?.name
        };
      } else {
        formattedApprovalObject = {
          approved: false,
          rejected: false,
          isNA: true,
          showUndo: false
        };
      }
      break;
    case COMPENSATION_RECORD_STATUS.REJECTED:
      const requiredLog = record?.logs.filter(
        (log) =>
          log?.beforeActionType ===
          COMPENSATION_RECORD_STATUS.FIRST_APPROVED_STATUS
      )[0];
      if (requiredLog) {
        formattedApprovalObject = {
          approved: false,
          rejected: true,
          isNA: false,
          showUndo: false,
          takenBy: requiredLog?.takenBy?.name,
          rejectionReason: requiredLog?.reason
        };
      } else {
        formattedApprovalObject = {
          approved: false,
          rejected: false,
          isNA: true,
          showUndo: false
        };
      }
      break;
  }
  return formattedApprovalObject;
};

export const handleOnTicketUrlClick = (url, isBostaPage = false) => {
  let formattedUrl = url;
  if (!isBostaPage) {
    formattedUrl = url.toLowerCase();
    const includesHTTP = formattedUrl.includes('http');
    if (!includesHTTP) {
      formattedUrl = 'http://' + formattedUrl;
    }
  }

  window.open(formattedUrl, '_blank');
};

export const sortRequestsArray = (record) => {
  const statusWeight = {
    [COMPENSATION_RECORD_STATUS.APPROVED]: 2,
    [COMPENSATION_RECORD_STATUS.REJECTED]: 2,
    [COMPENSATION_RECORD_STATUS.FIRST_APPROVED_STATUS]: 1,
    [COMPENSATION_RECORD_STATUS.PENDING_FIRST_APPROVAL_STATUS]: 1
  };
  const x = record.sort((a, b) => {
    if (statusWeight[a.status] - statusWeight[b.status] === 0) {
      if (dayjs(a.createdAt) > dayjs(b.createdAt)) {
        return -1;
      } else if (dayjs(a.createdAt) < dayjs(b.createdAt)) {
        return 1;
      } else {
        return 0;
      }
    } else {
      return statusWeight[a.status] - statusWeight[b.status];
    }
  });
  return record;
};

const BULK_UPDATE_BUSINESS_PRICING_TIER = [
  'ID',
  'name',
  'Current Tier',
  'Final Tier'
];

const constructBulkUpdateBusinessPricingTier = (row) => {
  return {
    id: row[0]?.toString().trim(),
    name: row[1]?.toString().trim(),
    currentTier: row[2]?.toString().trim(),
    finalTier: row[3]?.toString().trim()
  };
};

export const convertExcelBulkUpdateBusinessessPricingTierToJSON = (file) => {
  return new Promise((resolve, reject) => {
    XlsxPopulate.fromDataAsync(file).then((workbook) => {
      try {
        const sheet = workbook.sheet('pricingTiers');

        const sheetAdjusment = sheet
          .usedRange()
          .value()
          .slice(NUMBER_OF_HEADERS_IN_EXCEL);
        const sheetWithoutHeaders = sheet.usedRange().value();

        if (
          JSON.stringify(sheetWithoutHeaders[0]) !==
          JSON.stringify(BULK_UPDATE_BUSINESS_PRICING_TIER)
        ) {
          reject({
            message: 'Unsupported Template!'
          });
        }

        const adjusments = sheetAdjusment
          .filter((row) => Object.keys(omitBy(row, (item) => !item)).length)
          .map((row) =>
            row.map((cell) =>
              cell instanceof XlsxPopulate.RichText
                ? cell.text().replace(/undefined/g, '')
                : cell
            )
          )
          .map(constructBulkUpdateBusinessPricingTier);
        resolve(adjusments);
      } catch (error) {
        if (error.message.includes('usedRange')) {
          error.message = 'Wrong template';
        }
        reject(error);
      }
    });
  });
};

export const getDepartmentLiability = ({ loopArray = [] }) => {
  let result = [];
  loopArray.forEach((department) => {
    if (department.liability?.liabilityOn?.length) {
      result = department.liability?.liabilityOn;
    }
  });

  return result;
};

export const convertExcelLinkFinancialAccountsToJSON = (file) => {
  return new Promise((resolve, reject) => {
    XlsxPopulate.fromDataAsync(file).then((workbook) => {
      try {
        const sheet = workbook.sheet('File Template');
        const sheetAdjusment = sheet
          .usedRange()
          .value()
          .slice(NUMBER_OF_HEADERS_IN_EXCEL);

        const adjusments = sheetAdjusment
          .filter((row) => Object.keys(omitBy(row, (item) => !item)).length)
          .map((row) =>
            row.map((cell) =>
              cell instanceof XlsxPopulate.RichText
                ? cell.text().replace(/undefined/g, '')
                : cell
            )
          )
          .map(constructWalletLinkFinancialAccount);
        resolve(Object.values(omitBy(adjusments, isUndefined)));
      } catch (error) {
        if (error.message.includes('usedRange')) {
          error.message = 'Wrong template';
        }
        reject(error);
      }
    });
  });
};
