import { useEffect } from 'react';
import { Modal } from 'antd';

import './BRScanningPopup.less';

const BRScanningPopup = ({
  actionTitle,
  actionData,
  bgClassName,
  scannerCallback,
  visible,
  onCancel,
  close,
  closeAutomatically,
  automaticCloseCallback = () => {}
}) => {
  useEffect(() => {
    if (scannerCallback) {
      document.addEventListener('keypress', scannerCallback);
      return () => {
        document.removeEventListener('keypress', scannerCallback);
      };
    }

    if (closeAutomatically) {
      setTimeout(function () {
        close();
        automaticCloseCallback();
      }, 3000);
    }
  }, []);

  return (
    <Modal
      visible={visible}
      destroyOnClose
      className={`br-scanning-popup ${bgClassName}`}
      footer={null}
      mask={false}
      onCancel={onCancel || close}
      width={null}
      centered
    >
      <div className="br-scanning-popup__actionTitle">{actionTitle}</div>
      <div className="br-scanning-popup__actionData">{actionData}</div>
    </Modal>
  );
};
export default BRScanningPopup;
