import { withRouter, Redirect, useRouteMatch } from 'react-router';
import { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { OpsControl } from 'contexts/ops-control.context';
import aclMatrix from 'common/aclMatrix';
import { isUserAuthorized } from 'utils/helpers';
import { ACL_MATRIX } from 'common/permissions';
import { getHubs } from 'services/hubs';

import Container from 'components/Container/index';
import BRTabs from 'components/BRTabs/BRTabs';
import BRHeader from 'components/BRHeader/BRHeader';
import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import SortingFacility from './SortingFacility/SortingFacility';

import './OpsControlContainer.less';

const OpsControlContainer = ({ intl }) => {
  const [allHubs, setAllHubs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { path } = useRouteMatch();

  useEffect(() => {
    getAllHubs();
  }, []);

  const getAllHubs = async () => {
    setIsLoading(true);
    try {
      const { result } = await getHubs();
      setAllHubs(result);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const TABS = {
    SortingFacility: {
      label: intl.formatMessage({ id: 'ops_control.sorting_facility_label' }),
      component: SortingFacility,
      path: '/ops-control/sorting-facility',
      exact: false,
      isUserAuthorized: isUserAuthorized(
        [...aclMatrix.DISPATCH_RTO_TAB, ...aclMatrix.HUB_OPERATIONS],
        [ACL_MATRIX.DELIVERY_MARK_AS_RECEIVED]
      )
    }
  };

  const getDefaultTabPath = () => {
    let pathName = '';
    Object.keys(TABS).some((tab) => {
      if (TABS[tab].isUserAuthorized) {
        pathName = TABS[tab].path;
        return true;
      }
    });
    return pathName;
  };

  const getTabsPath = () => {
    const path = [];
    Object.keys(TABS).filter((tab) => {
      return TABS[tab].isUserAuthorized && path.push(TABS[tab].path);
    });

    return path;
  };

  return (
    <LoadingWrapper loading={isLoading}>
      <OpsControl.Provider value={{ allHubs }}>
        <Switch>
          <Route
            path={getTabsPath()}
            render={() => (
              <Container
                className="br-hub-operation__content"
                header={
                  <BRHeader
                    title={intl.formatMessage({ id: 'sidebar.ops_control' })}
                  />
                }
                content={<BRTabs tabs={TABS} />}
              />
            )}
          />
          <Redirect from={path} to={getDefaultTabPath()} />
        </Switch>
      </OpsControl.Provider>
    </LoadingWrapper>
  );
};

export default injectIntl(withRouter(OpsControlContainer));
