import http from 'utils/http';
import { downloadHeader } from 'utils/download';

export const getAmazonRemittance = (payload) => {
  return http.get(`/wallet/amazon/remittance/`, payload);
};

export const getSingleAmazonRemittance = (id) => {
  return http.get(`/wallet/amazon/remittance/${id}`);
};

export const uploadAmazonTransaction = (payload) => {
  return http.post(`/wallet/amazon/remittance/`, payload, downloadHeader);
};

export const uploadAmazonTips = (payload) => {
  return http.post('wallet/amazon/tips', payload);
};

