import classnames from 'classnames';

import './MultiPackagesCount.less';

const MultiPackagesCount = ({ delivery }) => {
  const { multiPackages, scannedPackages = [] } = delivery;

  const isAllPackagesScanned = multiPackages === scannedPackages.length;

  return (
    <div
      className={classnames('br-multi-package__container', {
        'br-multi-packages__scanned': isAllPackagesScanned
      })}
    >
      <span>{scannedPackages.length} </span>
      <span> / {multiPackages}</span>
    </div>
  );
};

export default MultiPackagesCount;
