import classNames from 'classnames';

import { DELIVERIES_TYPE } from 'constants/sorting-facility';

import './SingleCardInfo.less';

const SingleInfoCard = ({ title, data, type }) => {
  return (
    <div className="single-info__container">
      <h3 className="single-info__header font-14">{title}</h3>
      <div className="single-info__data-container">
        <h6
          className={classNames(
            { 'single-info__pinding-orders': type === DELIVERIES_TYPE.PENDING },
            { 'single-info__damaged-orders': type === DELIVERIES_TYPE.DAMAGED },
            'font-24'
          )}
        >
          {data}
        </h6>
      </div>
    </div>
  );
};
export default SingleInfoCard;
