import React from 'react';
import { withRouter } from 'react-router';

import { fmt } from 'IntlWrapper/IntlWrapper';

import { ACL_MATRIX } from 'common/permissions';
import { COUNTRIES_CODE_NAME } from 'common/countries/constants/countries';

import BRSideTabs from 'components/BRSideTabs/BRSideTabs';
import PersonalProfile from 'components/NewSettings/components/PersonalProfile/PersonalProfile';
import Security from 'components/NewSettings/components/Security/Security';
import System from 'components/NewSettings/components/System/System';
import LiveOpsPriorities from './components/LiveOpsPriorities/LiveOpsPriorities';
import StarAllowances from 'components/NewSettings/components/StarAllowances/StarAllowances';
import aclMatrix, { SUPER_ADMIN } from 'common/aclMatrix';
import InternationalShippingSettings from './components/InternationalShipping/InternationalShippingSettings';
import BostaShop from './components/BostaShop/BostaShop';
import DispatchingConfiguration from 'components/NewSettings/components/DispatchingConfiguration/DispatchingConfiguration';
import ActiveSessions from 'components/NewSettings/components/ActiveSessions/ActiveSessions';
import HolidaySettings from './components/HolidaySettings/HolidaySettings';
import ScoreCard from 'components/NewSettings/components/ScoreCard/ScoreCard';

import { ReactComponent as SettingsPersonalProfileIcon } from 'assets/imgRevamp/settings_personal_profile_icon.svg';
import { ReactComponent as systemSettings } from 'assets/imgRevamp/systemSettings.svg';
import { ReactComponent as security } from 'assets/imgRevamp/shield.svg';
import { ReactComponent as ScoreCardIcon } from 'assets/imgRevamp/check.svg';
import { ReactComponent as LiveOpsPrioritiesIcon } from 'assets/imgRevamp/LiveOpsPrioritiesIcon.svg';
import { ReactComponent as BostaShopIcon } from 'assets/imgRevamp/shopping-bag.svg';

import './Settings.less';

const routes = [
  {
    name: fmt({ id: 'settings.tabs.personal_profile' }),
    path: 'personal-info',
    exact: true,
    icon: SettingsPersonalProfileIcon,
    isDefault: true,
    component: () => <PersonalProfile />
  },
  {
    name: fmt({ id: 'settings.tabs.security' }),
    path: 'security',
    exact: true,
    icon: security,
    component: () => <Security />
  },
  {
    name: fmt({ id: 'settings.tabs.active_sessions' }),
    path: 'active-sessions',
    exact: true,
    icon: security,
    component: () => <ActiveSessions />
  },
  {
    name: fmt({ id: 'settings.tabs.system' }),
    path: 'system',
    exact: true,
    icon: systemSettings,
    component: () => <System />,
    access: ['SUPER_ADMIN'],
    permission: [ACL_MATRIX.STARS_TOGGLE_COD_COLLECTION]
  },
  {
    name: fmt({ id: 'settings.tabs.score_card' }),
    path: 'score-card',
    exact: true,
    icon: ScoreCardIcon,
    iconClassName: 'br-settings-score-card-icon',
    component: () => <ScoreCard />,
    access: ['SUPER_ADMIN', 'FLEET_MANAGER'],
    permission: [ACL_MATRIX.STARS_CONFIGURE_PERFORMANCE]
  },
  {
    name: fmt({ id: 'settings.tabs.star_allowances' }),
    path: 'star_allowances',
    exact: true,
    icon: security,
    component: () => <StarAllowances />,
    access: aclMatrix.STAR_ALLOWANCES,
    permission: [ACL_MATRIX.STARS_CONFIGURE_SALARY]
  },
  {
    name: fmt({ id: 'settings.tabs.live_ops_priorities' }),
    path: 'live-ops-priorities',
    exact: true,
    icon: LiveOpsPrioritiesIcon,
    access: ['SUPER_ADMIN', 'FLEET_MANAGER'],
    permission: [ACL_MATRIX.DELIVERY_CONFIGURE_LIVE_OPS],
    component: () => <LiveOpsPriorities />
  },
  {
    name: fmt({ id: 'settings.tabs.international_shipping' }),
    path: 'box',
    exact: true,
    icon: systemSettings,
    access: [
      'BUSINESS_ADMIN',
      'SUPER_ADMIN',
      'OPERATIONS_MANAGER',
      'SALES_MANAGER',
      'SALES',
      'ON_BOARDING',
      'CUSTOMER_SUPPORT'
    ],
    permission: [ACL_MATRIX.BOX_COUNTRY_CREATE],
    component: () => <InternationalShippingSettings />
  },
  {
    name: fmt({ id: 'settings.tabs.bosta_shop' }),
    path: 'shop',
    exact: true,
    icon: BostaShopIcon,
    access: [SUPER_ADMIN],
    permission: [ACL_MATRIX.BOSTA_SHOP_CONFIGURE_FLYERS],
    component: () => <BostaShop />
  },
  {
    name: fmt({ id: 'settings.tabs.dispatching_configurations' }),
    path: 'dispatching_configuration',
    exact: true,
    icon: systemSettings,
    access: [SUPER_ADMIN],
    permission: [ACL_MATRIX.COUNTRY_CONFIGURE_DISPATCHING_CUTOFFS],
    component: () => <DispatchingConfiguration />
  },
  {
    name: fmt({ id: 'settings.tabs.holiday_settings' }),
    path: 'holiday-settings',
    icon: systemSettings,
    access: [SUPER_ADMIN],
    permission: [ACL_MATRIX.SLA_HOLIDAYS_CREATE],
    component: () => <HolidaySettings />
  }
];

const Settings = () => {
  return (
    <BRSideTabs
      showHeader
      headerTitle={fmt({
        id: 'nav_bar.settings'
      })}
      routes={routes}
    />
  );
};

export default withRouter(Settings);
