import { useState, useRef, useCallback, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Radio, Tooltip, Input, Button } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import classnames from 'classnames';
import { debounce } from 'lodash';

import { PACKAGE_SIZES, DEFAULT_PACKAGE_WEIGHT } from 'constants/hubs';

import { notify } from 'components/Notify/Notify';

import './PickupsTabActions.less';

const SCANNING_TYPE = ['Scanned', 'Selected', 'Default'];

const PickupsTabActions = ({
  intl,
  checkTrackingNumberExistance,
  packageSizes,
  useWeights = false,
  checkSealNumberExistance,
  showConfirmButton = false,
  handleOnConfirmReceiveClick,
  confirmButtonText,
  buttonDisabled
}) => {
  const [selectedScanningType, setSelectedScanningType] = useState('');
  const [scanType, setScanType] = useState(SCANNING_TYPE[2]);
  const [selectedFilterValue, setSelectedFilterValue] = useState('');
  const [inputsValues, setInputValues] = useState({
    trackingNumber: '',
    packageSize: '',
    sealPackageSize: '',
    sealNumber: ''
  });
  const [showActiveInput, setShowActiveInput] = useState(false);

  const trackingNumberRef = useRef('');
  const packageSizeRef = useRef('');
  const sealNumberRef = useRef('');

  const onChange = ({ target: { value } }) => {
    setSelectedFilterValue(value);
    setSelectedScanningType(value);
    setInputValues({
      ...inputsValues,
      packageSize: value,
      sealPackageSize: value
    });
    setScanType(SCANNING_TYPE[1]);
  };

  const checkIfPackageSizeEntered = (value, { isSealField }) => {
    const isSizeEntered = PACKAGE_SIZES.find(
      (size) => size.value.toLowerCase() === value?.toLowerCase()
    );

    let isValidWord = false;
    PACKAGE_SIZES.forEach((size) => {
      if (size.value.toLowerCase().includes(value?.toLowerCase())) {
        isValidWord = true;
        return;
      }
    });
    if (isSizeEntered) {
      setSelectedFilterValue(isSizeEntered?.value);
      setSelectedScanningType('');
      setScanType(SCANNING_TYPE[0]);
      setInputValues({
        trackingNumber: '',
        packageSize: isSealField ? '' : value,
        sealPackageSize: isSealField ? value : '',
        sealNumber: ''
      });
      if (isSealField) {
        sealNumberRef.current.focus({
          cursor: 'start'
        });
      } else {
        trackingNumberRef.current.focus({
          cursor: 'start'
        });
      }
    } else {
      if (!isValidWord) notify('Package size value is not correct.');
      setSelectedFilterValue(packageSizes[0].value);
    }
  };

  const resetInputs = (reseSecondInputOnly = false) => {
    setInputValues({
      trackingNumber: '',
      packageSize: reseSecondInputOnly ? inputsValues.packageSize : '',
      sealPackageSize: reseSecondInputOnly ? inputsValues.sealPackageSize : ''
    });
  };

  const onChangeTrackingNumberInput = async ({ target: { value } }) => {
    if (useWeights) {
      await setInputValues({ ...inputsValues, trackingNumber: value });
      checkTrackingNumberExistance(
        value,
        PACKAGE_SIZES[0].value,
        resetInputs,
        SCANNING_TYPE[1],
        DEFAULT_PACKAGE_WEIGHT
      );
    } else {
      await setInputValues({
        ...inputsValues,
        trackingNumber: value,
        sealNumber: ''
      });
      if (isNaN(value)) {
        checkIfPackageSizeEntered(value);
      } else {
        if (inputsValues?.packageSize === '') {
          setScanType(SCANNING_TYPE[2]);
          await setSelectedFilterValue(packageSizes[0].value);
          checkTrackingNumberExistance(
            value,
            packageSizes[0].value,
            resetInputs,
            SCANNING_TYPE[2]
          );
        } else {
          checkTrackingNumberExistance(
            value,
            selectedFilterValue,
            resetInputs,
            scanType,
            undefined,
            packageSizeRef
          );
        }
      }
    }
  };

  const onChangeSealNumberInput = async ({ target: { value } }) => {
    await setInputValues({
      ...inputsValues,
      trackingNumber: '',
      sealNumber: value
    });
    if (isNaN(value)) {
      checkIfPackageSizeEntered(value, { isSealField: true });
    } else {
      if (inputsValues?.sealPackageSize === '') {
        setScanType(SCANNING_TYPE[2]);
        await setSelectedFilterValue(packageSizes[0].value);
        checkSealNumberExistance(
          value,
          packageSizes[0].value,
          resetInputs,
          SCANNING_TYPE[2]
        );
      } else {
        checkSealNumberExistance(
          value,
          selectedFilterValue,
          resetInputs,
          scanType
        );
      }
    }
  };

  const checkPackageSize = useCallback(
    debounce(async (event, { isSealField }) => {
      const { value } = event.target;
      if (value !== '' && !isNaN(value)) {
        isSealField
          ? onChangeSealNumberInput(event)
          : onChangeTrackingNumberInput(event);
      } else {
        checkIfPackageSizeEntered(value, { isSealField });
      }
    }, 500),
    [packageSizes, onChangeTrackingNumberInput]
  );

  const onChangePackageSizeInput = (event, { isSealField }) => {
    const { value } = event.target;
    setInputValues({
      ...inputsValues,
      packageSize: isSealField ? '' : value,
      sealPackageSize: isSealField ? value : ''
    });
    setShowActiveInput(true);
    checkPackageSize(event, { isSealField });
  };

  const inputFields = ({ ref, isSealField = false, packageSizeRef }) => {
    return (
      <>
        <div
          className={classnames('br-pickups-tab-actions__scannig-type-title', {
            'br-pickups-tab-actions__scannig-type-title__only-weights':
              useWeights
          })}
        >
          {selectedScanningType === '' && !useWeights
            ? `Package Size / ${
                isSealField ? 'Security Seal Number' : 'Tracking Number'
              }`
            : isSealField
            ? 'Security Seal Number'
            : 'Tracking Number'}
          <Tooltip
            placement="top"
            title={
              isSealField
                ? 'SOHO pickups only'
                : intl.formatMessage({
                    id: `hubs.receive_new_pickup.pickups_tab_actions.tooltip.${
                      selectedScanningType === '' && !useWeights
                        ? 'sizes'
                        : 'weights'
                    }`
                  })
            }
          >
            <ExclamationCircleFilled />
          </Tooltip>
        </div>
        <Input.Group
          compact
          className={classnames({
            'br-pickups-tab-actions__only-weight': useWeights
          })}
        >
          {!useWeights && selectedScanningType === '' && (
            <Input
              className={classnames(
                'br-pickups-tab-actions__package-size-input',
                {
                  'br-pickups-tab-actions__package-size-active-input':
                    showActiveInput
                }
              )}
              ref={packageSizeRef}
              placeholder="Package Size /"
              onChange={(event) => {
                onChangePackageSizeInput(event, { isSealField });
              }}
              value={
                isSealField
                  ? inputsValues.sealPackageSize
                  : inputsValues.packageSize
              }
              onFocus={() => setShowActiveInput(true)}
              onBlur={() => setShowActiveInput(false)}
              onMouseEnter={() => setShowActiveInput(true)}
              onMouseLeave={() => setShowActiveInput(true)}
            />
          )}
          <Input
            className={classnames(
              {
                'br-pickups-tab-actions__tracking-number-input':
                  selectedScanningType === '' && !useWeights
              },
              {
                'br-pickups-tab-actions__customized-tracking-number-input':
                  selectedScanningType !== '' && !useWeights
              },
              {
                'br-pickups-tab-actions__active-tracking-number-input':
                  showActiveInput && selectedScanningType === '' && !useWeights
              }
            )}
            placeholder={
              isSealField ? 'Security Seal Number' : 'Tracking NUmber'
            }
            ref={ref}
            onFocus={() => setShowActiveInput(true)}
            onBlur={() => setShowActiveInput(false)}
            onChange={
              isSealField
                ? onChangeSealNumberInput
                : onChangeTrackingNumberInput
            }
            onMouseEnter={() => setShowActiveInput(true)}
            onMouseLeave={() => setShowActiveInput(true)}
            value={
              isSealField
                ? inputsValues.sealNumber
                : inputsValues.trackingNumber
            }
          />
        </Input.Group>
      </>
    );
  };

  useEffect(() => {
    useWeights &&
      trackingNumberRef.current.focus({
        cursor: 'start'
      });
  }, []);

  return (
    <div className="br-pickups-tab-actions">
      {!useWeights && (
        <div className="br-pickups-tab-actions__scannig-type-title">
          {intl.formatMessage({
            id: 'hubs.receive_new_pickup.pickups_tab_actions.scanning_type'
          })}
        </div>
      )}
      {!useWeights && (
        <div className="br-pickups-tab-actions display-flex align-center">
          <Radio.Group
            className="display-flex align-center"
            onChange={onChange}
            value={selectedScanningType}
          >
            {packageSizes.map(({ name, value }, index) => (
              <>
                {index === 0 && (
                  <>
                    <Radio.Button
                      className="br-pickups-tab-actions__radio-button"
                      value={''}
                    >
                      Scan size
                    </Radio.Button>
                    <div className="br-pickups-tab-actions__divider"></div>
                    <span className="br-pickups-tab-actions__label">
                      Select size
                    </span>
                  </>
                )}
                <Radio.Button
                  className="br-pickups-tab-actions__radio-button"
                  value={value}
                  key={value}
                >
                  {name}
                </Radio.Button>
              </>
            ))}
          </Radio.Group>
        </div>
      )}
      <div className="br-pickups-tab__scanning-inputs">
        <div>{inputFields({ ref: trackingNumberRef, packageSizeRef })}</div>
        {/* {checkSealNumberExistance && !useWeights && (
          <div>{inputFields({ ref: sealNumberRef, isSealField: true })}</div>
        )} */}
      </div>
      {showConfirmButton && (
        <Button
          type="primary"
          onClick={handleOnConfirmReceiveClick}
          disabled={buttonDisabled}
          className="br-pickups-tab__confirm-receiving-cta"
        >
          {confirmButtonText}
        </Button>
      )}
    </div>
  );
};
export default injectIntl(PickupsTabActions);
