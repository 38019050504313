import React, { useEffect, useState, useMemo } from 'react';
import { withRouter, Redirect } from 'react-router';
import { Link, Switch, Route } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Menu } from 'antd';
import Icon from '@ant-design/icons';
import classnames from 'classnames';

import { isUserAuthorized } from 'utils/helpers';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import Container from 'components/Container';
import BRHeader from 'components/BRHeader/BRHeader';

import './BRSideTabs.less';

const BRSideTabs = ({
  className,
  children,
  routes,
  match: { url },
  location: { pathname },
  showHeader,
  headerTitle = ''
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const screenWidth = window.matchMedia(`(min-width: 993px)`);

  useEffect(() => {
    setIsCollapsed(!screenWidth.matches);

    if (screenWidth) {
      screenWidth.addEventListener('change', () =>
        setIsCollapsed(!screenWidth.matches)
      );
    }
    setIsLoading(false);
  }, [screenWidth]);

  const defaultPath = useMemo(() => {
    // // in case of typing direct url in browser domain/parent-path/child-path
    // // check if /parent-path/child-path
    // // remove /parent-path
    // // result /child-path
    // const pathnameWithoutParent = pathname.replace(url, '');
    // if (pathnameWithoutParent) {
    //   // remove /
    //   // result child-path
    //   return pathnameWithoutParent.replace('/', '');
    // } else {
    //   for (let i = 0; i < routes.length; i++) {
    //     if (routes[i].isDefault) {
    //       return routes[i].path;
    //     }
    //   }
    //   return routes.length ? routes[0].path : '';
    // }

    let currentPath = null;
    let defaultPath = null;
    for (let i = 0; i < routes.length; i++) {
      if (pathname.includes(routes[i].path)) {
        currentPath = routes[i].path;
        break;
      } else if (routes[i].isDefault) {
        defaultPath = routes[i].path;
      }
    }

    return currentPath || defaultPath || (routes.length ? routes[0].path : '');
    // }, [url]);
  }, [pathname, routes]);

  return (
    <LoadingWrapper loading={isLoading}>
      <Container
        hideWarningBanner
        className={classnames('br-side-tabs-parent', {
          [className]: !!className
        })}
        header={showHeader ? <BRHeader title={headerTitle} /> : <div />}
        content={
          <div className="br-side-tabs">
            <div className="br-side-tabs__container">
              <Menu
                className={classnames('br-side-tabs__menu', {
                  'br-side-tabs__menu__not-collasped': !isCollapsed
                })}
                mode="inline"
                defaultSelectedKeys={[defaultPath]}
                inlineCollapsed={isCollapsed}
              >
                {routes.map(
                  (route) =>
                    (!route.access ||
                      isUserAuthorized(route.access, route.permission, {
                        countries: route.countries
                      })) && (
                      <Menu.Item
                        key={route.path}
                        {...(route.isDisabled && {
                          disabled: route.isDisabled(route.props)
                        })}
                        icon={
                          <Icon
                            component={route.icon}
                            className={route.iconClassName || ''}
                          />
                        }
                      >
                        <Link to={`${url}/${route.path}`}>{route.name}</Link>
                      </Menu.Item>
                    )
                )}
              </Menu>
            </div>
            <div className="br-side-tabs__content">
              {children}
              {!isLoading && (
                <Switch>
                  {routes.map((route) => (
                    <Route
                      key={route.path}
                      path={`${url}/${route.path}`}
                      exact={route.exact}
                      component={() => route.component(route.props)}
                    />
                  ))}

                  <Redirect from={url} to={`${url}/${defaultPath}`} />
                </Switch>
              )}
            </div>
          </div>
        }
      />
    </LoadingWrapper>
  );
};

export default injectIntl(withRouter(BRSideTabs));
