import dayjs from 'dayjs';

import { capitalizeFirstLetter } from 'utils/helpers';
import { COUNTRY_CURRENCY } from 'constants/helper';
import { getNationalIdViewLink } from 'services/stars';
import { STAR_NATIONAL_ID_ATTACHMENTS } from 'constants/stars';

import { fmt } from 'components/IntlWrapper/IntlWrapper';

export const NumberWithCommasSeperator = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const FormatStarLogEvent = ({
  actionType,
  actionName,
  actionData,
  actionsList,
  time,
  takenBy
}) => {
  if (actionType) {
    actionType = capitalizeFirstLetter(actionType?.toLowerCase());
  }
  let logData = {
    time: dayjs(time).tz().format('DD MMM YYYY  h:mm a'),
    takenBy: `${
      capitalizeFirstLetter(
        takenBy?.userRole?.replace(/_/g, ' ')?.toLowerCase()
      ) || ''
    }: ${takenBy?.userName || ''}`
  };
  switch (actionName) {
    case 'profile_phone':
      return {
        ...logData,
        event: `${actionType || ''} star phone number from ${
          actionData?.Before
        } to ${actionData?.After}`
      };
    case 'starInfo_currentVehicle':
      return {
        ...logData,
        event: `${actionType || ''} Vehicle type from ${
          actionData?.Before
        } to ${actionData?.After}`
      };

    case 'starInfo_isVerified':
      return {
        ...logData,
        event: `Star is  ${actionData?.After || ''}`
      };
    case 'starInfo_availabilityState':
      return {
        ...logData,
        event: `Star marked as  ${capitalizeFirstLetter(
          actionData?.After?.toLowerCase() || ''
        )}`
      };
    case 'excludedFromLiability':
      return {
        ...logData,
        event:
          actionData?.Before && actionData?.After
            ? `Star excluded from liability rule from ${dayjs(
                actionData?.Before
              )
                .tz()
                .format('DD-MM-YYYY')} to ${dayjs(actionData?.After)
                .tz()
                .format('DD-MM-YYYY')}`
            : `Star excluded from liability rule is reseted`
      };
    case 'starInfo_fleetType':
      return {
        ...logData,
        event: `Update star fleet type from ${actionData?.Before} to ${actionData?.After}`
      };
    case 'emails_0_address':
      return {
        ...logData,
        event: `Update star email address from ${actionData?.Before} to ${actionData?.After}`
      };
    case 'emails.0.verified':
      return {
        ...logData,
        event: `Star is ${actionData?.After ? 'activated' : 'deactivated'} ${
          actionsList?.deactivationReason
            ? ` - Reason: ${actionsList?.deactivationReason}`
            : ''
        }`
      };
    case 'profile_firstName':
      return {
        ...logData,
        event: `Update star first name from ${actionData?.Before} to ${actionData?.After}`
      };
    case 'profile_lastName':
      return {
        ...logData,
        event: `Update star last name from ${actionData?.Before} to ${actionData?.After}`
      };
    case 'starInfo_address_firstLine':
      return {
        ...logData,
        event: `Update street name from ${actionData?.Before} to ${actionData?.After}`
      };
    case 'starInfo_address_secondLine':
      return {
        ...logData,
        event: `Update address landmarks from ${actionData?.Before} to ${actionData?.After}`
      };
    case 'starInfo_address_floor':
      return {
        ...logData,
        event: `Update floor from ${actionData?.Before} to ${actionData?.After}`
      };
    case 'starInfo_address_apartment':
      return {
        ...logData,
        event: `Update apartment from ${actionData?.Before} to ${actionData?.After}`
      };
    case 'starInfo_address_buildingNumber':
      return {
        ...logData,
        event: `Update building no. from ${actionData?.Before} to ${actionData?.After}`
      };
    case 'starInfo_vehicles_year':
    case 'starInfo_vehicles_0_year':
      return {
        ...logData,
        event: `Update vehicle year from ${dayjs(actionData?.Before)
          .year()
          .toString()} to ${dayjs(actionData?.After).year().toString()}`
      };
    case 'starInfo_vehicles_0_make':
      return {
        ...logData,
        event: `Update vehicle make from ${actionData?.Before} to ${actionData?.After}`
      };
    case 'starInfo_vehicles_0_model':
      return {
        ...logData,
        event: `Update vehicle model from ${actionData?.Before} to ${actionData?.After}`
      };
    case 'starInfo_vehicles_0_licencePlate':
      return {
        ...logData,
        event: `Update vehicle licence from ${actionData?.Before} to ${actionData?.After}`
      };
    case 'starInfo_vehicles_0_color':
      return {
        ...logData,
        event: `Update vehicle color from ${actionData?.Before} to ${actionData?.After}`
      };
    case 'starInfo_fawryUserId':
      return {
        ...logData,
        event: `Update fawry ID from ${actionData?.Before} to ${actionData?.After}`
      };
    case 'starInfo_address_district_name':
      return {
        ...logData,
        event: `Update star address district name ${actionData?.Before} to ${actionData?.After}`
      };
    case 'starInfo_contractor_contractorName':
      return {
        ...logData,
        event: `Update contractor name to ${actionData?.After}`
      };
    case 'starInfo_salaryConfig_guaranteePerDay':
      return {
        ...logData,
        event: `Update salary guarantee per day to  ${fmt(
          {
            id: COUNTRY_CURRENCY
          },
          { cod: actionData?.After }
        )}`
      };
    case 'starInfo_salaryConfig_variable':
      return {
        ...logData,
        event: `Update salary variable to  ${fmt(
          {
            id: COUNTRY_CURRENCY
          },
          { cod: actionData?.After }
        )}`
      };
    case 'starInfo_salaryConfig_fixedPerDay':
      return {
        ...logData,
        event: `Update salary fixed per day to ${fmt(
          {
            id: COUNTRY_CURRENCY
          },
          { cod: actionData?.After }
        )}`
      };
    case 'RESET_SHORTAGE':
      return {
        ...logData,
        event: `Shortage rule is reseted`
      };
    case 'excludedAmount':
      return {
        ...logData,
        event: actionData.After
          ? `Excluded Amount changed from ${actionData?.Before || 0} to ${
              actionData?.After
            }`
          : `Excluded Amount rule is reseted to 0`
      };
    case 'excludedFrom':
    case 'excludedTo':
    case 'starInfo_fleetType_1':
    case 'starInfo_address_district__id':
    case 'starInfo_address_district_nameAr':
    case 'starInfo_contractor_contractorId':
      return null;
    default:
      return {
        ...logData,
        event: `${actionName} from ${actionData?.Before} to ${actionData?.After}`
      };
  }
};

export const renderStarNationalIdImages = (obj) => {
  const { BACK_NATIONAL_ID, FRONT_NATIONAL_ID } = obj || {};
  return (
    BACK_NATIONAL_ID &&
    FRONT_NATIONAL_ID && (
      <div className="br-form__display-national-id">
        {[BACK_NATIONAL_ID, FRONT_NATIONAL_ID].map((link, index) => (
          <a
            download={link + '.jpg'}
            href={link}
            target="_blank"
            title="ImageName"
          >
            <img src={link} alt={`national_Id_${index}`} />
          </a>
        ))}
      </div>
    )
  );
};
