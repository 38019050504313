export const COUNTRIES = [
  {
    id: '60e4482c7cb7d4bc4849c4d5',
    placeholder: '1234567890',
    codeNumber: '+20',
    codeName: 'EG',
    displayName: 'Egypt +20',
    name: 'Egypt'
  },
  {
    id: 'eF-3f9FZr',
    placeholder: '12345678',
    codeNumber: '+966',
    codeName: 'SA',
    displayName: 'Saudi Arabia +966',
    name: 'Saudi Arabia'
  },
  {
    id: '10e4482c7cb7d4bc4849c4d7',
    placeholder: '65123456',
    codeNumber: '+974',
    codeNumberArabic: '974+',
    codeName: 'QA',
    code: 'QA',
    displayName: 'Qatar +974',
    display: true,
    name: 'Qatar',
    arabicName: 'قطر'
  }
];

export const URL_FLAGS =
  'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/';
