import { createContext, useEffect, useState } from 'react';

import { getCountryTimeZone } from 'services/auth';

import { notify } from 'components/Notify/Notify';

export const ContextWrapper = createContext({});

const isAuthenticated = () => !!localStorage.getItem('token');

export const WrapperProvider = ({ children }) => {
  const [countryInfo, setCountryInfo] = useState({});

  useEffect(() => {
    if (isAuthenticated()) {
      getCountryInfo();
    }
  }, []);

  const getCountryInfo = async () => {
    try {
      const { data } = await getCountryTimeZone();
      setCountryInfo(data);
    } catch (error) {
      notify(error.message);
    }
  };
  return (
    <ContextWrapper.Provider value={{ countryInfo }}>
      {children}
    </ContextWrapper.Provider>
  );
};
