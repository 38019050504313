import React, { useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';
import { Button, Input, Form, Select, Checkbox, Divider, Tooltip } from 'antd';
import dayjs from 'dayjs';
import diff from 'diffObj';

import { openModal } from 'utils/modal';
import {
  attachmentsCountRule,
  emailRule,
  lengthRule,
  phoneNumberRule,
  urlRule,
  numberRule
} from 'utils/forms';
import { COUNTRIES } from 'constants/country-data';
import {
  cleanEmptyString,
  convertArrayToNestedObject,
  isUserAuthorized
} from 'utils/helpers';
import { formatImages } from 'utils/upload';
import { renderStarNationalIdImages } from 'utils/stars';
import { getAreasInfo, fetchCities } from 'services/cities';
import {
  getStarInformation,
  getAllHubs,
  getStarContractors,
  createStar,
  editStar,
  getAllHubZones,
  getSalaryConfigration,
  removeHubZone,
  getNationalIdUploadLink,
  getNationalIdViewLink
} from 'services/stars';
import { imageUpload } from 'services/upload';
import { PHONE_NUMBER_MAX_LENGHT } from 'constants/form';
import {
  FLEET_TYPES,
  VEHICLE_TYPES,
  SALARY_TYPES,
  STAR_NATIONAL_ID_ATTACHMENTS,
  ID_IMAGE_MAX_SIZE
} from 'constants/stars';
import { IS_SAUDI } from 'constants/helper';
import ACL_MATRIX from 'common/aclMatrix';
import {
  isValuOptionAvailable,
  getNationalIdLength,
  isNationalIdRequiredForStars,
  getCurrency,
  hasFawryId
} from 'common/countries/countries-mapping';
import {
  PHONE_NUMBER_PLACEHOLDER,
  STREET_NAME_PLACEHOLDER
} from 'common/countries/constants/forms';

import { notify } from 'components/Notify/Notify';
import BRHeader from 'components/BRHeader/BRHeader';
import Container from 'components/Container';
import BRFormInputNumber from 'components/BRFormInputNumber/BRFormInputNumber';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRArea from 'components/BRArea/BRArea';
import BRConfirmationModal from 'components/BRConfirmationModal/BRConfirmationModal';
import BRButton from 'components/BRButton/BRButton';
import CreateStarSalaryZoneModel from './components/CreateStarSalaryZoneModel/CreateStarSalaryZoneModel';
import UploadAttachment from 'components/SupportTickets/components/UploadAttachment/UploadAttachment';

import { ReactComponent as Plus } from 'assets/bosta-icons/Plus.svg';
import starSallary from 'assets/imgRevamp/starSallary.svg';

import './CreateEditStar.less';

function CreateStar({
  intl,
  history,
  match: {
    params: { id: starId }
  }
}) {
  const formRef = useRef(null);

  const handleOnChangeForm = (name, value) => {
    if (Array.isArray(name)) {
      const result = convertArrayToNestedObject(name, value);
      formRef.current.setFieldsValue(result);
    } else {
      formRef.current.setFieldsValue({ [name]: value });
    }
  };
  const [allHubs, setHubs] = useState([]);
  const [allCities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [isAreasLoading, setAreasLoading] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isZonesLoading, setIsZonesLoading] = useState(false);
  const [starInfo, setStarInfo] = useState('');
  const [allContractors, setContractors] = useState([]);
  const [hubZones, setHubZones] = useState([]);
  const [selectedFleetType, setSelectedFleetTypes] = useState([]);
  const [selectedSalaryType, setSelectedSalaryType] = useState([]);
  const [salaryConfigration, setSalaryConfigration] = useState(null);
  const [disableAddSalaryTypeButton, setDisableAddSalaryTypeButton] =
    useState(false);
  const [newStarSalaryZone, setNewStarSalaryZone] = useState(null);
  const [nationalImages, setNationalImages] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);

  const getHubs = async () => {
    try {
      setLoading(true);
      const { result } = await getAllHubs();
      setHubs(result);
      setLoading(false);
      return result;
    } catch (error) {
      notify(error.message);
    }
    setLoading(false);
  };

  const getAllCities = async () => {
    try {
      setLoading(true);
      const data = await fetchCities();
      setCities(data);
      setLoading(false);
      return data;
    } catch (error) {
      notify(error.message);
    }
    setLoading(false);
  };

  const handleOnCityChange = async (cityId) => {
    formRef && formRef.current.setFieldsValue({ districtId: null });

    if (cityId) {
      try {
        setAreasLoading(true);
        const areas = await getAreasInfo(cityId);
        setAreas(areas);
        setAreasLoading(false);
      } catch (error) {
        notify(error.message);
      }
      setAreasLoading(false);
    }
  };

  const checkSalaryInEdit = (salaryConfig) => {
    const newArray = [];
    Object.keys(salaryConfig).map((salary) => {
      SALARY_TYPES.forEach((type) => {
        if (type.salaryConfigKey === salary) {
          newArray.push(type);
        }
      });
    });
    return newArray;
  };

  const getStarNationalImages = async (starId) => {
    try {
      const { results } = await getNationalIdViewLink(starId, {
        attachments: STAR_NATIONAL_ID_ATTACHMENTS
      });
      setNationalImages(results);
    } catch (error) {
      setNationalImages(null);
    }
  };

  const getStarInfo = async () => {
    try {
      setLoading(true);
      const { message: starInfo } = await getStarInformation(starId);
      getStarNationalImages(starId);

      handleOnCityChange(starInfo.address?.city?._id);
      setSelectedFleetTypes(starInfo?.fleetType || []);
      getHubZones(starInfo.warehouseInfo._id);
      checkSalaryAmount({
        zoneName: starInfo?.salaryConfig?.salaryInfo?.zoneName,
        vehicleType: starInfo.currentVehicle
      });
      formRef.current.setFieldsValue({
        profile: {
          firstName: starInfo.firstName,
          lastName: starInfo.lastName,
          phone: starInfo.phone,
          national_number: starInfo?.nationalIdNumber?.toString()
        },
        fleetType: starInfo.fleetType,
        email: starInfo.email,
        city: starInfo?.cities?.[0]?._id,
        hub: starInfo?.hub?._id,
        warehouseInfo: starInfo.warehouseInfo?._id,
        fawryUserId: starInfo?.fawryUserId,
        contractor: starInfo.contractor?.contractorId,
        address: {
          ...starInfo.address,
          districtId:
            starInfo?.address?.districtId || starInfo?.address?.district?._id
        },
        addressCity: starInfo.address?.city?._id,
        currentVehicle: starInfo.currentVehicle,
        vehicle: {
          ...starInfo.vehicles?.[0],
          year: dayjs(starInfo.vehicles?.[0].year).year().toString()
        },
        zone: starInfo?.salaryConfig?.salaryInfo?.zoneName,
        posId: starInfo?.posId,
        valuLink: starInfo?.valuQRCodeLink
      });

      setSalaryConfigration(starInfo?.salaryConfig);
      if (starInfo?.salaryConfig) {
        setSelectedSalaryType(checkSalaryInEdit(starInfo?.salaryConfig));
      }
      setStarInfo(starInfo);
      setLoading(false);
    } catch (err) {
      notify(err.message);
    }
    setLoading(false);
  };

  const starContractors = async () => {
    try {
      const { message } = await getStarContractors();
      setContractors(message || []);
    } catch (err) {
      notify(err.message);
    }
  };

  const handleRemoveSalaryType = (salary) => {
    const filteredSalaryTypes = selectedSalaryType.filter(
      (type) => salary !== type.value
    );
    setSelectedSalaryType(filteredSalaryTypes);
    const currentValue = formRef.current.getFieldValue('salaryType');
    onSalaryTypeChange(currentValue, null, filteredSalaryTypes);
  };

  useEffect(() => {
    getHubs();
    getAllCities();
    starContractors();
    if (starId) {
      getStarInfo();
    }
  }, [starId]);

  const handleConfirmUpdateStar = async (modifiedStarsObject) => {
    try {
      setLoading(true);
      const payload = {
        starData: {
          ...cleanEmptyString(modifiedStarsObject),
          starInfo: {
            ...cleanEmptyString(modifiedStarsObject.starInfo),
            valuQRCodeLink: modifiedStarsObject.starInfo.valuQRCodeLink
          }
        }
      };
      const response = await editStar(starId, payload);
      if (response) {
        const images = formRef.current.getFieldValue([
          'profile',
          'national_id'
        ]);
        images?.attachments.length &&
          (await handleNationalIdImage(starId, images));
        history.push(`/stars/all/${starId}`);
        notify('Star updated successfully.', 'success');
      } else {
        notify(response.message);
      }
    } catch (err) {
      notify(err?.message || 'Error while editing star.');
    }
    setLoading(false);
  };

  const handleNationalIdImage = async (id, images) => {
    const formattedImages = formatImages(images);
    const payload = {
      attachments: STAR_NATIONAL_ID_ATTACHMENTS
    };
    try {
      setLoading(true);
      const { results } = await getNationalIdUploadLink(id, payload);

      Object.values(results).forEach(async (link, index) => {
        await imageUpload(link, formattedImages[index]);
      });
      notify(
        intl.formatMessage({ id: 'stars.create_edit_star.update_success' }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }

    setIsUpdate(false);
    setLoading(false);
  };

  const handleFormSubmit = async (values) => {
    const {
      address,
      vehicle,
      currentVehicle,
      fleetType,
      profile,
      zone,
      fawryUserId,
      posId,
      valuLink
    } = values;
    if (
      selectedSalaryType?.length === 1 &&
      selectedSalaryType[0].salaryConfigKey === SALARY_TYPES[2].salaryConfigKey
    ) {
      notify(
        '“Guaranteed“ CANNOT be added alone, Package Variable has to be added with it'
      );
      return;
    }
    const warehouseInfo = allHubs.find((el) => el._id === values.warehouseInfo);
    const Contractors = allContractors.find(
      (el) => el._id === values.contractor
    );
    const newSalaryObj = {};
    selectedSalaryType.map((type) => {
      newSalaryObj[type.salaryConfigKey] =
        salaryConfigration[type.salaryConfigKey];
    });
    const modifiedStarsObject = {
      emails: [
        {
          address: values.email,
          verified: starId ? starInfo.isVerified : false
        }
      ],
      profile,
      starInfo: {
        address,
        currentVehicle,
        fleetType,
        fawryUserId: !hasFawryId() ? profile.phone : fawryUserId,
        salaryConfig: {
          ...newSalaryObj,
          salaryInfo: {
            _id: newStarSalaryZone
              ? newStarSalaryZone?.zoneId
              : hubZones.find((el) => el.zoneName === zone)?._id,
            zoneName: newStarSalaryZone ? newStarSalaryZone?.similarZone : zone,
            newZoneName: newStarSalaryZone ? newStarSalaryZone?.newZone : null
          }
        },
        starLegalDocuments: {
          nationalIdNumber: profile.national_number?.toString()
        },
        contractor: {
          contractorId: Contractors?._id,
          quickBooksCustomerId: Contractors?.quickBooksCustomerId,
          contractorName: Contractors?.companyName
        },
        vehicles: [vehicle],
        ...(IS_SAUDI ? { posId } : {}),
        valuQRCodeLink: valuLink
      },
      warehouseInfo: {
        _id: warehouseInfo?._id,
        name: warehouseInfo?.name,
        address: warehouseInfo?.address
      }
    };
    if (starId) {
      let salaryData = {};
      if (starInfo?.salaryConfig) {
        const { salaryInfo, ...restOfsalaryData } = starInfo?.salaryConfig;
        salaryData = restOfsalaryData;
      }
      const oldData = {
        'current vehicle': starInfo?.currentVehicle,
        zone: starInfo?.salaryConfig?.salaryInfo?.zoneName
      };
      const newData = {
        'current vehicle': currentVehicle,
        zone
      };

      const differenceForStarData = diff(oldData, newData);
      const differenceForStarSalary = Object.values(
        diff(newSalaryObj, salaryData)
      )?.length
        ? { 'salary configuration': '' }
        : {};
      const comparingDataResult = {
        ...differenceForStarData,
        ...differenceForStarSalary
      };
      if (Object.values(comparingDataResult)?.length) {
        openModal(BRConfirmationModal, {
          title: 'Updating Star Info',
          message: intl.formatMessage(
            {
              id: 'stars.create_edit_star.update_star_warning_message'
            },
            {
              updatedParams: Object.keys(comparingDataResult).join(', ')
            }
          ),
          onConfirm: () => {
            handleConfirmUpdateStar(modifiedStarsObject);
          }
        });
      } else {
        handleConfirmUpdateStar(modifiedStarsObject);
      }
    } else {
      try {
        const { message, result } = await createStar(
          cleanEmptyString(modifiedStarsObject)
        );

        if (result) {
          notify(message, 'success');
          const images = profile.national_id;
          images?.attachments.length &&
            (await handleNationalIdImage(result._id, images));
          history.push(`/stars/all/${result._id}`);
        }
      } catch (err) {
        if (Array.isArray(err?.data)) {
          err.response.data.map((e) => notify(e.message));
        } else {
          notify(err?.message);
        }
      }
    }
  };

  const handleChangeFleetType = (value) => {
    setSelectedFleetTypes(value);
    if (!value.includes(FLEET_TYPES[2].value)) {
      setSelectedSalaryType([]);
    }
  };

  const getHubZones = async (value) => {
    formRef && formRef.current.setFieldsValue({ zone: null });
    if (!value) {
      return;
    }
    try {
      setIsZonesLoading(true);
      const { data } = await getAllHubZones(value);
      setHubZones(data);
    } catch (error) {
      notify(error.message);
    }
    setIsZonesLoading(false);
  };

  const fleetTypeChildren = () => {
    const children = [];
    FLEET_TYPES.map(({ value, label }) => {
      children.push(
        <Select.Option key={value}>
          <Checkbox checked={selectedFleetType?.indexOf(value) >= 0} />
          {label}
        </Select.Option>
      );
    });
    return children;
  };

  const handleAddNewSalaryType = async () => {
    await formRef.current.validateFields(['salaryType']);
    const salary = formRef.current.getFieldValue('salaryType');
    if (!salary) {
      return;
    }
    const checkIsValueAdded = selectedSalaryType.find(
      (type) => salary === type.value
    );
    if (!checkIsValueAdded) {
      const getAllSalaryInfo = SALARY_TYPES.find(
        (type) => salary === type.value
      );
      await setSelectedSalaryType([...selectedSalaryType, getAllSalaryInfo]);
      setDisableAddSalaryTypeButton(true);
    }
  };

  const checkSalaryAmount = async ({ zoneName, vehicleType }) => {
    if (!zoneName || !vehicleType) {
      return;
    }
    try {
      zoneName = zoneName.split('=')[0].trim();
      const {
        data: { vehicle }
      } = await getSalaryConfigration({
        zoneName,
        vehicleType
      });
      setSalaryConfigration(vehicle[0]);
    } catch (error) {
      notify(error.message);
    }
  };

  const checkChangedValues = (changedValues, allValues) => {
    const { zone, currentVehicle } = allValues;
    const { zone: changedZone, currentVehicle: changedCurrentVehicle } =
      changedValues;

    if (changedCurrentVehicle && zone) {
      checkSalaryAmount({ zoneName: zone, vehicleType: changedCurrentVehicle });
    } else if (changedZone && currentVehicle) {
      checkSalaryAmount({ zoneName: changedZone, vehicleType: currentVehicle });
    }
  };

  const checkIfOptionShouldBeDisabled = (value) => {
    // first  disable condition: - If value = "fixed per day" and it is selected => "Gurantee Option should be disabled"

    const firstDisablingCondition =
      value === SALARY_TYPES[2].value &&
      selectedSalaryType.find((type) => SALARY_TYPES[1].value === type.value);

    // second  disable condition: - If value = "Gurantee per salary" and it is selected => "Fixed per day Option should be disabled"
    const secondDisablingCondition =
      value === SALARY_TYPES[1].value &&
      selectedSalaryType.find((type) => SALARY_TYPES[2].value === type.value);

    return firstDisablingCondition || secondDisablingCondition;
  };

  const onSalaryTypeChange = (value, _, selectedSalaryArray) => {
    const selectedArray = selectedSalaryArray || selectedSalaryType;
    const isSelectedTypeFound = !!selectedArray.find(
      (type) => type.value === value
    );
    setDisableAddSalaryTypeButton(isSelectedTypeFound);
  };
  const handleOpenCreateStarSalaryZoneModel = async () => {
    openModal(CreateStarSalaryZoneModel, {
      allHubs,
      onSuccess: async (values) => {
        setNewStarSalaryZone(values);
        formRef &&
          formRef.current.setFieldsValue({
            zone: `${values.similarZone}  =  ${values.newZone}`
          });
        checkSalaryAmount({
          zoneName: values.similarZone,
          vehicleType: formRef.current.getFieldValue('currentVehicle')
        });
      }
    });
  };

  const handleRemoveZone = async (zoneId) => {
    setLoading(true);
    try {
      await removeHubZone(zoneId);
      const updatedZones = hubZones.filter((item) => item._id !== zoneId);
      formRef.current.setFieldsValue({
        zone: null
      });
      setHubZones(updatedZones);
    } catch (error) {
      notify(error.message);
    }
    setLoading(false);
  };

  const toggleUpdateState = () => {
    setIsUpdate((prev) => !prev);
  };

  return (
    <LoadingWrapper loading={isLoading}>
      <Container
        header={
          <BRHeader
            title={intl.formatMessage({
              id: 'stars.create_edit_star.titles.stars'
            })}
            showBackArrow
          />
        }
        className="br-create-edit-star"
        content={
          <>
            <p className="br-create-edit-star__title">
              {intl.formatMessage({
                id: 'stars.create_edit_star.titles.basic_info'
              })}
            </p>
            <Form
              onFinish={handleFormSubmit}
              onValuesChange={(changedValues, allValues) =>
                checkChangedValues(changedValues, allValues)
              }
              ref={formRef}
              scrollToFirstError
            >
              <div className="br-form-row">
                <Form.Item
                  name={['profile', 'firstName']}
                  rules={[
                    {
                      required: true,
                      label: intl.formatMessage({
                        id: 'contact_form_labels.first_name'
                      })
                    }
                  ]}
                  label={intl.formatMessage({
                    id: 'contact_form_labels.first_name'
                  })}
                >
                  <Input
                    placeholder={intl.formatMessage({
                      id: 'stars.create_edit_star.form_placeholders.star_first_name'
                    })}
                  />
                </Form.Item>
                <Form.Item
                  name={['profile', 'lastName']}
                  label={intl.formatMessage({
                    id: 'contact_form_labels.last_name'
                  })}
                  rules={[
                    {
                      required: true,

                      label: intl.formatMessage({
                        id: 'contact_form_labels.last_name'
                      })
                    }
                  ]}
                >
                  <Input
                    placeholder={intl.formatMessage({
                      id: 'stars.create_edit_star.form_placeholders.star_last_name'
                    })}
                  />
                </Form.Item>
              </div>
              <div className="br-form-row">
                <Form.Item
                  name={['profile', 'phone']}
                  label={intl.formatMessage({
                    id: 'contact_form_labels.phone'
                  })}
                  validateTrigger="onBlur"
                  rules={[
                    {
                      label: intl.formatMessage({
                        id: 'contact_form_labels.phone'
                      }),
                      required: true,
                      maxLen: PHONE_NUMBER_MAX_LENGHT,
                      message: intl.formatMessage({
                        id: 'form.phone_not_valid'
                      })
                    },
                    phoneNumberRule({
                      message: intl.formatMessage({
                        id: 'form.phone_not_valid'
                      }),
                      internationlNumbers: true
                    })
                  ]}
                >
                  <Input
                    placeholder={PHONE_NUMBER_PLACEHOLDER}
                    label={intl.formatMessage({
                      id: 'contact_form_labels.phone'
                    })}
                    onChange={(e) =>
                      handleOnChangeForm('fawryUserId', e.target.value)
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  label={intl.formatMessage({
                    id: 'contact_form_labels.email'
                  })}
                  rules={[
                    { required: true },
                    emailRule(
                      intl.formatMessage({
                        id: 'form.email_not_valid'
                      })
                    )
                  ]}
                >
                  <Input
                    placeholder={intl.formatMessage({
                      id: 'stars.create_edit_star.form_placeholders.star_email'
                    })}
                    autoComplete={`${Math.random()}`}
                  />
                </Form.Item>
              </div>

              {isNationalIdRequiredForStars() && (
                <div
                  className={classnames('br-form-row', {
                    'br-form__national-id': starId
                  })}
                >
                  <Form.Item
                    name={['profile', 'national_number']}
                    label={intl.formatMessage({
                      id: 'stars.create_edit_star.form_labels.national_number'
                    })}
                    rules={[
                      lengthRule({
                        length: getNationalIdLength(),
                        message: intl.formatMessage(
                          {
                            id: 'stars.create_edit_star.form_errors.national_length'
                          },
                          { length: getNationalIdLength() }
                        )
                      }),
                      numberRule(
                        intl.formatMessage(
                          {
                            id: 'stars.create_edit_star.form_errors.national_number'
                          },
                          { length: getNationalIdLength() }
                        )
                      )
                    ]}
                  >
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'stars.create_edit_star.form_placeholders.national_number_placeholder'
                      })}
                    />
                  </Form.Item>
                  <div>
                    {starId && !isUpdate ? (
                      renderStarNationalIdImages(nationalImages)
                    ) : (
                      <>
                        <Form.Item
                          name={['profile', 'national_id']}
                          label={intl.formatMessage({
                            id: 'stars.create_edit_star.form_labels.national_id'
                          })}
                          rules={[
                            { required: true },
                            attachmentsCountRule({
                              count: 2,
                              message: intl.formatMessage({
                                id: 'stars.create_edit_star.form_errors.national_id'
                              })
                            })
                          ]}
                        >
                          <UploadAttachment
                            maxCount={2}
                            accept=".jpg, .jpeg, .png"
                            disableEncoding
                            maxSize={ID_IMAGE_MAX_SIZE}
                            hideUploadButtonAfterMaxCount
                          />
                        </Form.Item>
                      </>
                    )}
                    {starId && (
                      <BRButton
                        label={intl.formatMessage({
                          id: isUpdate
                            ? 'common.cancel'
                            : 'stars.create_edit_star.form_labels.update_national_id'
                        })}
                        onClick={toggleUpdateState}
                      />
                    )}
                  </div>
                </div>
              )}

              <div className="br-form-row">
                {hasFawryId() && (
                  <Form.Item
                    label={intl.formatMessage({
                      id: `stars.create_edit_star.form_labels.fawry_id`
                    })}
                    name="fawryUserId"
                  >
                    <Input
                      placeholder={intl.formatMessage({
                        id: `stars.create_edit_star.form_placeholders.star_fawry_id`
                      })}
                      autoComplete={`${Math.random()}`}
                      disabled
                    />
                  </Form.Item>
                )}

                <Form.Item
                  label="Contractor"
                  name="contractor"
                  rules={[
                    {
                      required: true
                    }
                  ]}
                >
                  <Select
                    placeholder={intl.formatMessage({
                      id: `form.select_placeholder`
                    })}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    autoComplete={`${Math.random()}`}
                    allowClear
                  >
                    {allContractors.map((contractor) => (
                      <Select.Option
                        key={contractor._id}
                        value={contractor._id}
                      >
                        {contractor.companyName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="br-form-row">
                <Form.Item
                  name="warehouseInfo"
                  label={intl.formatMessage({
                    id: 'stars.create_edit_star.form_labels.hub'
                  })}
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder={intl.formatMessage({
                      id: `form.select_placeholder`
                    })}
                    showSearch
                    loading={isAreasLoading}
                    autoComplete={`${Math.random()}`}
                    allowClear
                    onChange={getHubZones}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {allHubs &&
                      allHubs.map((hub) => (
                        <Select.Option key={hub._id} value={hub._id}>
                          {hub.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="fleetType"
                  label={intl.formatMessage({
                    id: 'stars.create_edit_star.form_labels.fleet_type'
                  })}
                  rules={[{ required: true }]}
                >
                  <Select
                    showArrow
                    mode="multiple"
                    placeholder={intl.formatMessage({
                      id: `form.select_placeholder`
                    })}
                    onChange={handleChangeFleetType}
                    dropdownClassName="br-create-edit-star__fleet-type-form-item"
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                    filterOption={(input, option) =>
                      option?.children[1]
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {fleetTypeChildren()}
                  </Select>
                </Form.Item>
              </div>
              <div className="br-form-row">
                <Form.Item
                  className="br-form-row__half-width"
                  name="zone"
                  label={intl.formatMessage({
                    id: 'location_form_labels.zone'
                  })}
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder={intl.formatMessage({
                      id: `form.select_placeholder`
                    })}
                    showSearch
                    filterOption={(input, option) =>
                      option.value.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    autoComplete={`${Math.random()}`}
                    loading={isZonesLoading}
                    allowClear
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <div className="br-form-row">
                          <BRButton
                            label={intl.formatMessage({
                              id: `stars.create_edit_star.create_star_salary_zone_modal.add_new_zone`
                            })}
                            prefixIcon={<Plus />}
                            type="link-color"
                            className="button-md br-add-button"
                            onClick={handleOpenCreateStarSalaryZoneModel}
                          />
                        </div>
                      </>
                    )}
                    dropdownClassName="br-order-types__return-location__dropdown"
                    getPopupContainer={(trigger) =>
                      trigger.parentElement.parentElement.parentElement
                        .parentElement.parentElement.parentElement.parentElement
                        .parentElement
                    }
                    optionLabelProp="label"
                  >
                    {hubZones?.map((zone) => (
                      <Select.Option key={zone._id} value={zone.zoneName}>
                        <div className="br-star__zones-option">
                          <span>{zone.zoneName}</span>
                          <BRButton
                            onClick={() => {
                              handleRemoveZone(zone._id);
                            }}
                            label={intl.formatMessage({ id: 'common.delete' })}
                            loading={isLoading}
                          />
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                {IS_SAUDI && isUserAuthorized(ACL_MATRIX.FINANCE_TEAM) && (
                  <Form.Item
                    name="posId"
                    label={intl.formatMessage({
                      id: 'stars.create_edit_star.form_labels.pos_id'
                    })}
                    rules={[{ required: false }]}
                  >
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'stars.create_edit_star.form_placeholders.pos_id'
                      })}
                      autoComplete={`${Math.random()}`}
                    />
                  </Form.Item>
                )}
                {isValuOptionAvailable() && (
                  <Form.Item
                    name="valuLink"
                    label={intl.formatMessage({
                      id: 'stars.create_edit_star.form_labels.valu_payment_link'
                    })}
                    rules={[
                      { required: false },
                      urlRule(
                        intl.formatMessage({
                          id: 'form.url_not_valid'
                        })
                      )
                    ]}
                  >
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'stars.create_edit_star.form_placeholders.valu_payment_link'
                      })}
                      autoComplete={`${Math.random()}`}
                    />
                  </Form.Item>
                )}
              </div>
              <Divider />
              <p className="br-create-edit-star__title">
                {intl.formatMessage({
                  id: 'stars.create_edit_star.titles.vechile_info'
                })}
              </p>
              <div className="br-form-row">
                <Form.Item
                  label={intl.formatMessage({
                    id: 'stars.create_edit_star.form_labels.vehicle_type'
                  })}
                  name="currentVehicle"
                  rules={[
                    {
                      required: true
                    }
                  ]}
                >
                  <Select
                    placeholder={intl.formatMessage({
                      id: 'stars.create_edit_star.form_placeholders.star_vehicle_type'
                    })}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    autoComplete={`${Math.random()}`}
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                    allowClear
                  >
                    {VEHICLE_TYPES?.map(({ label, value }) => (
                      <Select.Option key={value} value={value}>
                        {label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name={['vehicle', 'make']}
                  label={
                    <>
                      {intl.formatMessage({
                        id: 'stars.create_edit_star.form_labels.make'
                      })}
                      <span className="br-form-optional-label">
                        {intl.formatMessage({
                          id: 'form.optional_label'
                        })}
                      </span>
                    </>
                  }
                  rules={[{ required: false }]}
                >
                  <Input
                    autoComplete={`${Math.random()}`}
                    placeholder={intl.formatMessage({
                      id: 'stars.create_edit_star.form_placeholders.vehicle_make'
                    })}
                  />
                </Form.Item>
              </div>
              <div className="br-form-row">
                <Form.Item
                  name={['vehicle', 'model']}
                  label={
                    <>
                      {intl.formatMessage({
                        id: 'stars.create_edit_star.form_labels.model'
                      })}
                      <span className="br-form-optional-label">
                        {intl.formatMessage({
                          id: 'form.optional_label'
                        })}
                      </span>
                    </>
                  }
                  rules={[{ required: false }]}
                >
                  <Input
                    autoComplete={`${Math.random()}`}
                    placeholder={intl.formatMessage({
                      id: 'stars.create_edit_star.form_placeholders.vehicle_model'
                    })}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <>
                      {intl.formatMessage({
                        id: 'stars.create_edit_star.form_labels.license'
                      })}
                      <span className="br-form-optional-label">
                        {intl.formatMessage({
                          id: 'form.optional_label'
                        })}
                      </span>
                    </>
                  }
                  name={['vehicle', 'licencePlate']}
                  rules={[
                    {
                      required: false
                    }
                  ]}
                >
                  <Input
                    placeholder={intl.formatMessage({
                      id: 'stars.create_edit_star.form_placeholders.vehicle_license'
                    })}
                    autoComplete={`${Math.random()}`}
                  />
                </Form.Item>
              </div>
              <div className="br-form-row">
                <Form.Item
                  name={['vehicle', 'year']}
                  label={
                    <>
                      {intl.formatMessage({
                        id: 'stars.create_edit_star.form_labels.year'
                      })}
                      <span className="br-form-optional-label">
                        {intl.formatMessage({
                          id: 'form.optional_label'
                        })}
                      </span>
                    </>
                  }
                  rules={[{ required: false }, { min: 4 }, { max: 4 }]}
                >
                  <Input
                    autoComplete={`${Math.random()}`}
                    placeholder={intl.formatMessage({
                      id: 'stars.create_edit_star.form_placeholders.vehicle_year'
                    })}
                  />
                </Form.Item>
                <Form.Item
                  name={['vehicle', 'color']}
                  label={
                    <>
                      {intl.formatMessage({
                        id: 'stars.create_edit_star.form_labels.color'
                      })}
                      <span className="br-form-optional-label">
                        {intl.formatMessage({
                          id: 'form.optional_label'
                        })}
                      </span>
                    </>
                  }
                  rules={[{ required: false }]}
                >
                  <Input
                    autoComplete={`${Math.random()}`}
                    placeholder={intl.formatMessage({
                      id: 'stars.create_edit_star.form_placeholders.vehicle_color'
                    })}
                  />
                </Form.Item>
              </div>
              <Divider />
              {selectedFleetType.includes(FLEET_TYPES[2].value) && (
                <>
                  <p className="br-create-edit-star__title  ">
                    {intl.formatMessage({
                      id: 'stars.create_edit_star.titles.salary'
                    })}
                  </p>
                  <div className="br-created-edit-star__salary-confirgarion">
                    <div className="br-create-edit-star__salary-form display-flex align-center">
                      <Form.Item
                        className="br-form-row__half-width"
                        label={intl.formatMessage({
                          id: 'stars.create_edit_star.form_labels.salary_type'
                        })}
                        name="salaryType"
                        rules={[
                          {
                            required: !selectedSalaryType?.length
                          }
                        ]}
                      >
                        <Select
                          onChange={onSalaryTypeChange}
                          placeholder={intl.formatMessage({
                            id: `form.select_placeholder`
                          })}
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentElement
                          }
                          autoComplete={`${Math.random()}`}
                          allowClear
                        >
                          {SALARY_TYPES?.map(({ label, sublabel, value }) => (
                            <Select.Option
                              disabled={checkIfOptionShouldBeDisabled(value)}
                              key={label}
                              value={value}
                            >
                              {label}
                              <div
                                className={classnames(
                                  'br-create-edit-star__salary-sublabel',
                                  {
                                    'br-create-edit-star__salary-disabled-sublabel':
                                      checkIfOptionShouldBeDisabled(value)
                                  }
                                )}
                              >
                                {sublabel}
                              </div>
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Tooltip
                        title={
                          selectedSalaryType?.length === 2 &&
                          'You can’t add more than two salary options'
                        }
                        placement="topLeft"
                        overlayClassName="br-create-edit-star__tooltip"
                        getPopupContainer={(trigger) => trigger.parentElement}
                      >
                        <Button
                          className="br-create-edit-star__salary-add-action"
                          onClick={handleAddNewSalaryType}
                          disabled={
                            selectedSalaryType?.length === 2 ||
                            disableAddSalaryTypeButton
                          }
                          type="primary"
                        >
                          Add
                        </Button>
                      </Tooltip>
                    </div>
                    <div className="br-create-edit-star__salary-section">
                      {selectedSalaryType.length ? (
                        selectedSalaryType.map((type) => (
                          <div
                            key={type.value}
                            className="display-flex align-center justify-content-between"
                          >
                            <span className="br-create-edit-star__salary-section__right-content">
                              <span className="br-create-edit-star__salary-section__title">
                                {type.value}
                              </span>
                              <span className="br-create-edit-star__salary-section__subtitle">
                                {salaryConfigration ? (
                                  <>
                                    {intl.formatMessage(
                                      {
                                        id: `stars.create_edit_star.salary_type.${type.helpSentenceFor}.help_sentense`
                                      },
                                      {
                                        currency: getCurrency().localized,
                                        amount:
                                          salaryConfigration[
                                            type.salaryConfigKey
                                          ],
                                        vehicleType: (
                                          <span className="bold">
                                            {salaryConfigration.vehicleType}
                                          </span>
                                        ),
                                        zone: (
                                          <span className="bold">
                                            {formRef.current.getFieldValue(
                                              'zone'
                                            )}
                                          </span>
                                        )
                                      }
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {intl.formatMessage({
                                      id: `stars.create_edit_star.salary_type.${type.helpSentenceFor}.title`
                                    })}
                                    {intl.formatMessage({
                                      id: `stars.create_edit_star.salary_type.default_help_sentense`
                                    })}
                                  </>
                                )}
                              </span>
                            </span>
                            <Button
                              type="danger"
                              onClick={() => handleRemoveSalaryType(type.value)}
                            >
                              Remove
                            </Button>
                          </div>
                        ))
                      ) : (
                        <div className="br-create-edit-star__empty-salary-section">
                          <img alt="Star Wallet" src={starSallary} />
                          <span className="br-create-edit-star__salary-section__hint">
                            Select how this star is going to be compensated
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <Divider />
                </>
              )}
              <p className="br-create-edit-star__title  ">
                {intl.formatMessage({
                  id: 'stars.create_edit_star.titles.address'
                })}
              </p>
              <div className="br-form-row br-customer-details__city-area-row">
                <Form.Item
                  name="addressCity"
                  label={intl.formatMessage({
                    id: 'location_form_labels.city'
                  })}
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder={intl.formatMessage({
                      id: `form.select_placeholder`
                    })}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={handleOnCityChange}
                    autoComplete={`${Math.random()}`}
                    allowClear
                  >
                    {allCities.map((city) => (
                      <Select.Option key={city._id} value={city._id}>
                        {city.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <BRArea
                  name={['address', 'districtId']}
                  areas={areas}
                  loading={isAreasLoading}
                  disabled={!formRef.current?.getFieldValue('addressCity')}
                />
              </div>
              <div className="br-form-row">
                <Form.Item
                  name={['address', 'firstLine']}
                  label={intl.formatMessage({
                    id: 'location_form_labels.street'
                  })}
                  rules={[
                    {
                      required: true,
                      max: 200,
                      label: intl.formatMessage({
                        id: 'location_form_labels.street'
                      })
                    }
                  ]}
                >
                  <Input
                    autoComplete={`${Math.random()}`}
                    placeholder={STREET_NAME_PLACEHOLDER}
                  />
                </Form.Item>
                <Form.Item
                  name={['address', 'secondLine']}
                  label={
                    <>
                      {intl.formatMessage({
                        id: 'location_form_labels.landmarks'
                      })}
                      <span className="br-form-optional-label">
                        {intl.formatMessage({
                          id: 'form.optional_label'
                        })}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      max: 200,
                      label: intl.formatMessage({
                        id: 'location_form_labels.landmarks'
                      })
                    }
                  ]}
                >
                  <Input
                    autoComplete={`${Math.random()}`}
                    placeholder={intl.formatMessage({
                      id: 'shipments.new_order.form_placeholder.landmarks_placeholder'
                    })}
                  />
                </Form.Item>
              </div>
              <div className="br-form-row">
                <Form.Item
                  name={['address', 'buildingNumber']}
                  label={
                    <>
                      {intl.formatMessage({
                        id: 'location_form_labels.building'
                      })}
                      <span className="br-form-optional-label">
                        {intl.formatMessage({
                          id: 'form.optional_label'
                        })}
                      </span>
                    </>
                  }
                  rules={[{ max: 4 }]}
                >
                  <Input
                    autoComplete={`${Math.random()}`}
                    placeholder={intl.formatMessage({
                      id: 'shipments.new_order.form_placeholder.building_placeholder'
                    })}
                    maxLength={4}
                  />
                </Form.Item>
                <Form.Item
                  name={['address', 'floor']}
                  label={
                    <>
                      {intl.formatMessage({
                        id: 'location_form_labels.floor'
                      })}
                      <span className="br-form-optional-label">
                        {intl.formatMessage({
                          id: 'form.optional_label'
                        })}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      max: 4,
                      label: intl.formatMessage({
                        id: 'location_form_labels.floor'
                      })
                    }
                  ]}
                >
                  <BRFormInputNumber
                    maxLength={4}
                    placeholder={intl.formatMessage({
                      id: 'shipments.new_order.form_placeholder.floor_placeholder'
                    })}
                    filedName={['address', 'floor']}
                    isWithCounter={false}
                    autoComplete={`${Math.random()}`}
                    handleOnChangeForm={handleOnChangeForm}
                  />
                </Form.Item>
                <Form.Item
                  name={['address', 'apartment']}
                  label={
                    <>
                      {intl.formatMessage({
                        id: 'location_form_labels.apartment'
                      })}
                      <span className="br-form-optional-label">
                        {intl.formatMessage({
                          id: 'form.optional_label'
                        })}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      max: 4,
                      label: intl.formatMessage({
                        id: 'location_form_labels.apartment'
                      })
                    }
                  ]}
                >
                  <BRFormInputNumber
                    maxLength={4}
                    placeholder={intl.formatMessage({
                      id: 'shipments.new_order.form_placeholder.apartment_placeholder'
                    })}
                    filedName={['address', 'apartment']}
                    isWithCounter={false}
                    autoComplete={`${Math.random()}`}
                    handleOnChangeForm={handleOnChangeForm}
                  />
                </Form.Item>
              </div>
              <Button
                type="danger"
                htmlType="submit"
                className="br-create-edit-star__footer"
              >
                {intl.formatMessage({
                  id: `stars.create_edit_star.${
                    starId ? 'update_star_account' : 'create_star_account'
                  }`
                })}
              </Button>
            </Form>
          </>
        }
      />
    </LoadingWrapper>
  );
}

export default injectIntl(withRouter(CreateStar));
