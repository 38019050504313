const emailValidator = (value, message) => {
  const emailExp = /^\S+@\S+\.\S+$/;

  if (!value || emailExp.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const emailRule = (message) => ({
  validator: (_, value) => emailValidator(value, message)
});

export const requiredRule = (message) => ({
  required: true,
  message
});

export const notRequiredRule = () => ({
  required: false
});

const numberValidator = (value, message) => {
  const numberRegExp = /^\d+$/;
  if (!value || numberRegExp.test(value.price) || numberRegExp.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const numberRule = (message) => ({
  validator: (_, value) => numberValidator(value, message)
});

const conatinUrl = (value, message) => {
  const name =
    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
  if (!value || !name.test(value)) {
    return Promise.reject(message);
  }
  return Promise.resolve();
};

export const conatinUrlRule = (message) => ({
  validator: (_, value) => conatinUrl(value, message)
});

const postiveAmountValidator = (value, message) => {
  const number = parseFloat(value);
  if (!value || number > 0) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const postiveCompensationAmountRule = (message) => ({
  validator: (_, value) => postiveAmountValidator(value, message)
});
