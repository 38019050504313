const PERMISSIONS_RESOURCES = {
  SYSTEM: 'SYSTEM',
  ADMIN_GROUPS: 'ADMIN_GROUPS',
  BUSINESS: 'BUSINESS',
  BUSINESS_API_KEY: 'BUSINESS_API_KEY',
  DELIVERY: 'DELIVERY',
  HUBS: 'HUBS',
  HUBS_SCORES: 'HUBS_SCORES',
  HUBS_TRANSFER: 'HUBS_TRANSFER',
  STARS: 'STARS',
  STAR_ACTIONS: 'STAR_ACTIONS',
  ROUTES: 'ROUTES',
  PICKUP_REQUESTS: 'PICKUP_REQUESTS',
  PROMOTIONS: 'PROMOTIONS',
  PRICING_TIERS: 'PRICING_TIERS',
  BUSINESS_PICKUP_LOCATIONS: 'BUSINESS_PICKUP_LOCATIONS',
  ACTION_CENTER: 'ACTION_CENTER',
  CASH_MONEY: 'CASH_MONEY',
  SEALS: 'SEALS',
  NOTIFICATION: 'NOTIFICATION',
  WALLET_TRANSACTIONS: 'WALLET_TRANSACTIONS',
  WALLET_CASH_CYCLES: 'WALLET_CASH_CYCLES',
  WALLET_ADJUSTMENTS: 'WALLET_ADJUSTMENTS',
  COMPENSATION: 'COMPENSATION',
  FUNDING_PROFILES: 'FUNDING_PROFILES',
  FINANCE_REPORTS: 'FINANCE_REPORTS',
  TICKETS: 'TICKETS',
  ZONES: 'ZONES',
  COUNTRY: 'COUNTRY',
  BOSTA_SHOP: 'BOSTA_SHOP',
  BOX_COUNTRY: 'BOX_COUNTRY',
  VENDORS: 'VENDORS',
  CASHIER: 'CASHIER',
  BOX_ITEM: 'BOX_ITEM',
  FULFILLMENT_PRODUCTS: 'FULFILLMENT_PRODUCTS',
  THREE_PL_PROVIDERS: 'THREE_PL_PROVIDERS',
  BARCODES: 'BARCODES',
  SLA_HOLIDAYS: 'SLA_HOLIDAYS',
  STREET_CODES: 'STREET_CODES',
  FULFILLMENT_PRICING_TIERS: 'PRICING_FULFILLMENT_TIERS',
  FLYERS: 'FLYERS',
  USERS: 'USERS',
  INTERNATIONAL_ORDER: 'INTERNATIONAL_ORDER',
  PRICING_TRANSIT_COSTS: 'PRICING_TRANSIT_COSTS',
  PRICING_SERVICE_TYPES: 'PRICING_SERVICE_TYPES',
  PRICING_SIZES: 'PRICING_SIZES',
  RECEIVING_MANIFEST: 'RECEIVING_MANIFEST',
  BOSTA_FULFILLMENT: 'BOSTA_FULFILLMENT',
  PRICING_INTERNATIONAL_TIERS: 'PRICING_INTERNATIONAL_TIERS',
  CAPITAL_CONFIGURATIONS: 'CAPITAL_CONFIGURATIONS',
  DELIVERY_SCANS_LOGS: 'DELIVERY_SCANS_LOGS',
  TICKETS_ESCALATION: 'ESCALATE',
  LIST_ESCALATED_TICKETS: 'LIST_ESCALATED_TICKETS',
  EXPORT_ESCALATED_TICKETS: 'EXPORT_ESCALATED_TICKETS',
  TRANSACTIONS: 'TRANSACTIONS',
  TRANSACTIONS_CARDS: 'TRANSACTIONS_CARDS',
  TRANSACTIONS_RECEIPT: 'TRANSACTIONS_RECEIPT',
  CASH_CYCLES_CARDS: 'CASH_CYCLES_CARDS',
  CASH_CYCLES: 'CASH_CYCLES',
  SUBSCRIPTIONS: 'SUBSCRIPTIONS'
};

const SPECIAL_PERMISSIONS = {
  FORCE_ASSIGN_TO_HUB: 'FORCE_ASSIGN_TO_HUB',
  ESCALATE: 'ESCALATE',
  LIST_ESCALATED_TICKETS: 'LIST_ESCALATED_TICKETS',
  EXPORT_ESCALATED_TICKETS: 'EXPORT_ESCALATED_TICKETS',
  UPDATE_ESCALATION_INFO: 'UPDATE_ESCALATION_INFO',
  LIST: 'LIST',
  LIST_SUMMARY: 'LIST_SUMMARY',
  GET: 'GET',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  EXPORT: 'EXPORT',
  EXPORT_BANK_INFO: 'EXPORT_BANK_INFO',
  VIEW_LIVE_OPS: 'VIEW_LIVE_OPS',
  CONFIGURE_GEOFENCES: 'CONFIGURE_GEOFENCES',
  ADD_RETURN_CONTACT_PERSON: 'ADD_RETURN_CONTACT_PERSON',
  CONFIGURE_BOT_CALL_SERVICE: 'CONFIGURE_BOT_CALL_SERVICE',
  CONFIGURE_FULFILLMENT_SERVICE: 'CONFIGURE_FULFILLMENT_SERVICE',
  ADD_FLYER_QUOTA: 'ADD_FLYER_QUOTA',
  VALIDATE_STATUS: 'VALIDATE_STATUS',
  UPDATE_MAIN_LOCATION: 'UPDATE_MAIN_LOCATION',
  UPDATE_WALLET_DATA: 'UPDATE_WALLET_DATA',
  UPDATE_PRICING_DATA: 'UPDATE_PRICING_DATA',
  UPDATE_DEFAULT_PRICE_PLAN: 'UPDATE_DEFAULT_PRICE_PLAN',
  CONFIGURE_OPERATIONS_SETTINGS: 'CONFIGURE_OPERATIONS_SETTINGS',
  EDIT_TEAM_MEMBER_EMAIL: 'EDIT_TEAM_MEMBER_EMAIL',
  EDIT_TEAM_MEMBER_PHONE: 'EDIT_TEAM_MEMBER_PHONE',
  VIEW_SLLR_ANALYTICS: 'VIEW_SLLR_ANALYTICS',
  ASSIGN_ACCOUNT_MANAGER: 'ASSIGN_ACCOUNT_MANAGER',
  ASSIGN_SALES_MANAGER: 'ASSIGN_SALES_MANAGER',
  MARK_AS_RECEIVED: 'MARK_AS_RECEIVED',
  MARK_AS_IN_TRANSIT_BETWEEN_HUBS: 'MARK_AS_IN_TRANSIT_BETWEEN_HUBS',
  MARK_AS_AUTO_DELIVERED: 'MARK_AS_AUTO_DELIVERED',
  MARK_AS_RECEIVED_BY_MISTAKE: 'MARK_AS_RECEIVED_BY_MISTAKE',
  DEBRIEF: 'DEBRIEF',
  COLLECT: 'COLLECT',
  GET_MONEY_DEBRIEFING_REPORT: 'GET_MONEY_DEBRIEFING_REPORT',
  LIST_STAR_NOTIFICATIONS: 'LIST_STAR_NOTIFICATIONS',
  VIEW_AMAZON_REMITTANCE: 'VIEW_AMAZON_REMITTANCE',
  CONFIGURE_PERFORMANCE: 'CONFIGURE_PERFORMANCE',
  CONFIGURE_SALARY: 'CONFIGURE_SALARY',
  CONFIGURE_LIVE_OPS: 'CONFIGURE_LIVE_OPS',
  CONFIGURE_DISPATCHING_CUTOFFS: 'CONFIGURE_DISPATCHING_CUTOFFS',
  CONFIGURE_FLYERS: 'CONFIGURE_FLYERS',
  TOGGLE_COD_COLLECTION: 'TOGGLE_COD_COLLECTION',
  GET_SALARY: 'GET_SALARY',
  GET_PERFORMANCE: 'GET_PERFORMANCE',
  EXCLUDE_LIABILITY: 'EXCLUDE_LIABILITY',
  UNLINK_PHONE: 'UNLINK_PHONE',
  ADD_SETTLEMENT: 'ADD_SETTLEMENT',
  VIEW_QUALITY_PAGE: 'VIEW_QUALITY_PAGE',
  CREATE_ORDER: 'CREATE_ORDER',
  DEPRECATED: 'DEPRECATED',
  UPDATE_REFUND_OPTION: 'UPDATE_REFUND_OPTION',
  VIEW_ALL_TICKETS: 'VIEW_ALL_TICKETS',
  GET_BALANCE: 'GET_BALANCE',
  REQUEST_IMPERSONATION: 'REQUEST_IMPERSONATION',
  UPDATE_RECURRING_PICKUPS_OPTION: 'UPDATE_RECURRING_PICKUPS_OPTION',
  MARK_AS_IN_TRANSIT: 'MARK_AS_IN_TRANSIT',
  MARK_AS_CLEARED_FROM_CUSTOMS: 'MARK_AS_CLEARED_FROM_CUSTOMS',
  ACTIVATE_INTERNATIONAL_SHIPPING: 'ACTIVATE_INTERNATIONAL_SHIPPING',
  RECEIVE_AT_WAREHOUSE: 'RECEIVE_AT_WAREHOUSE',
  UNRESTRICTED_COUNTRIES_ACCESS: 'UNRESTRICTED_COUNTRIES_ACCESS',
  GET_REQUEST: 'GET_REQUEST',
  ASSIGN_TO_HUB: 'ASSIGN_TO_HUB',
  LIST_INTERNATIONAL: 'LIST_INTERNATIONAL',
  DELETE_INTERNATIONAL_TIER: 'DELETE_INTERNATIONAL_TIER',
  ACTIVATE: 'ACTIVATE',
  DEACTIVATE: 'DEACTIVATE'
};

export const ACL_MATRIX = {
  DELIVERY_FORCE_ASSIGN_TO_HUB: [
    PERMISSIONS_RESOURCES.DELIVERY,
    SPECIAL_PERMISSIONS.FORCE_ASSIGN_TO_HUB
  ],
  TICKETS_UPDATE_ESCALATION_INFO: [
    PERMISSIONS_RESOURCES.TICKETS,
    SPECIAL_PERMISSIONS.UPDATE_ESCALATION_INFO
  ],
  TICKETS_ESCALATE: [
    PERMISSIONS_RESOURCES.TICKETS,
    SPECIAL_PERMISSIONS.ESCALATE
  ],
  VIEW_ESCALATED_TICKETS: [
    PERMISSIONS_RESOURCES.TICKETS,
    SPECIAL_PERMISSIONS.LIST_ESCALATED_TICKETS
  ],
  EXPORT_ESCALATED_TICKETS: [
    PERMISSIONS_RESOURCES.TICKETS,
    SPECIAL_PERMISSIONS.EXPORT_ESCALATED_TICKETS
  ],
  SYSTEM_DEPRECATED: [
    PERMISSIONS_RESOURCES.SYSTEM,
    SPECIAL_PERMISSIONS.DEPRECATED
  ],
  ADMIN_GROUPS_CREATE: [
    PERMISSIONS_RESOURCES.ADMIN_GROUPS,
    SPECIAL_PERMISSIONS.CREATE
  ],
  ADMIN_GROUPS_LIST: [
    PERMISSIONS_RESOURCES.ADMIN_GROUPS,
    SPECIAL_PERMISSIONS.LIST
  ],
  ADMIN_GROUPS_UPDATE: [
    PERMISSIONS_RESOURCES.ADMIN_GROUPS,
    SPECIAL_PERMISSIONS.UPDATE
  ],
  BUSINESSES_LIST: [PERMISSIONS_RESOURCES.BUSINESS, SPECIAL_PERMISSIONS.LIST],
  BUSINESS_DETAILS: [PERMISSIONS_RESOURCES.BUSINESS, SPECIAL_PERMISSIONS.GET],
  BUSINESS_EXPORT: [PERMISSIONS_RESOURCES.BUSINESS, SPECIAL_PERMISSIONS.EXPORT],
  BUSINESS_DELETE: [PERMISSIONS_RESOURCES.BUSINESS, SPECIAL_PERMISSIONS.DELETE],
  BUSINESS_EXPORT_BANK_DETAILS: [
    PERMISSIONS_RESOURCES.BUSINESS,
    SPECIAL_PERMISSIONS.EXPORT_BANK_INFO
  ],
  BUSINESS_ASSIGN_ACCOUNT_MANAGER: [
    PERMISSIONS_RESOURCES.BUSINESS,
    SPECIAL_PERMISSIONS.ASSIGN_ACCOUNT_MANAGER
  ],
  BUSINESS_ASSIGN_SALES_MANAGER: [
    PERMISSIONS_RESOURCES.BUSINESS,
    SPECIAL_PERMISSIONS.ASSIGN_SALES_MANAGER
  ],
  BUSINESS_ADD_RETURN_CONTACT: [
    PERMISSIONS_RESOURCES.BUSINESS,
    SPECIAL_PERMISSIONS.ADD_RETURN_CONTACT_PERSON
  ],
  BUSINESS_CONFIGURE_BOT_CALL: [
    PERMISSIONS_RESOURCES.BUSINESS,
    SPECIAL_PERMISSIONS.CONFIGURE_BOT_CALL_SERVICE
  ],
  BUSINESS_CONFIGURE_FULFILLMENT: [
    PERMISSIONS_RESOURCES.BUSINESS,
    SPECIAL_PERMISSIONS.CONFIGURE_FULFILLMENT_SERVICE
  ],
  BUSINESS_ACTIVATE_INTERNATIONAL_SHIPPING: [
    PERMISSIONS_RESOURCES.BUSINESS,
    SPECIAL_PERMISSIONS.ACTIVATE_INTERNATIONAL_SHIPPING
  ],
  BUSINESS_VALIDATE_STATUS: [
    PERMISSIONS_RESOURCES.BUSINESS,
    SPECIAL_PERMISSIONS.VALIDATE_STATUS
  ],
  BUSINESS_ADD_FLYERS_QUOTE: [
    PERMISSIONS_RESOURCES.BUSINESS,
    SPECIAL_PERMISSIONS.ADD_FLYER_QUOTA
  ],
  BUSINESS_CREATE_API_KEY: [
    PERMISSIONS_RESOURCES.BUSINESS_API_KEY,
    SPECIAL_PERMISSIONS.CREATE
  ],
  BUSINESS_EDIT_API_KEY: [
    PERMISSIONS_RESOURCES.BUSINESS_API_KEY,
    SPECIAL_PERMISSIONS.UPDATE
  ],
  BUSINESS_DELETE_API_KEY: [
    PERMISSIONS_RESOURCES.BUSINESS_API_KEY,
    SPECIAL_PERMISSIONS.DELETE
  ],
  BUSINESS_UPDATE: [PERMISSIONS_RESOURCES.BUSINESS, SPECIAL_PERMISSIONS.UPDATE],
  BUSINESS_UPDATE_MAIN_LOCATION: [
    PERMISSIONS_RESOURCES.BUSINESS,
    SPECIAL_PERMISSIONS.UPDATE_MAIN_LOCATION
  ],
  BUSINESS_UPDATE_WALLET: [
    PERMISSIONS_RESOURCES.BUSINESS,
    SPECIAL_PERMISSIONS.UPDATE_WALLET_DATA
  ],
  BUSINESS_UPDATE_PRICING: [
    PERMISSIONS_RESOURCES.BUSINESS,
    SPECIAL_PERMISSIONS.UPDATE_PRICING_DATA
  ],
  BUSINESS_CONFIGURE_OPERATIONS_SETTINGS: [
    PERMISSIONS_RESOURCES.BUSINESS,
    SPECIAL_PERMISSIONS.CONFIGURE_OPERATIONS_SETTINGS
  ],
  BUSINESS_EDIT_TEAM_MEMBER_EMAIL: [
    PERMISSIONS_RESOURCES.BUSINESS,
    SPECIAL_PERMISSIONS.EDIT_TEAM_MEMBER_EMAIL
  ],
  BUSINESS_EDIT_TEAM_MEMBER_PHONE: [
    PERMISSIONS_RESOURCES.BUSINESS,
    SPECIAL_PERMISSIONS.EDIT_TEAM_MEMBER_PHONE
  ],
  BUSINESS_VIEW_SLLR_ANALYTICS: [
    PERMISSIONS_RESOURCES.BUSINESS,
    SPECIAL_PERMISSIONS.VIEW_SLLR_ANALYTICS
  ],
  BUSINESS_UPDATE_DEFAULT_PRICE_PLAN: [
    PERMISSIONS_RESOURCES.BUSINESS,
    SPECIAL_PERMISSIONS.UPDATE_DEFAULT_PRICE_PLAN
  ],
  BUSINESS_UPDATE_PICKUP_LOCATION: [
    PERMISSIONS_RESOURCES.BUSINESS_PICKUP_LOCATIONS,
    SPECIAL_PERMISSIONS.UPDATE
  ],
  BUSINESS_UPDATE_REFUND_OPTION: [
    PERMISSIONS_RESOURCES.BUSINESS,
    SPECIAL_PERMISSIONS.UPDATE_REFUND_OPTION
  ],
  BUSINESS_UPDATE_RECURRING_PICKUPS_OPTION: [
    PERMISSIONS_RESOURCES.BUSINESS,
    SPECIAL_PERMISSIONS.UPDATE_RECURRING_PICKUPS_OPTION
  ],
  BUSINESS_LIST_INTERNATIONAL: [
    PERMISSIONS_RESOURCES.BUSINESS,
    SPECIAL_PERMISSIONS.LIST_INTERNATIONAL
  ],
  PROMOTIONS_LIST: [PERMISSIONS_RESOURCES.PROMOTIONS, SPECIAL_PERMISSIONS.GET],
  PRICING_TIERS_LIST: [
    PERMISSIONS_RESOURCES.PRICING_TIERS,
    SPECIAL_PERMISSIONS.GET
  ],
  PRICING_TIERS_CREATE: [
    PERMISSIONS_RESOURCES.PRICING_TIERS,
    SPECIAL_PERMISSIONS.CREATE
  ],
  PRICING_TIERS_GET: [
    PERMISSIONS_RESOURCES.PRICING_TIERS,
    SPECIAL_PERMISSIONS.GET
  ],
  PRICING_TIERS_UPDATE: [
    PERMISSIONS_RESOURCES.PRICING_TIERS,
    SPECIAL_PERMISSIONS.UPDATE
  ],
  PRICING_TIERS_DELETE: [
    PERMISSIONS_RESOURCES.PRICING_TIERS,
    SPECIAL_PERMISSIONS.DELETE
  ],
  PRICING_TRANSIT_COSTS_UPDATE: [
    PERMISSIONS_RESOURCES.PRICING_TRANSIT_COSTS,
    SPECIAL_PERMISSIONS.UPDATE
  ],
  PRICING_SERVICE_TYPES_UPDATE: [
    PERMISSIONS_RESOURCES.PRICING_SERVICE_TYPES,
    SPECIAL_PERMISSIONS.UPDATE
  ],
  PRICING_SIZES_UPDATE: [
    PERMISSIONS_RESOURCES.PRICING_SIZES,
    SPECIAL_PERMISSIONS.UPDATE
  ],
  DELIVERIES_LIST: [PERMISSIONS_RESOURCES.DELIVERY, SPECIAL_PERMISSIONS.LIST],
  DELIVERY_DETAILS: [PERMISSIONS_RESOURCES.DELIVERY, SPECIAL_PERMISSIONS.GET],
  DELIVERY_CREATE: [PERMISSIONS_RESOURCES.DELIVERY, SPECIAL_PERMISSIONS.CREATE],
  DELIVERY_MARK_AS_RECEIVED: [
    PERMISSIONS_RESOURCES.DELIVERY,
    SPECIAL_PERMISSIONS.MARK_AS_RECEIVED
  ],
  DELIVERY_MARK_AS_IN_TRANSIT_BETWEEN_HUBS: [
    PERMISSIONS_RESOURCES.DELIVERY,
    SPECIAL_PERMISSIONS.MARK_AS_IN_TRANSIT_BETWEEN_HUBS
  ],
  DELIVERY_MARK_AS_AUTO_DELIVERED: [
    PERMISSIONS_RESOURCES.DELIVERY,
    SPECIAL_PERMISSIONS.MARK_AS_AUTO_DELIVERED
  ],
  DELIVERY_MARK_AS_RECEIVED_BY_MISTAKE: [
    PERMISSIONS_RESOURCES.DELIVERY,
    SPECIAL_PERMISSIONS.MARK_AS_RECEIVED_BY_MISTAKE
  ],
  DELIVERY_CONFIGURE_LIVE_OPS: [
    PERMISSIONS_RESOURCES.DELIVERY,
    SPECIAL_PERMISSIONS.CONFIGURE_LIVE_OPS
  ],
  DELIVERY_VIEW_QUALITY_PAGE: [
    PERMISSIONS_RESOURCES.DELIVERY,
    SPECIAL_PERMISSIONS.VIEW_QUALITY_PAGE
  ],
  DELIVERY_ASSIGN_TO_HUB: [
    PERMISSIONS_RESOURCES.DELIVERY,
    SPECIAL_PERMISSIONS.ASSIGN_TO_HUB
  ],
  LIVE_OPS: [PERMISSIONS_RESOURCES.DELIVERY, SPECIAL_PERMISSIONS.VIEW_LIVE_OPS],
  HUBS: [PERMISSIONS_RESOURCES.HUBS, SPECIAL_PERMISSIONS.LIST],
  HUBS_CREATE: [PERMISSIONS_RESOURCES.HUBS, SPECIAL_PERMISSIONS.CREATE],
  HUBS_SCORES_LIST: [
    PERMISSIONS_RESOURCES.HUBS_SCORES,
    SPECIAL_PERMISSIONS.LIST
  ],
  HUBS_SCORES_LIST_SUMMARY: [
    PERMISSIONS_RESOURCES.HUBS_SCORES,
    SPECIAL_PERMISSIONS.LIST_SUMMARY
  ],
  HUBS_TRANSFER_LIST: [
    PERMISSIONS_RESOURCES.HUBS_TRANSFER,
    SPECIAL_PERMISSIONS.LIST
  ],
  ACTION_CENTER_LIST: [
    PERMISSIONS_RESOURCES.ACTION_CENTER,
    SPECIAL_PERMISSIONS.LIST
  ],
  ACTION_CENTER_LIST_SUMMARY: [
    PERMISSIONS_RESOURCES.ACTION_CENTER,
    SPECIAL_PERMISSIONS.LIST_SUMMARY
  ],
  CONFIGURE_GEOFENCES: [
    PERMISSIONS_RESOURCES.HUBS,
    SPECIAL_PERMISSIONS.CONFIGURE_GEOFENCES
  ],
  STARS_LIST: [PERMISSIONS_RESOURCES.STARS, SPECIAL_PERMISSIONS.LIST],
  STARS_GET: [PERMISSIONS_RESOURCES.STARS, SPECIAL_PERMISSIONS.GET],
  STARS_CREATE: [PERMISSIONS_RESOURCES.STARS, SPECIAL_PERMISSIONS.CREATE],
  STARS_UPDATE: [PERMISSIONS_RESOURCES.STARS, SPECIAL_PERMISSIONS.UPDATE],
  STARS_EXPORT: [PERMISSIONS_RESOURCES.STARS, SPECIAL_PERMISSIONS.EXPORT],
  STARS_GET_SALARY: [
    PERMISSIONS_RESOURCES.STARS,
    SPECIAL_PERMISSIONS.GET_SALARY
  ],
  STARS_GET_PERFORMANCE: [
    PERMISSIONS_RESOURCES.STARS,
    SPECIAL_PERMISSIONS.GET_PERFORMANCE
  ],
  STARS_EXCLUDE_LIABILITY: [
    PERMISSIONS_RESOURCES.STARS,
    SPECIAL_PERMISSIONS.EXCLUDE_LIABILITY
  ],
  STARS_UNLINK_PHONE: [
    PERMISSIONS_RESOURCES.STARS,
    SPECIAL_PERMISSIONS.UNLINK_PHONE
  ],
  STAR_ACTIONS_LIST: [
    PERMISSIONS_RESOURCES.STAR_ACTIONS,
    SPECIAL_PERMISSIONS.LIST
  ],
  STARS_CONFIGURE_PERFORMANCE: [
    PERMISSIONS_RESOURCES.STARS,
    SPECIAL_PERMISSIONS.CONFIGURE_PERFORMANCE
  ],
  STARS_CONFIGURE_SALARY: [
    PERMISSIONS_RESOURCES.STARS,
    SPECIAL_PERMISSIONS.CONFIGURE_SALARY
  ],
  STARS_TOGGLE_COD_COLLECTION: [
    PERMISSIONS_RESOURCES.STARS,
    SPECIAL_PERMISSIONS.TOGGLE_COD_COLLECTION
  ],
  STARS_ACTIVATE: [PERMISSIONS_RESOURCES.STARS, SPECIAL_PERMISSIONS.ACTIVATE],
  STARS_DEACTIVATE: [
    PERMISSIONS_RESOURCES.STARS,
    SPECIAL_PERMISSIONS.DEACTIVATE
  ],
  ROUTES: [PERMISSIONS_RESOURCES.ROUTES, SPECIAL_PERMISSIONS.LIST],
  ROUTES_DEBRIEF: [PERMISSIONS_RESOURCES.ROUTES, SPECIAL_PERMISSIONS.DEBRIEF],
  ROUTES_CREATE: [PERMISSIONS_RESOURCES.ROUTES, SPECIAL_PERMISSIONS.CREATE],
  PICKUPS_LIST: [
    PERMISSIONS_RESOURCES.PICKUP_REQUESTS,
    SPECIAL_PERMISSIONS.LIST
  ],
  PICKUP_DETAILS: [
    PERMISSIONS_RESOURCES.PICKUP_REQUESTS,
    SPECIAL_PERMISSIONS.GET
  ],
  PICKUP_CREATE: [
    PERMISSIONS_RESOURCES.PICKUP_REQUESTS,
    SPECIAL_PERMISSIONS.CREATE
  ],
  CASH_MONEY_COLLECT: [
    PERMISSIONS_RESOURCES.CASH_MONEY,
    SPECIAL_PERMISSIONS.COLLECT
  ],
  CASH_MONEY_ADD_SETTLEMENT: [
    PERMISSIONS_RESOURCES.CASH_MONEY,
    SPECIAL_PERMISSIONS.ADD_SETTLEMENT
  ],
  CASH_MONEY_GET_MONEY_DEBRIEFING_REPORT: [
    PERMISSIONS_RESOURCES.CASH_MONEY,
    SPECIAL_PERMISSIONS.GET_MONEY_DEBRIEFING_REPORT
  ],
  CASH_MONEY_EXPORT: [
    PERMISSIONS_RESOURCES.CASH_MONEY,
    SPECIAL_PERMISSIONS.EXPORT
  ],
  CASH_MONEY_CREATE: [
    PERMISSIONS_RESOURCES.CASH_MONEY,
    SPECIAL_PERMISSIONS.CREATE
  ],
  SEALS_LIST: [PERMISSIONS_RESOURCES.SEALS, SPECIAL_PERMISSIONS.LIST],
  NOTIFICATION_LIST_STAR_NOTIFICATION: [
    PERMISSIONS_RESOURCES.NOTIFICATION,
    SPECIAL_PERMISSIONS.LIST_STAR_NOTIFICATIONS
  ],
  BARCODES_GET: [PERMISSIONS_RESOURCES.BARCODES, SPECIAL_PERMISSIONS.GET],
  BARCODES_CREATE: [PERMISSIONS_RESOURCES.BARCODES, SPECIAL_PERMISSIONS.CREATE],
  WALLET_TRANSACTIONS_LIST: [
    PERMISSIONS_RESOURCES.WALLET_TRANSACTIONS,
    SPECIAL_PERMISSIONS.LIST
  ],
  WALLET_CASH_CYCLES_LIST: [
    PERMISSIONS_RESOURCES.WALLET_CASH_CYCLES,
    SPECIAL_PERMISSIONS.LIST
  ],
  WALLET_ADJUSTMENTS_CREATE: [
    PERMISSIONS_RESOURCES.WALLET_ADJUSTMENTS,
    SPECIAL_PERMISSIONS.CREATE
  ],
  COMPENSATION_LIST: [
    PERMISSIONS_RESOURCES.COMPENSATION,
    SPECIAL_PERMISSIONS.LIST
  ],
  FUNDING_PROFILES_GET: [
    PERMISSIONS_RESOURCES.FUNDING_PROFILES,
    SPECIAL_PERMISSIONS.GET
  ],
  FINANCE_REPORTS_VIEW_AMAZON_REMITTANCE: [
    PERMISSIONS_RESOURCES.FINANCE_REPORTS,
    SPECIAL_PERMISSIONS.VIEW_AMAZON_REMITTANCE
  ],
  TICKETS_LIST: [PERMISSIONS_RESOURCES.TICKETS, SPECIAL_PERMISSIONS.LIST],
  ZONES_UPDATE: [PERMISSIONS_RESOURCES.ZONES, SPECIAL_PERMISSIONS.UPDATE],
  COUNTRY_CONFIGURE_DISPATCHING_CUTOFFS: [
    PERMISSIONS_RESOURCES.COUNTRY,
    SPECIAL_PERMISSIONS.CONFIGURE_DISPATCHING_CUTOFFS
  ],
  BOSTA_SHOP_CONFIGURE_FLYERS: [
    PERMISSIONS_RESOURCES.BOSTA_SHOP,
    SPECIAL_PERMISSIONS.CONFIGURE_FLYERS
  ],
  BOX_COUNTRY_CREATE: [
    PERMISSIONS_RESOURCES.BOX_COUNTRY,
    SPECIAL_PERMISSIONS.CREATE
  ],
  VENDORS_LIST: [PERMISSIONS_RESOURCES.VENDORS, SPECIAL_PERMISSIONS.LIST],
  CASHIER_LIST: [PERMISSIONS_RESOURCES.CASHIER, SPECIAL_PERMISSIONS.LIST],
  BOX_ITEM_LIST: [PERMISSIONS_RESOURCES.BOX_ITEM, SPECIAL_PERMISSIONS.LIST],
  FULFILLMENT_PRODUCTS_LIST: [
    PERMISSIONS_RESOURCES.FULFILLMENT_PRODUCTS,
    SPECIAL_PERMISSIONS.LIST
  ],
  THREE_PL_PROVIDERS_CREATE_ORDER: [
    PERMISSIONS_RESOURCES.THREE_PL_PROVIDERS,
    SPECIAL_PERMISSIONS.CREATE_ORDER
  ],
  SLA_HOLIDAYS_CREATE: [
    PERMISSIONS_RESOURCES.SLA_HOLIDAYS,
    SPECIAL_PERMISSIONS.CREATE
  ],
  STREET_CODES_CREATE: [
    PERMISSIONS_RESOURCES.STREET_CODES,
    SPECIAL_PERMISSIONS.CREATE
  ],
  FULFILLMENT_PRICING_TIERS_GET: [
    PERMISSIONS_RESOURCES.FULFILLMENT_PRICING_TIERS,
    SPECIAL_PERMISSIONS.GET
  ],
  TICKETS_VIEW_ALL_TICKETS: [
    PERMISSIONS_RESOURCES.TICKETS,
    SPECIAL_PERMISSIONS.VIEW_ALL_TICKETS
  ],
  FLYERS_GET_BALANCE: [
    PERMISSIONS_RESOURCES.FLYERS,
    SPECIAL_PERMISSIONS.GET_BALANCE
  ],
  USERS_REQUEST_IMPERSONATION: [
    PERMISSIONS_RESOURCES.USERS,
    SPECIAL_PERMISSIONS.REQUEST_IMPERSONATION
  ],
  FLYERS_ORDERS_PAGE: [
    PERMISSIONS_RESOURCES.FLYERS,
    SPECIAL_PERMISSIONS.GET_REQUEST
  ],
  INTERNATIONAL_ORDER_LIST: [
    PERMISSIONS_RESOURCES.INTERNATIONAL_ORDER,
    SPECIAL_PERMISSIONS.LIST
  ],
  INTERNATIONAL_ORDER_GET: [
    PERMISSIONS_RESOURCES.INTERNATIONAL_ORDER,
    SPECIAL_PERMISSIONS.GET
  ],
  INTERNATIONAL_ORDER_UPDATE: [
    PERMISSIONS_RESOURCES.INTERNATIONAL_ORDER,
    SPECIAL_PERMISSIONS.UPDATE
  ],
  INTERNATIONAL_ORDER_MARK_AS_IN_TRANSIT: [
    PERMISSIONS_RESOURCES.INTERNATIONAL_ORDER,
    SPECIAL_PERMISSIONS.MARK_AS_IN_TRANSIT
  ],
  INTERNATIONAL_ORDER_MARK_AS_CLEARED_FROM_CUSTOMS: [
    PERMISSIONS_RESOURCES.INTERNATIONAL_ORDER,
    SPECIAL_PERMISSIONS.MARK_AS_CLEARED_FROM_CUSTOMS
  ],
  INTERNATIONAL_ORDER_RECEIVE_AT_WAREHOUSE: [
    PERMISSIONS_RESOURCES.INTERNATIONAL_ORDER,
    SPECIAL_PERMISSIONS.RECEIVE_AT_WAREHOUSE
  ],
  INTERNATIONAL_ORDER_UNRESTRICTED_COUNTRIES_ACCESS: [
    PERMISSIONS_RESOURCES.INTERNATIONAL_ORDER,
    SPECIAL_PERMISSIONS.UNRESTRICTED_COUNTRIES_ACCESS
  ],
  RECEIVING_MANIFEST_LIST: [
    PERMISSIONS_RESOURCES.RECEIVING_MANIFEST,
    SPECIAL_PERMISSIONS.LIST
  ],
  BOSTA_FULFILLMENT_LIST: [
    PERMISSIONS_RESOURCES.BOSTA_FULFILLMENT,
    SPECIAL_PERMISSIONS.LIST
  ],
  PRICING_INTERNATIONAL_TIERS_UPDATE: [
    PERMISSIONS_RESOURCES.PRICING_INTERNATIONAL_TIERS,
    SPECIAL_PERMISSIONS.UPDATE
  ],
  PRICING_INTERNATIONAL_TIERS_DELETE_INTERNATIONAL_TIER: [
    PERMISSIONS_RESOURCES.PRICING_INTERNATIONAL_TIERS,
    SPECIAL_PERMISSIONS.DELETE_INTERNATIONAL_TIER
  ],
  CAPITAL_CONFIGURATIONS_GET: [
    PERMISSIONS_RESOURCES.CAPITAL_CONFIGURATIONS,
    SPECIAL_PERMISSIONS.GET
  ],
  DELIVERY_SCANS_LOGS_LIST: [
    PERMISSIONS_RESOURCES.DELIVERY_SCANS_LOGS,
    SPECIAL_PERMISSIONS.LIST
  ],
  DELIVERY_SCANS_LOGS_EXPORT: [
    PERMISSIONS_RESOURCES.DELIVERY_SCANS_LOGS,
    SPECIAL_PERMISSIONS.EXPORT
  ],
  TRANSACTIONS_CARDS_GET: [
    PERMISSIONS_RESOURCES.TRANSACTIONS_CARDS,
    SPECIAL_PERMISSIONS.GET
  ],
  TRANSACTIONS_LIST: [
    PERMISSIONS_RESOURCES.TRANSACTIONS,
    SPECIAL_PERMISSIONS.LIST
  ],
  TRANSACTIONS_EXPORT: [
    PERMISSIONS_RESOURCES.TRANSACTIONS,
    SPECIAL_PERMISSIONS.EXPORT
  ],
  TRANSACTIONS_RECEIPT_GET: [
    PERMISSIONS_RESOURCES.TRANSACTIONS_RECEIPT,
    SPECIAL_PERMISSIONS.GET
  ],
  CASH_CYCLES_CARDS_GET: [
    PERMISSIONS_RESOURCES.CASH_CYCLES_CARDS,
    SPECIAL_PERMISSIONS.GET
  ],
  CASH_CYCLES_LIST: [
    PERMISSIONS_RESOURCES.CASH_CYCLES_CARDS,
    SPECIAL_PERMISSIONS.LIST
  ],
  CASH_CYCLES_EXPORT: [
    PERMISSIONS_RESOURCES.CASH_CYCLES_CARDS,
    SPECIAL_PERMISSIONS.EXPORT
  ],
  SUBSCRIPTIONS_LIST: [
    PERMISSIONS_RESOURCES.SUBSCRIPTIONS,
    SPECIAL_PERMISSIONS.LIST
  ]
};

export const PAGES_ACCESS = {
  BUSINESSES_LIST: [ACL_MATRIX.BUSINESSES_LIST],
  PACKAGING_PAGE: [
    ACL_MATRIX.FLYERS_GET_BALANCE,
    ACL_MATRIX.FLYERS_ORDERS_PAGE
  ],
  BUSINESS_DETAILS: [ACL_MATRIX.BUSINESS_DETAILS],
  BUSINESS_EDIT: [
    ACL_MATRIX.BUSINESS_UPDATE,
    ACL_MATRIX.BUSINESS_UPDATE_MAIN_LOCATION,
    ACL_MATRIX.BUSINESS_UPDATE_WALLET,
    ACL_MATRIX.BUSINESS_CONFIGURE_OPERATIONS_SETTINGS,
    ACL_MATRIX.BUSINESS_UPDATE_PRICING,
    ACL_MATRIX.BUSINESS_CREATE_API_KEY,
    ACL_MATRIX.BUSINESS_UPDATE_PICKUP_LOCATION
  ],
  DELIVERIES_LIST: [ACL_MATRIX.DELIVERIES_LIST],
  DELIVERIES_CREATE: [ACL_MATRIX.DELIVERY_CREATE],
  DELIVERY_DEATILS: [ACL_MATRIX.DELIVERY_DETAILS],
  ROUTES: [ACL_MATRIX.ROUTES],
  ROUTE_CREATE_EDIT: [ACL_MATRIX.ROUTES_CREATE],
  PICKUPS: [ACL_MATRIX.PICKUPS_LIST],
  PICKUP_DETAILS: [ACL_MATRIX.PICKUP_DETAILS],
  PICKUPS_CREATE: [ACL_MATRIX.PICKUP_CREATE],
  HUBS: [ACL_MATRIX.HUBS],
  HUBS_OPERATIONS: [
    ACL_MATRIX.DELIVERY_MARK_AS_RECEIVED,
    ACL_MATRIX.DELIVERY_MARK_AS_IN_TRANSIT_BETWEEN_HUBS,
    ACL_MATRIX.ROUTES_DEBRIEF,
    ACL_MATRIX.CASH_MONEY_COLLECT,
    ACL_MATRIX.SEALS_LIST,
    ACL_MATRIX.RECEIVING_MANIFEST_LIST
  ],
  HUBS_MANAGEMENT: [
    ACL_MATRIX.HUBS_CREATE,
    ACL_MATRIX.CASH_MONEY_GET_MONEY_DEBRIEFING_REPORT,
    ACL_MATRIX.HUBS_TRANSFER_LIST,
    ACL_MATRIX.DELIVERY_MARK_AS_AUTO_DELIVERED
  ],
  HUBS_OPERATIONS_MONITORING: [
    ACL_MATRIX.HUBS_SCORES_LIST,
    ACL_MATRIX.HUBS_SCORES_LIST_SUMMARY,
    ACL_MATRIX.DELIVERIES_LIST
  ],
  LIVE_OPS: [ACL_MATRIX.LIVE_OPS],
  GEOFENCES: [ACL_MATRIX.CONFIGURE_GEOFENCES],
  ACTION_CENTER_HUB_VIEW: [ACL_MATRIX.ACTION_CENTER_LIST],
  ACTION_CENTER_AUDIT_VIEW: [ACL_MATRIX.ACTION_CENTER_LIST_SUMMARY],
  WALLET: [
    ACL_MATRIX.WALLET_TRANSACTIONS_LIST,
    ACL_MATRIX.WALLET_CASH_CYCLES_LIST,
    ACL_MATRIX.WALLET_ADJUSTMENTS_CREATE,
    ACL_MATRIX.COMPENSATION_LIST
  ],
  STARS: [
    ACL_MATRIX.STARS_LIST,
    ACL_MATRIX.STARS_GET_SALARY,
    ACL_MATRIX.STAR_ACTIONS_LIST,
    ACL_MATRIX.VENDORS_LIST,
    ACL_MATRIX.STARS_GET_PERFORMANCE
  ],
  USER_MANAGMENT: [
    ACL_MATRIX.ADMIN_GROUPS_LIST,
    ACL_MATRIX.ADMIN_GROUPS_UPDATE,
    ACL_MATRIX.ADMIN_GROUPS_CREATE
  ],
  PRICING: [
    ACL_MATRIX.PRICING_TIERS_GET,
    ACL_MATRIX.FULFILLMENT_PRICING_TIERS_GET
  ],
  INTERNATIOANL_SHIPPING: [
    ACL_MATRIX.INTERNATIONAL_ORDER_LIST,
    ACL_MATRIX.INTERNATIONAL_ORDER_MARK_AS_IN_TRANSIT
  ],
  INTERNATIONAL_WALLET: [
    ACL_MATRIX.TRANSACTIONS_CARDS_GET,
    ACL_MATRIX.TRANSACTIONS_LIST,
    ACL_MATRIX.TRANSACTIONS_EXPORT,
    ACL_MATRIX.TRANSACTIONS_RECEIPT_GET,
    ACL_MATRIX.CASH_CYCLES_CARDS_GET,
    ACL_MATRIX.CASH_CYCLES_LIST,
    ACL_MATRIX.CASH_CYCLES_EXPORT
  ]
};
