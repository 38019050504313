import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Button } from 'antd';
import dayjs from 'dayjs';

import { COMPLETED_ORDERS_REACHES_FINAL_STATES_CODES } from 'constants/hubs';
import { COUNTRY_CURRENCY } from 'constants/helper';
import { TAB_KEYS } from 'constants/debrief';
import { isReturnOrder } from 'utils/shipmentDetails';
import { getDeliveryType } from 'utils/deliveries';

import { createTicket, confirmReceivingDeliveries } from 'services/hubs';
import deliveryStateFormatter from 'containers/Deliveries/common/DeliveryStateFormatter';
import { notify } from 'components/Notify/Notify';
import BRSearchableTable from 'components/BRSearchableTable/BRSearchableTable';

import './DeliveredTab.less';

const DeliveredTab = ({
  starData,
  intl,
  routesIds,
  hubName,
  getUpdatedData,
  handleConfirmReceiving,
  setIsConfirmReceivingDisabled
}) => {
  const [selectedRowsKeys, setSelectedRowsKeys] = useState([]);
  const handleCreateTicket = async (trackingNumber) => {
    const ticketCreationDate = dayjs().format('D MMM');
    try {
      const ticketData = {
        trackingNumber,
        category: 'Delivery Issue',
        subCategory: 'Delivered by Mistake',
        reason: `Order picked up by Mistake || ${ticketCreationDate} || ${hubName}`,
        description: `Order delivered by mistake. Please restart order of tracking number:${trackingNumber}`,
        rank: 'Low'
      };
      const { externalId } = await createTicket({ ...ticketData });
      notify(`Ticket Id: ${externalId} is created successfulyy`, 'success');
      getUpdatedData();
    } catch (error) {
      notify(error.message);
    }
  };

  const onConfirmReceivingOrders = async () => {
    try {
      if (selectedRowsKeys.length > 0) {
        setIsConfirmReceivingDisabled(true);
        const res = await confirmReceivingDeliveries({
          deliveryIds: selectedRowsKeys,
          routeIds: routesIds
        });
        if (res) {
          getUpdatedData();
        }
        setIsConfirmReceivingDisabled(false);
      } else {
        notify('Please select orders');
      }
    } catch (error) {
      setIsConfirmReceivingDisabled(false);
      notify(error.message);
    }
  };

  const columns = [
    {
      title: intl.formatMessage({
        id: `hubs.packages_debrief.columns.tracking_number`
      }),
      dataIndex: 'trackingNumber'
    },
    {
      title: intl.formatMessage({
        id: `hubs.packages_debrief.columns.last_update_date`
      }),
      dataIndex: 'updatedAt',
      render: (updatedAt) =>
        dayjs(new Date(updatedAt)).tz().format('DD MMMM, HH:mm A')
    },
    {
      title: intl.formatMessage({
        id: `hubs.packages_debrief.columns.type`
      }),
      dataIndex: '',
      render: getDeliveryType
    },
    {
      title: intl.formatMessage({
        id: `hubs.packages_debrief.columns.state`
      }),

      render: (item) => deliveryStateFormatter.getStateName(item)
    },
    {
      title: intl.formatMessage({
        id: `hubs.packages_debrief.columns.cod`
      }),
      dataIndex: ['cod', 'amount'],
      render: (cod) =>
        cod
          ? `${intl.formatMessage(
              {
                id: COUNTRY_CURRENCY
              },
              {
                cod
              }
            )}`
          : 'N/A'
    },
    {
      title: intl.formatMessage({ id: 'pickups.table_columns.action' }),

      render: (_, record) => {
        return record?.tickets?.length ? (
          `Ticket ID: ${record.tickets.join(',')}`
        ) : (
          <Button onClick={() => handleCreateTicket(record.trackingNumber)}>
            Create Ticket
          </Button>
        );
      }
    }
  ];

  useEffect(() => {
    setIsConfirmReceivingDisabled(!selectedRowsKeys.length);
  }, [selectedRowsKeys]);

  useEffect(() => {
    if (handleConfirmReceiving) {
      handleConfirmReceiving[TAB_KEYS.DELIVERED] = onConfirmReceivingOrders;
    }
  });

  return (
    <>
      <BRSearchableTable
        title={`Delivered orders (${
          starData?.filter(
            (el) =>
              COMPLETED_ORDERS_REACHES_FINAL_STATES_CODES.includes(
                el.state.code
              ) &&
              !el?.isConfirmedDelivery &&
              !isReturnOrder(el)
          ).length
        })`}
        listData={starData?.filter(
          (el) =>
            COMPLETED_ORDERS_REACHES_FINAL_STATES_CODES.includes(
              el.state.code
            ) &&
            !el?.isConfirmedDelivery &&
            !isReturnOrder(el)
        )}
        onRowClick={() => {}}
        columns={columns}
        scrollY={300}
        selectedRows={setSelectedRowsKeys}
        hidePagination
      />
    </>
  );
};

export default injectIntl(DeliveredTab);
