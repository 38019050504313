import React from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';
import { Typography, Table, Button, Input, DatePicker, Select } from 'antd';
import Icon, { SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { notify } from 'components/Notify/Notify';

import EmptySearchView from 'assets/imgRevamp/EmptySearchView.png';
import { ReactComponent as ExportIcon } from 'assets/imgRevamp/export.svg';
import { ReactComponent as emptyTable } from 'assets/imgRevamp/emptyTable.svg';

import './BRSearchableTable.less';

const PAGE_SIZE = 50;

class BRSearchableTable extends React.Component {
  state = {
    count: 0,
    listingData: [],
    selectedRowKeys: [],
    isLoading: false,
    pageId: 1,
    searchInputText: '',
    searchFilterValue: null,
    isSearchResult: false,
    formattedColumns: [],
    pageSize: this.props.pageLimit || PAGE_SIZE
  };
  componentDidMount() {
    const { listFunction, listData, count, columns } = this.props;
    const { pageSize } = this.state;
    //if data is object and we don't want to get this request inside table component
    if (listData) {
      this.setState({
        listingData: listData,
        count: count
      });
    }
    //If we want to make calling request inside table component
    else if (listFunction) {
      this.getData({ pageId: 1, pageSize });
    }

    // Child passes its method to the parent
    if (this.props.shareMethods) {
      this.props.shareMethods(this.refresh.bind(this));
    }
    if (columns) {
      const { columns } = this.props;
      let formattedColumns = [];
      columns?.map((column) => {
        formattedColumns.push(
          column?.render
            ? { ...column }
            : { ...column, render: (item) => item || 'N/A' }
        );
      });
      this.setState({ formattedColumns });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.listData !== this.props.listData) {
      const { listData, count, pageNumber } = this.props;
      this.setState({
        listingData: listData,
        count: count,
        pageId: pageNumber
      });
    }
  }

  refresh({ pageId: pageIdProp } = {}) {
    const { pageId, searchInputText, searchFilterValue, pageSize } = this.state;
    this.getData({
      pageId: pageIdProp || pageId,
      pageSize,
      searchValue: searchInputText,
      filterValue: searchFilterValue
    });
  }

  getData = async ({
    pageId,
    pageSize,
    sortField,
    sortOrder,
    searchValue,
    filterValue
  }) => {
    try {
      this.setState({ isLoading: true });
      const {
        listFunction,
        searchAndFilterFunction,
        pageSize: pageSizeProp
      } = this.props;
      const { searchInputText, searchFilterValue } = this.state;

      const payload = {
        pageId,
        pageLimit: pageSizeProp
          ? pageSizeProp
          : pageSize
          ? pageSize
          : PAGE_SIZE,
        sortField,
        sortOrder,
        searchValue: searchValue ? searchValue : searchInputText,
        filterValue: filterValue ? filterValue : searchFilterValue
      };
      const fetchData =
        (searchValue || filterValue) && searchAndFilterFunction
          ? searchAndFilterFunction
          : listFunction;

      const data = await fetchData(payload);
      if (data) {
        const { list, total } = data;
        this.setState({
          listingData: list,
          count: total,
          pageId,
          isLoading: false
        });
      } else {
        this.setState({
          listingData: [],
          count: 0,
          pageId,
          isLoading: false
        });
      }
    } catch (error) {
      notify(error.message);
    }
    this.setState({ isLoading: false });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { searchInputText, searchFilterValue } = this.state;
    const { handlePagination, listFunction } = this.props;
    if (pagination.pageSize) {
      this.setState({ pageSize: pagination.pageSize });
    }
    if (handlePagination) {
      handlePagination(pagination.current);
    }
    listFunction &&
      this.getData({
        pageId: pagination.current,
        pageSize: pagination.pageSize,
        ...filters,
        sortField: sorter.field,
        sortOrder: sorter.order,
        searchValue: searchInputText,
        filterValue: searchFilterValue
      });
  };

  handleInputSearch = async (inputText) => {
    const { setSearchValue, handleTextInput, setFilterValue } = this.props;

    let payloadSearch = null;
    if (handleTextInput) {
      payloadSearch = handleTextInput(inputText);
    }
    const searchText = payloadSearch ? payloadSearch : inputText;
    const { searchInputText } = this.state;
    if (inputText === searchInputText) {
      return;
    }

    if (inputText) {
      this.setState(
        {
          pageId: 1,
          searchInputText: searchText,
          isSearchResult: true
        },
        () => this.getData({ pageId: 1, searchValue: searchText })
      );
      if (setSearchValue) {
        setSearchValue(searchText);
      }
    } else {
      await this.setState(
        {
          pageId: 1,
          searchInputText: searchText,
          isSearchResult: false
        },
        () => this.getData({ pageId: 1, searchValue: null })
      );
    }
  };

  handleFilterSearch = async (searchFilterValue) => {
    const { setFilterValue } = this.props;
    if (searchFilterValue) {
      await this.setState({
        pageId: 1,
        searchFilterValue,
        isSearchResult: true
      });
      this.getData({ pageId: 1, searchFilterValue });
      if (setFilterValue) {
        setFilterValue(searchFilterValue);
      }
    } else {
      await this.setState({
        pageId: 1,
        searchFilterValue: null,
        isSearchResult: false
      });
      this.getData({ pageId: 1, filterValue: null });
    }
  };

  handleExport = async () => {
    const { selectedRowKeys, searchFilterValue } = this.state;
    const { exportListFileFunction } = this.props;
    const payload = {
      ...(selectedRowKeys.length && { selectedRowKeys }),
      ...(searchFilterValue && { filterValue: searchFilterValue })
    };
    this.setState({ isLoading: true });
    try {
      await exportListFileFunction(payload);
    } catch (error) {
      notify(error.message);
    }
    this.setState({ isLoading: false });
  };

  onChangeDatePicker = async (dateObjects, dateValues) => {
    const { setFilterValue } = this.props;
    const { searchFilterValue } = this.state;
    await this.setState({
      pageId: 1,
      searchFilterValue: {
        ...searchFilterValue,
        scheduledAtStart:
          dateValues[0] &&
          dayjs(dateObjects[0])
            .utc()
            .set('hour', 0)
            .set('minute', 0)
            .set('second', 0)
            .format(),

        scheduledAtEnd:
          dateValues[1] &&
          dayjs(dateObjects[1])
            .utc()
            .set('hour', 23)
            .set('minute', 59)
            .set('second', 59)
            .format()
      },
      pickerOpen: false,
      isSearchResult: true
    });
    this.getData({ pageId: 1, searchFilterValue });
    if (setFilterValue) {
      setFilterValue({
        scheduledAtStart:
          dateValues[0] &&
          dayjs(dateValues[0], 'DD-MM-YYYY').locale('en').format('YYYY-MM-DD'),
        scheduledAtEnd:
          dateValues[1] &&
          dayjs(dateValues[1], 'DD-MM-YYYY').locale('en').format('YYYY-MM-DD')
      });
    }
  };

  handleChange = async (value) => {
    const { searchFilterValue } = this.state;
    const { onSelectBusiness } = this.props;
    await onSelectBusiness(value);
    await this.setState({
      pageId: 1,
      searchFilterValue: {
        ...searchFilterValue,
        businessId: value
      },
      pickerOpen: false,
      isSearchResult: true
    });
    this.getData({ pageId: 1, searchFilterValue });
  };

  render() {
    const {
      showSearch,
      disableExport,
      showDateFilter,
      showFilter,
      intl,
      className,
      history,
      match: { url },
      onRowClick,
      filterItems,
      hidePagination,
      title,
      withOutCheckBoxes,
      scrollY,
      tableTitleClassName,
      selectedRows,
      paginationPostion,
      emptyTableView,
      exportListFileFunction,
      searchPrefix,
      searchInputPlaceholder,
      filterComponents,
      pageSizeOptions = [],
      showExportIcon = true,
      hideSelection = false,
      emptyViewSubtitle,
      businessess,
      businessId,
      customColumns,
      filterOptions,
      scroll = false,
      ...props
    } = this.props;

    const {
      listingData,
      count,
      selectedRowKeys,
      pageId,
      isLoading,
      isSearchResult,
      formattedColumns,
      pageSize
    } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
        selectedRows && selectedRows(selectedRowKeys);
      }
    };

    return (
      <div className={classnames('br-searchable-table', className)}>
        <div className="br-searchable-component__header">
          <Typography.Title className={tableTitleClassName} level={4}>
            {title || ''}
          </Typography.Title>
          <div>
            <div className="display-flex">
              {(showSearch || filterOptions) && (
                <div className="br-searchable-table-filter-show-search-container">
                  {filterOptions && filterOptions()}
                  {showSearch && (
                    <Input.Search
                      className={classnames(
                        'br-searchable-component__header__search-input',
                        { 'ant-input-group-addon-hide': !searchPrefix }
                      )}
                      placeholder={
                        searchInputPlaceholder
                          ? searchInputPlaceholder
                          : intl.formatMessage({
                              id: 'searchable_table.input.placeholder'
                            })
                      }
                      disabled={isLoading}
                      prefix={<SearchOutlined />}
                      allowClear
                      onSearch={this.handleInputSearch}
                    />
                  )}
                </div>
              )}
              {businessess?.length && (
                <Select
                  placeholder="Select Business"
                  style={{ width: 300, marginRight: 16 }}
                  onChange={this.handleChange}
                  allowClear
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                  showSearch
                  defaultValue={businessId || null}
                >
                  {businessess?.map((business) => (
                    <Select.Option value={business._id} key={business._id}>
                      {business.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
              {showDateFilter && (
                <>
                  <DatePicker.RangePicker
                    onChange={this.onChangeDatePicker}
                    format={['DD-MM-YYYY', 'DD-MM-YYYY']}
                  />
                </>
              )}
              {showFilter && filterComponents(this.handleFilterSearch)}

              {exportListFileFunction && (
                <Button
                  className="br-searchable-component__header__export"
                  onClick={this.handleExport}
                  disabled={disableExport && (isLoading || !listingData.length)}
                >
                  {showExportIcon && <Icon id="1" component={ExportIcon} />}
                  {intl.formatMessage({
                    id: 'common.export'
                  })}
                </Button>
              )}
            </div>
          </div>
        </div>
        {isSearchResult && !isLoading && !listingData.length ? (
          !emptyTableView ? (
            <div className="br-searchable-component__empty-search-view">
              <img src={EmptySearchView} />
              <p className="br-searchable-component__empty-search-view__title">
                {intl.formatMessage({
                  id: 'searchable_table.empty_view.search_title'
                })}
              </p>
            </div>
          ) : (
            emptyTableView
          )
        ) : !isSearchResult && !isLoading && !listingData?.length ? (
          <div className="br-searchable-component__empty">
            <Icon
              component={emptyTable}
              className="br-searchable-component__empty__icon"
            />
            <p className="br-searchable-component__empty__title">
              {intl.formatMessage({
                id: 'live_ops.empty_view_table_title'
              })}
            </p>
            <span className="br-searchable-component__empty__sub-title">
              {emptyViewSubtitle}
            </span>
          </div>
        ) : (
          <Table
            {...props}
            scroll={{ x: scroll || 'max-content', y: scrollY }}
            rowKey="_id"
            className="br-searchable-table__listing"
            loading={isLoading}
            onChange={this.handleTableChange}
            rowSelection={!withOutCheckBoxes && { ...rowSelection }}
            onRow={(record) => {
              return {
                onClick: (event) => {
                  const selection = window.getSelection();
                  if (selection.toString().length !== 0) {
                    // selection
                    event.stopPropagation();
                  } else if (event.detail === 2) {
                    // double click
                    event.stopPropagation();
                  } else {
                    // normal single click
                    onRowClick
                      ? onRowClick(record)
                      : history.push(`${url}/${record._id}/edit`);
                  }
                }
              };
            }}
            columns={customColumns ? customColumns : formattedColumns}
            dataSource={listingData}
            pagination={
              !hidePagination
                ? {
                    position: paginationPostion || ['bottomCenter'],
                    total: count,
                    current: pageId,
                    pageSize: pageSize,
                    showSizeChanger: pageSizeOptions?.length,
                    pageSizeOptions: pageSizeOptions
                  }
                : false
            }
          />
        )}
      </div>
    );
  }
}

export default injectIntl(withRouter(BRSearchableTable));
