import { getCurrentUserCountry } from '../countries-mapping';

const PHONE_PLACEHOLDER_MAPPER = {
  QA: '+97428380369',
  SA: '+966503344556',
  EG: '01234567890',
  AE: '+971555186536'
};

const STREET_PLACEHOLDER_MAPPER = {
  QA: 'Al Corniche Street',
  SA: 'King Abdulaziz Street',
  EG: 'ex: Mosaddak street',
  AE: 'Sheikh Zayed Road'
};

const LOCATION_NAME_MAPPER = {
  QA: 'Head office',
  SA: 'Qabyan, As Sulay, Riyadh 14266',
  EG: 'Maadi Head Office',
  AE: 'Head office'
};

export const PHONE_NUMBER_PLACEHOLDER =
  PHONE_PLACEHOLDER_MAPPER[getCurrentUserCountry()?.codeName];

export const STREET_NAME_PLACEHOLDER =
  STREET_PLACEHOLDER_MAPPER[getCurrentUserCountry()?.codeName];

export const LOCATION_NAME_PLACEHOLDER =
  LOCATION_NAME_MAPPER[getCurrentUserCountry()?.codeName];
