import React, { useState, useEffect, useCallback, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Input, InputNumber, Select, Button, Checkbox } from 'antd';

import { openModal } from 'utils/modal';
import { updateStarsAllownces } from 'services/stars';
import { getAllZonesGroupedByHub } from 'services/stars';
import { getCurrency } from 'common/countries/countries-mapping';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRContentHeader from 'components/BRContentHeader/BRContentHeader';
import BRConfirmationModal from 'components/BRConfirmationModal/BRConfirmationModal';
import { notify } from 'components/Notify/Notify';

import './StarAllowances.less';

let originalZones = {};

const StarAllownces = ({ intl }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [allZones, setAllZones] = useState([]);
  const [selectedZones, setSelectedZones] = useState([]);
  const [selectedHub, setSelectedHub] = useState([]);

  const formRef = useRef('');

  const userCountry = JSON.parse(localStorage.getItem('userInfo'))?.country
    ?.code;

  const getAllZones = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await getAllZonesGroupedByHub();
      setAllZones(data);
      originalZones = data;
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  }, []);
  useEffect(() => {
    getAllZones();
  }, []);

  const onChangeSearchInput = ({ target: { value } }) => {
    // looking if other options with same label are matching inputValue
    let searchByzone = [];
    let searchedZones = [];
    if (value) {
      value = value?.toLowerCase();
      originalZones.filter(({ zones, hubId }) => {
        if (hubId?.toLowerCase().includes(value)) {
          searchByzone.push({ hubId, zones: zones });
        } else {
          searchedZones = zones.filter(({ zoneName }) => {
            zoneName.toLowerCase().includes(value);
          });
          if (searchedZones.length) {
            searchByzone.push({ hubId, zones: searchedZones });
          }
        }
      });
      setAllZones([...searchByzone]);
    } else {
      if (originalZones?.length) {
        setAllZones(originalZones);
      } else {
        getAllZones();
      }
    }
  };

  const onChangeHub = async ({ target: { checked } }, hub) => {
    const checkedHub = allZones.find(({ hubId }) => hubId === hub);
    let newZones = [];
    checkedHub?.zones.forEach(({ zoneName }) => {
      newZones.push(zoneName);
    });
    if (checked) {
      setSelectedHub([...selectedHub, hub]);
      setSelectedZones([...selectedZones, ...newZones]);
      formRef.current.setFieldsValue({
        zones: [...selectedZones, ...newZones]
      });
    } else {
      let myArray = selectedZones.filter((el) => !newZones.includes(el));
      let newSelectedHub = selectedHub.filter((zonesHub) => zonesHub !== hub);
      formRef.current.setFieldsValue({
        zones: myArray
      });
      setSelectedHub(newSelectedHub);
      setSelectedZones(myArray);
    }
  };

  const hubsZonesChildren = () => {
    const children = [];
    allZones.map(({ hubId, zones }) => {
      children.push(
        <Select.OptGroup
          label={
            <Checkbox
              checked={selectedHub.indexOf(hubId) >= 0}
              onChange={(e) => onChangeHub(e, hubId)}
            >
              <span className="br-star_allowances__hub-label"> {hubId}</span>
            </Checkbox>
          }
        >
          {zones?.map((zone) => (
            <Select.Option
              className="br-star_allowances__hub-zone"
              value={zone.zoneName}
            >
              <Checkbox
                checked={selectedZones.indexOf(zone.zoneName) >= 0}
              ></Checkbox>
              {zone.zoneName}
            </Select.Option>
          ))}
        </Select.OptGroup>
      );
    });
    return children;
  };

  const handleChangeHubsZones = useCallback(
    (value) => {
      if (!value.length) {
        setSelectedHub([]);
      }
      setSelectedZones(value);
    },
    [selectedZones]
  );

  const inputNumberValidator = (rule, value) => {
    const { min, max } = rule;
    try {
      const valueAsNumber = Number(value);
      if (isNaN(valueAsNumber) || !value) {
        throw new Error(
          intl.formatMessage({
            id: 'settings.star_allowances.amount_validation_message.numeric_values_only'
          })
        );
      }
      if (min != null && valueAsNumber < min)
        throw new Error(
          intl.formatMessage({
            id: 'settings.star_allowances.amount_validation_message.min_amount'
          })
        );
      if (max != null && valueAsNumber > max)
        throw new Error(
          intl.formatMessage({
            id: 'settings.star_allowances.amount_validation_message.max_amount'
          })
        );
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };

  const handleOpenWarningModal = (values) => {
    openModal(BRConfirmationModal, {
      title: intl.formatMessage({
        id: 'settings.star_allowances.warning_modal.title'
      }),
      message: intl.formatMessage({
        id: 'settings.star_allowances.warning_modal.hint'
      }),
      onConfirm: () => {
        handleConfirmUpdateAllownces(values);
      }
    });
  };

  const handleConfirmUpdateAllownces = async (values) => {
    try {
      setIsLoading(true);
      await updateStarsAllownces(values);
      notify(
        intl.formatMessage({
          id: 'settings.star_allowances.confirmation_message'
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  return (
    <LoadingWrapper loading={isLoading}>
      <BRContentHeader
        title={intl.formatMessage({ id: 'settings.star_allowances.title' })}
        subtitle={intl.formatMessage({
          id: 'settings.star_allowances.subtitle'
        })}
        isInternalComponent
      />
      <span className="br-star_allowances-content__title">
        {intl.formatMessage({ id: 'settings.star_allowances.phone_lines' })}
      </span>

      <Form scrollToFirstError onFinish={handleOpenWarningModal} ref={formRef}>
        <div className="br-form-row br-star_allowances-content">
          <Form.Item
            name="phoneAllowance"
            label={intl.formatMessage({
              id: 'settings.star_allowances.form_labels.amount_per_day'
            })}
            rules={[
              {
                required: true,
                min: 0,
                max: 10,
                validator: inputNumberValidator
              }
            ]}
          >
            <Input suffix={getCurrency().localized} />
          </Form.Item>
          <Form.Item
            name="zones"
            label={intl.formatMessage({
              id: 'settings.star_allowances.form_labels.zones'
            })}
            rules={[
              {
                required: !selectedZones?.length,
                message: intl.formatMessage({
                  id: 'settings.star_allowances.zones_validation_message'
                })
              }
            ]}
          >
            <Select
              showSearch
              allowClear
              placeholder="Select"
              optionFilterProp="children"
              showArrow
              value={selectedZones}
              loading={isLoading}
              mode="multiple"
              onChange={handleChangeHubsZones}
              dropdownMatchSelectWidth={false}
              dropdownClassName="br-star_allowances-content__zones"
              dropdownRender={(menu) => (
                <>
                  <div className="br-star_allowances-content__zones__search">
                    <Input
                      placeholder="Search for hub"
                      onPressEnter={onChangeSearchInput}
                      onChange={onChangeSearchInput}
                    />
                  </div>
                  {menu}
                </>
              )}
              filterOption={(input, option) =>
                option?.value?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
              }
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
            >
              {hubsZonesChildren()}
            </Select>
          </Form.Item>
          <Button
            disabled={isLoading}
            type="danger"
            htmlType="submit"
            className="br-star_allowances-content__action"
          >
            {intl.formatMessage({
              id: 'settings.star_allowances.save_changes'
            })}
          </Button>
        </div>
      </Form>
    </LoadingWrapper>
  );
};

export default injectIntl(StarAllownces);
