import EgyptFlag from 'assets/imgRevamp/EgyptFlag.svg';
import SaudiArabiaFlag from 'assets/imgRevamp/SaudiArabiaFlag.svg';
import UAEFlag from 'assets/imgRevamp/uae-flag.svg';
import QatarFlag from 'assets/imgRevamp/qatar-flag.svg';

export const ALL_COUNTRIES = [
  {
    id: '60e4482c7cb7d4bc4849c4d5',
    codeNumber: '+20',
    codeName: 'EG',
    name: 'Egypt',
    flagIcon: EgyptFlag
  },
  {
    id: 'eF-3f9FZr',
    codeNumber: '+966',
    codeName: 'SA',
    name: 'Saudi Arabia',
    flagIcon: SaudiArabiaFlag
  },
  {
    id: '60e4482c7cb7d4bc4849c4d4',
    codeNumber: '+971',
    codeName: 'AE',
    name: 'United Arab Emirate',
    flagIcon: UAEFlag
  },
  {
    id: '10e4482c7cb7d4bc4849c4d7',
    codeNumber: '+974',
    codeName: 'QA',
    name: 'Qatar',
    flagIcon: QatarFlag,
    countryCurrency: 'QAR'
  }
];

export const QATAR_COUNTRY_DATA = {
  id: '10e4482c7cb7d4bc4849c4d7',
  placeholder: '65123456',
  codeNumber: '+974',
  codeNumberArabic: '974+',
  codeName: 'QA',
  code: 'QA',
  displayName: 'Qatar +974',
  display: true,
  name: 'Qatar',
  arabicName: 'قطر'
};

export const OMAN_COUNTRY_DATA = {
  id: '10e4482c7cb7d4bc4849c4dF',
  placeholder: '65123456',
  codeNumber: '+968',
  codeNumberArabic: '968+',
  codeName: 'OM',
  code: 'OM',
  displayName: 'Oman +968',
  display: true,
  name: 'Oman',
  arabicName: 'عمان'
};

export const KUWAIT_COUNTRY_DATA = {
  id: '60e4482c7cb7d4bc4849c4d6',
  placeholder: '65123456',
  codeNumber: '+965',
  codeNumberArabic: '965+',
  codeName: 'KW',
  code: 'KW',
  displayName: 'Kuwait +965',
  display: true,
  name: 'Kuwait',
  arabicName: 'الكويت'
};

export const DEFAULT_COUNTRY = ALL_COUNTRIES[0];

export const COUNTRIES_CODE_NAME = {
  EG: 'EG',
  SA: 'SA',
  AE: 'AE',
  QA: 'QA'
};

export const ALL_COUNTRIES_CODES = [
  COUNTRIES_CODE_NAME.EG,
  COUNTRIES_CODE_NAME.SA,
  COUNTRIES_CODE_NAME.AE,
  COUNTRIES_CODE_NAME.QA
];

export const COUNTRIES_NAME_TO_CODE_MAPPER = {
  'Saudi Arabia': 'SA',
  UAE: 'AE',
  Egypt: 'EG',
  Qatar: 'QA',
  Oman: 'OM',
  Kuwait: 'KW'
};

export const NATIONAL_ID_NUMBER_LENGTH = {
  EG: 14,
  SA: 10,
  AE: 15
};

export const STARS_NATIONAL_ID_NUMBER_REQUIRED = {
  EG: true
};

export const getCountryFullName = (code) => {
  return ALL_COUNTRIES.find((country) => code === country.codeName)?.name;
};
