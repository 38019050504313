import http from 'utils/http';
import { headerDownload } from 'utils';

export const exportXlsx = async (data) => {
  const res = await http.get(`/users/stars/exportXlsx`, data, headerDownload);
  return res;
};

export const getStarInformation = (starId) => {
  return http.get(`/users/stars/${starId}`);
};

export const getAllHubs = () => {
  return http.get(`/hubs`);
};

export const getAllHubZones = (hubId) => {
  return http.get(`/starSalaries/${hubId}`);
};
export const getAllstarSalariesZones = () => {
  return http.get(`/starSalaries`);
};

export const getSalaryConfigration = ({ zoneName, vehicleType }) => {
  return http.get(`/starSalaries/${zoneName}/${vehicleType}`);
};

export const getStarContractors = () => {
  return http.get(`/starContractors`);
};

export const createStar = (payload) => {
  return http.post('/users/stars', payload);
};

export const editStar = (starId, payload) => {
  return http.put(`/users/stars/${starId}`, payload);
};

export const exportStarWallet = (starId, { fromDate, toDate }, payload) => {
  return http.get(
    `/cash/star-wallet/${starId}/${fromDate}/${toDate}`,
    payload,
    headerDownload
  );
};

export const excludeStarFromLiability = (starId, payload) => {
  return http.put(`/users/stars/${starId}/exclude-liability`, payload);
};

export const getStarLiabilities = (starId) => {
  return http.get(`/cash/calculate-star-liabilities/${starId}`);
};

export const changeStarStatus = (starId, payload) => {
  return http.put(`/users/stars/${starId}`, payload);
};

export const fetchStarWallet = (starId, payload) => {
  return http.get(`/users/stars/${starId}/wallet`, payload);
};

export const starCollectCashMoney = (starId, payload) => {
  return http.put(`/users/stars/${starId}/collect-cash-money`, payload);
};

export const collectAllCash = (starId) => {
  return http.put(`/users/stars/${starId}/collect-all-cash-money`);
};

export const addSettlement = (starId, payload) => {
  return http.post(`/users/stars/${starId}/settlement`, payload);
};

export const exportStarCashRecord = (starId, payload) => {
  const { exportDateFrom, exportDateTo } = payload;
  return http.get(
    `/cash/star-transactions/${starId}/${exportDateFrom}/${exportDateTo}`
  );
};

export const exportAllStarsWallet = ({ fromDate, toDate }, payload) => {
  return http.get(`/cash/all-star-wallet/${fromDate}/${toDate}`, payload);
};

export const getAllZonesGroupedByHub = (payload) => {
  return http.get(`/starSalaries`, payload);
};

export const updateStarsAllownces = (payload) => {
  return http.put(`/starSalaries`, payload);
};

export const getStarCycles = (payload) => {
  return http.get(`/starSalaries/cycle-page`, payload);
};

export const getCycleDetails = (payload) => {
  return http.get('/starSalaries/cycle-page/details', payload);
};

export const getStars = (payload) => {
  return http.get(`/users/stars/search`, payload);
};

export const getUsers = (roles) => {
  return http.get(`/users/users-by-role/${roles}`);
};

export const getAllClerks = () => {
  return http.get(`/users/users-by-role/WAREHOUSE_CLERK`);
};

export const getAllHubLeaders = () => {
  return http.get(`/users/users-by-role/HUB_LEADER`);
};

export const exportStarsCycleTable = (payload) => {
  return http.get(`/starSalaries/export/cyclesPage`, payload);
};

export const exportStarsCycleDetailTable = (payload) => {
  return http.get(`/starSalaries/export/cyclesDetailsPage`, payload);
};

export const resetStarLiability = (starId) => {
  return http.put(`/users/stars/${starId}/reset-shortage-rule`);
};

export const unlinkStarPhoneNumber = (starId) => {
  return http.put(`/users/stars/${starId}/unlink-star`);
};

export const removeHubZone = (id) => {
  return http.delete(`/starSalaries/${id}`);
};

export const activateStar = ({ starId, payload }) => {
  return http.post(`/users/stars/activate/${starId}`, payload);
};

export const deactivateStar = ({ starId, payload }) => {
  return http.post(`/users/stars/deactivate/${starId}`, payload);
};

export const getNationalIdUploadLink = (starId, payload) => {
  return http.post(`users/stars/upload/${starId}`, payload);
};

export const getNationalIdViewLink = (starId, payload) => {
  return http.post(`users/stars/retrieve/${starId}`, payload);
};

export const getAllStars = (payload) => {
  return http.get('/users/stars/all', payload);
};
