import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Select, Button, Modal, DatePicker } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { cleanEmptyString } from 'utils/helpers';
import { openModal } from 'utils/modal';
import { disabledDate, UTCWithZeroTime } from 'utils/pickups';
import { fetchPickupLocation, createPickup } from 'services/pickups';

import { fetchBusinessPickupLocations } from 'services/business';
import { notify } from 'components/Notify/Notify';
import CreatePickupLocationFormModal from 'components/Pickups/components/CreatePickupLocationFormModal/CreatePickupLocationFormModal';
import CreateContactPersonFormModal from 'components/Pickups/components/CreateContactPersonFormModal/CreateContactPersonFormModal';

import './RequestPickupModal.less';
class RequestPickupModal extends Component {
  state = {
    pickupLocations: [],
    isLoading: false,
    pickupLocation: null,
    scheduledDate: null,
    contactPerson: {},
    fullContactPersonData: {},
    isNewZoning: true,
    locationId: null,
    cancelUpdate: false
  };

  formRef = React.createRef();

  componentDidMount() {
    this.getPickupLocations();
  }

  getPickupLocations = async () => {
    const { businessId } = this.props;
    try {
      this.setState({ isLoading: true });
      const pickups = await fetchBusinessPickupLocations(businessId);
      this.setState({ pickupLocations: pickups.data, isLoading: false });
    } catch (error) {
      notify(error.message);
    }
  };

  checkPickupLocation = (PickupLocation) => {
    const { businessId } = this.props;
    if (PickupLocation?.isNewZoning) {
      this.setState({ isNewZoning: true });
    } else {
      this.setState({ isNewZoning: false });
      openModal(CreatePickupLocationFormModal, {
        pickupLocation: PickupLocation,
        businessId,
        onSuccess: () => {
          this.setState({ locationId: PickupLocation._id }, () => {
            this.getPickupLocations();
          });
        },
        onCancel: () => {
          this.setState({ cancelUpdate: true });
        }
      });
    }
  };

  getPickupLocation = async (locationId) => {
    const { businessId } = this.props;
    try {
      this.setState({ isLoading: true });
      const { data: pickupLocation } = await fetchPickupLocation(locationId, {
        businessId
      });
      this.checkPickupLocation(pickupLocation);
      this.setState({ pickupLocation });

      const { contacts } = pickupLocation;

      if (contacts.length !== 0) {
        this.formRef.current.setFieldsValue({
          contactPerson: contacts[contacts.length - 1].name
        });

        this.setState({
          fullContactPersonData: cleanEmptyString(
            contacts[contacts?.length - 1]
          )
        });
      }

      this.setState({
        isLoading: false
      });

      return pickupLocation;
    } catch (error) {
      notify(error.message);
    }
  };

  handlePickupLocationChange = async (locationId) => {
    await this.getPickupLocation(locationId);
    this.formRef.current.setFieldsValue({
      locationId,
      contactPerson: this.state.pickupLocation?.contacts[0]?.name,
      scheduledDate: null
    });
  };

  handleOnChangePickupDate = (scheduledDate) => {
    this.setState({ scheduledDate });
  };

  onChangeContactPerson = (contactPerson) => {
    const { pickupLocation } = this.state;
    let newPerson;
    pickupLocation.contacts.map((person) => {
      if (person?.name === contactPerson) {
        newPerson = person.name;
        this.setState({
          contactPerson: newPerson,
          fullContactPersonData: person
        });
      }
    });
  };

  onFinish = async (values) => {
    const { close, onSuccess, intl, businessId } = this.props;
    const { scheduledDate, fullContactPersonData, cancelUpdate } = this.state;
    if (!cancelUpdate) {
      delete fullContactPersonData?.firstName;
      delete fullContactPersonData?.lastName;
      delete fullContactPersonData?.isDefault;
      const payload = {
        businessLocationId: values?.locationId,
        contactPerson: cleanEmptyString(fullContactPersonData),
        scheduledDate: UTCWithZeroTime(scheduledDate),
        businessId,
        creationSrc: 'Admin',
        dashboardSource: 'order creation page'
      };
      try {
        const {
          message: { puid }
        } = await createPickup(payload);
        notify(
          intl.formatMessage({
            id: `shipments.new_order.request_pickup.pickup_created_successfully`
          }),
          'success'
        );
        close();
        onSuccess(puid);
      } catch (error) {
        notify(error.message);
      }
    } else {
      notify(
        intl.formatMessage({
          id: `pickups.create_edit_pickup.error_create_new_zoning`
        })
      );
    }
  };

  handleCreateContactPersonClick = () => {
    const { intl, businessId } = this.props;
    const { pickupLocation } = this.state;
    if (pickupLocation?.canEdit) {
      openModal(CreateContactPersonFormModal, {
        businessId,
        pickupLocation,
        getPickupLocation: this.getPickupLocation
      });
    } else {
      notify(
        intl.formatMessage({
          id: !this.formRef.current.getFieldValue('locationId')
            ? 'settings.pickup_locations.pickup_location_form.add_contact_before_location_error'
            : 'settings.pickup_locations.pickup_location_form.add_contact_error'
        })
      );
    }
  };

  render() {
    const { intl, close, businessId, pickupCutoffTime } = this.props;
    const { pickupLocations, pickupLocation, isLoading } = this.state;
    return (
      <Modal
        {...this.props}
        wrapClassName="br-request-pickup-modal"
        title={null}
        footer={null}
        width={null}
        onCancel={close}
      >
        <>
          <div className="br-request-pickup-modal__title">
            {intl.formatMessage({
              id: `shipments.new_order.request_pickup_modal.title`
            })}
          </div>
        </>
        <Form
          className="br-request-pickup-modal__form"
          onFinish={this.onFinish}
          id="requestPickupForm"
          ref={this.formRef}
        >
          <div className="br-form-row">
            <Form.Item
              name="locationId"
              label={intl.formatMessage({
                id: `shipments.new_order.request_pickup_modal.form_labels.pickup_location`
              })}
              rules={[{ required: true }]}
            >
              <Select
                className="br-pickups__pickup-settings__form__selector"
                placeholder={intl.formatMessage({
                  id: `pickups.create_edit_pickup.form_placeholders.select_location`
                })}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={this.handlePickupLocationChange}
                getPopupContainer={(trigger) => trigger.parentElement}
                autoComplete={`${Math.random()}`}
              >
                {pickupLocations.map((location) => (
                  <Select.Option key={location._id} value={location._id}>
                    {location.locationName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="br-request-pickup-modal__create-pickup-location">
            <Button
              onClick={() => {
                openModal(CreatePickupLocationFormModal, {
                  businessId,
                  onSuccess: (pickupLocationId) => {
                    this.getPickupLocations();
                    this.handlePickupLocationChange(pickupLocationId);
                  }
                });
              }}
              className="br-add-button"
            >
              <PlusOutlined />
              {intl.formatMessage({
                id: `shipments.new_order.request_pickup_modal.actions.create_new_location`
              })}
            </Button>
          </div>
          <div className="br-form-row">
            <Form.Item
              name="contactPerson"
              label={intl.formatMessage({
                id: `shipments.new_order.request_pickup_modal.form_labels.contact_person`
              })}
              rules={[{ required: true }]}
            >
              <Select
                onChange={this.onChangeContactPerson}
                placeholder={intl.formatMessage({
                  id: `shipments.new_order.request_pickup_modal.select`
                })}
                autoComplete={`${Math.random()}`}
                getPopupContainer={(trigger) => trigger.parentElement}
              >
                {pickupLocation?.contacts.length &&
                  pickupLocation.contacts.map((contact) => (
                    <Select.Option key={contact.name} value={contact.name}>
                      {contact.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
          <div className="br-request-pickup-modal__create-contact">
            <Button
              onClick={this.handleCreateContactPersonClick}
              className="br-add-button"
            >
              <PlusOutlined />
              {intl.formatMessage({
                id: `shipments.new_order.request_pickup_modal.actions.create_new_contact`
              })}
            </Button>
          </div>
          <div className="br-form-row">
            <Form.Item
              name="scheduledDate"
              label={intl.formatMessage({
                id: `shipments.new_order.request_pickup_modal.form_labels.pickup_date`
              })}
              rules={[{ required: true }]}
            >
              <DatePicker
                placeholder={intl.formatMessage({
                  id: `shipments.new_order.request_pickup_modal.select`
                })}
                onChange={this.handleOnChangePickupDate}
                disabledDate={(current) =>
                  disabledDate(current, new Date(), pickupCutoffTime)
                }
                getPopupContainer={(trigger) => trigger.parentElement}
              />
            </Form.Item>
          </div>
          <div className="br-request-pickup-modal__footer">
            <Button onClick={close}>
              {intl.formatMessage({
                id: 'common.cancel'
              })}
            </Button>
            <Button
              disabled={isLoading}
              form="requestPickupForm"
              type="primary"
              htmlType="submit"
            >
              {intl.formatMessage({
                id: `shipments.new_order.request_pickup_modal.actions.schedule_pickup`
              })}
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
}

export default injectIntl(RequestPickupModal);
