import * as yup from 'yup';
import { get as getValue } from 'lodash';
import { fmt } from 'IntlWrapper/IntlWrapper';
import { phoneRegExp } from '../../utils';
import { isDefaultBostaCourier } from 'utils/helpers';
import aclMatrix from 'common/aclMatrix';

/**
 * deliveries columns used in table data binding
 * @type {({dataField: string, text: string}|{dataField: string, text: string}|{dataField: string, text: string}|{dataField: string, text: string}|{dataField: string, text: string})[]}
 */
export const deliveriesTableColumns = [
  { dataField: 'business', text: 'Business', fixed: 'left' },
  {
    dataField: 'trackingNumber',
    text: 'Tracking Number',
    fixed: 'left'
  },
  { dataField: 'type', text: 'Type' },
  { dataField: 'createdAt', text: 'Created At' },
  { dataField: 'state', text: 'State' },
  { dataField: 'hub', text: 'Hub' },
  { dataField: 'locationInHub', text: 'Location In Hub' },
  { dataField: 'assignedHub', text: 'Assigned Hub' },
  { dataField: 'warehouseTransit', text: 'In Transit' },
  { dataField: 'confirmedDeliveryAt', text: 'Confirmation Date' },
  { dataField: 'pickedUpAt', text: 'Picked Up At' },
  { dataField: 'scheduledAt', text: 'Scheduled At' },
  { dataField: 'merchantName', text: 'Merchant Name' },
  { dataField: 'e2eSLADate', text: 'E2E SLA Date' },
  { dataField: 'orderSLADate', text: 'Delivery Promise SLA' },
  { dataField: 'linkedDelivery', text: 'Linked Delivery' },
  { dataField: 'updatedAt', text: 'Last Updated At' },
  { dataField: 'receiverDetails', text: 'Receiver Details' },
  { dataField: 'pickupAddress', text: 'Pickup Address' },
  { dataField: 'dropOffAddress', text: 'Dropoff Address' },
  { dataField: 'cod', text: 'COD' },
  // { dataField: "cancelledAt", text: "Cancelled At" },
  { dataField: 'starInfo', text: 'Star Info' },
  // { dataField: "holderInfo", text: "Holder Info" },
  { dataField: 'priceBeforeVat', text: 'Price Before Vat' },
  { dataField: 'priceAfterVat', text: 'Price After Vat' },

  { dataField: 'businessRefNumber', text: 'Business Ref. Number' },
  { dataField: 'notes', text: 'Notes' },
  // { dataField: "comments", text: "Comments" },
  // { dataField: 'numberOfAttempts', text: 'Number of Attempts' },
  { dataField: 'rejectedReturnTicket', text: 'Ticket ID' },
  { dataField: 'action', text: 'Actions' }
];

export const deliveriesColumnsMapped = deliveriesTableColumns.map((item) => ({
  dataIndex: item.dataField,
  key: item.dataField,
  title: item.text,
  fixed: item.fixed,
  width: item.width
}));

/**
 * transition state lookup
 * @type {({label: string, value: string}|{label: string, value: string})[]}
 */
export const transitionState = [
  { value: 'false', label: 'No' },
  { value: 'true', label: 'Yes' }
];

/**
 * Located in hub
 * @type {({label: string, value: string}|{label: string, value: string})[]}
 */
export const locatedInHubState = [
  { value: 'false', label: 'No' },
  { value: 'true', label: 'Yes' }
];

/**
 * status filter lookup
 * @type {({text: string, key: string}|{text: string, key: string}|{text: string, key: string}|{text: string, key: string})[]}
 */
export const statusFilters = [
  { key: 'delivered', text: 'Delivered' },
  { key: 'ongoing', text: 'On Going' },
  { key: 'canceled', text: 'Canceled' },
  { key: 'exception', text: 'Exception' }
];

/**
 * search validation schema with yup
 */
export const formValidationSchema = yup.object({
  business: yup.string().notRequired(),
  hub: yup.string().notRequired(),
  exceptionBy: yup.string().notRequired(),
  inTransit: yup.string().notRequired(),
  city: yup.string().notRequired(),
  pickupZone: yup.string().notRequired(),
  pickupDistrict: yup.string().notRequired(),
  dropOffZone: yup.string().notRequired(),
  dropOffDistrict: yup.string().notRequired(),
  expectationBy: yup.string().notRequired(),
  expectationType: yup.string().notRequired(),
  deliveryType: yup.string().notRequired(),
  star: yup.string().notRequired(),
  trackingNumbers: yup.string().notRequired(),
  numberOfAttempts: yup.string().notRequired(),
  businessRefNumber: yup.string().notRequired(),
  customerFirstName: yup.string().notRequired(),
  customerLastName: yup.string().notRequired(),
  customerPhone: yup.string().notRequired(),
  Note: yup.string().notRequired(),
  createdAtStart: yup.string().notRequired(),
  createdAtEnd: yup.string().notRequired(),
  pickedUpAtStart: yup.string().notRequired(),
  pickedUpAtEnd: yup.string().notRequired(),
  exceptionAtStart: yup.string().notRequired(),
  exceptionAtEnd: yup.string().notRequired(),
  canceledAtStart: yup.string().notRequired(),
  canceledAtEnd: yup.string().notRequired(),
  deliveredAtStart: yup.string().notRequired(),
  deliveredAtEnd: yup.string().notRequired(),
  confirmedAtStart: yup.string().notRequired(),
  confirmedAtEnd: yup.string().notRequired()
});

/**
 * deliveries state
 * @type {{label: string, value: string}[]}
 */

export const packageSize = [
  { value: 0, label: 'Normal' },
  { value: 1, label: 'White bag' }
];

/**
 * deliveries types
 * @type {({label: string, value: string}|{label: string, value: string}|{label: string, value: string}|{label: string, value: string}|{label: string, value: string})[]}
 */
export const deliveryTypes = [
  { label: 'Deliver', value: 'SEND' },
  { label: 'Cash Collection', value: 'CASH_COLLECTION' },
  { label: 'Return', value: 'Return' },
  { label: 'RTO', value: 'RTO' },
  // { label: 'CRP', value: 'CRP' },
  { label: 'Exchange', value: 'EXCHANGE' },
  { label: 'Customer Return Pick up', value: 'CUSTOMER_RETURN_PICKUP' },
  { label: 'Sign and Return', value: 'SIGN_AND_RETURN' }
];

export const deliveryTypesEnum = {
  SEND: 'SEND',
  CASH_COLLECTION: 'CASH_COLLECTION',
  Return: 'Return',
  RTO: 'RTO',
  EXCHANGE: 'EXCHANGE',
  CUSTOMER_RETURN_PICKUP: 'CUSTOMER_RETURN_PICKUP',
  SIGN_AND_RETURN: 'SIGN_AND_RETURN'
};

// SEND, CASH_COLLECTION, Return, RTO, EXCHANGE, CUSTOMER_RETURN_PICKUP
/**
 * initial values for search form in deliveries search page
 * @type {{dropOffDistrict: null, dropOffZoneIds: null, notes: null, deliveredAtStart: null, businessId: null, pickedUpAtStart: null, type: null, pickupDistrict: null, businessReference: null, pickedUpAtEnd: null, customerFirstNames: null, pickupCityIds: null, expectationBy: null, exceptionBy: null, state: null, customerLastNames: null, canceledAtEnd: null, trackingNumber: null, customerPhones: null, createdAtEnd: null, createdAtStart: null, pickupZoneIds: null, deliveryType: null, stars: null, canceledAtStart: null, hubs: null, exceptionAtStart: null, deliveredAtEnd: null, exceptionAtEnd: null, deliveryState: null, numberOfAttempts: null, inTransitBetweenHubs: null, expectationType: null}}
 */
const hub = JSON.parse(localStorage.getItem('userInfo'))?.warehouseInfo || null;
export const initialFormValues = {
  is_invalid_business: null,
  id: null,
  businessId: null,
  hub: null,
  numberOfAttempts: null,
  businessReference: null,
  customersFirstNames: null,
  customersLastNames: null,
  mobilePhones: null,
  notes: null,
  createdAtStart: null,
  createdAtEnd: null,
  pickedUpAtStart: null,
  pickedUpAtEnd: null,
  exceptionAtStart: null,
  exceptionAtEnd: null,
  canceledAtStart: null,
  canceledAtEnd: null,
  deliveredAtStart: null,
  deliveredAtEnd: null,
  damagedAtStart: null,
  damagedAtEnd: null,
  lostAtStart: null,
  lostAtEnd: null,
  ofdAtStart: null,
  ofdAtEnd: null,
  confirmedAtStart: null,
  confirmedAtEnd: null,
  exceptionBy: null,
  type: null,
  state: null,
  packageType: null,
  shelfNames: null,
  locatedInHub: null,
  investigationAtStart: null,
  investigationAtEnd: null,
  scheduledAtStart: null,
  scheduledAtEnd: null,
  onHoldAtStart: null,
  onHoldAtEnd: null,
  withinSLA: null,
  exceededSLA: null,
  fakeAttempt: null,
  onHold: null,
  slaAtStart: null,
  slaAtEnd: null,
  deliverySlaAtStart: null,
  deliverySlaAtEnd: null,
  withinDeliverySLA: null,
  exceededDeliverySLA: null,
  collectedAtStart: null,
  collectedAtEnd: null,
  OFDByStar: null,
  inProgress: null,
  assignedHub: null,
  callsNumber: null,
  smsNumber: null,
  isFulfilled: null
};

export const CRP_TYPE = 'CUSTOMER_RETURN_PICKUP';
export const tablePageSize = 50;

/**
 * form validation schema
 * @type {{dropOffDistrict: *, notes, business: *, type: *, pickupDistrict: *, businessReference: *, dropOffZone: *, customerPhone, pickupCity: *, pickupZone: *, customerFirstName, customerEmail, dropOffCity: *, customerLastName, businessAddress: *}}
 */
export const validationSchema = {
  dropOffCity: yup.object().required('City Required'),
  dropOffZone: yup.object().nullable().required('Zone Required'),
  dropOffDistrict: yup.string().required('District Required'),
  pickupCity: yup.object().notRequired('City Required'),
  pickupZone: yup.object().notRequired('Zone Required'),
  pickupDistrict: yup.string().notRequired('District Required'),
  business: yup.object().required('Business Required'),
  businessAddress: yup.string().notRequired(),
  customerFirstName: yup.string().required('First Name Required'),
  customerLastName: yup.string().required('Last Name Required'),
  customerPhone: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid eg: 01234567890')
    .required('Customer Phone Number Required'),
  customerEmail: yup.string().email('Invalid Email Format').notRequired(),
  // state: yup.string().required(),
  type: yup.string().required('Delivery Type Required'),
  notes: yup.string().notRequired(),
  // deliveryTime: yup.string().required(),
  businessReference: yup.string().notRequired(),
  dropOffLine1: yup.string().required('Line 1 Required'),
  dropOffLine2: yup.string().notRequired(),
  dropOffApartment: yup.string().notRequired(),
  dropOffFloor: yup.string().notRequired(),
  cod: yup.number().notRequired()
};
// start : for uplodae xlsx
export const formatOptions = (keyFieldName, labelFieldName, data) => {
  const labelFields = labelFieldName.split(',');
  let mappedData = data;
  if (!Array.isArray(mappedData)) {
    mappedData = Array.from(Object.keys(data), (k) => data[k]);
  }
  return mappedData.map((item) => {
    const labelValue = labelFields.reduce((acc, value) => {
      if (!value) return acc;
      return `${acc} ${getValue(item, value, '')}`;
    }, '');
    return { value: item[keyFieldName], label: labelValue };
  });
};

export const returnColumsMapped = (nameOfColums) => {
  return nameOfColums.map((element) => {
    return {
      dataIndex: element.replace(/-/g, '').split(' ').join(''),
      title: element,
      fixed: 'left',
      width: 150
    };
  });
};

export const formatData = (data) => {
  return data.map((item) => {
    return {
      key: data.indexOf(item),
      CustomerFirstName: item.receiver.firstName,
      CustomerLastName: item.receiver.lastName,
      CustomerPhone: item.receiver.phone,
      SecondPhone: item.receiver.secondPhone,
      OrderType: item.type,
      City: item.dropOffAddress.cityCode,
      Zone: item.dropOffAddress.zone,
      District: item.dropOffAddress.district,
      StreetName: item.dropOffAddress.firstLine,
      Landmarks: item.dropOffAddress.secondLine,
      BuildingNo: item.dropOffAddress.buildingNumber,
      FloorNo: item.dropOffAddress.floor,
      ApartmentNo: item.dropOffAddress.apartment,
      CashAmount: item.codAmount,
      WorkAddress: item.dropOffAddress.isWorkAddress,
      NoofItems: item.itemsCount,
      PackageDescription: item.description,
      DeliveryNotes: item.notes,
      ExchangeCashtoCollect: item.exchange_cash_collect,
      ExchangeRefund: item.exchange_refund,
      ReturnNotes: item.returnNotes,
      ExchangeReturnNoofItems: item.returnItemsCount,
      ExchangeReturnPackageDescription: item.returnDescription,
      OrderReference: item.businessReference,
      AllowOpeningPackage: item.allowToOpenPackage
    };
  });
};

export const schemaDelivery = yup.array().of(
  yup.object().shape({
    type: yup
      .string()
      .label(
        fmt({
          id: 'shipments.upload_orders.table_fields.order_type'
        })
      )
      .required(
        fmt({
          id: 'form.required'
        })
      ),
    sender: yup.object().shape({
      type: yup.string().notRequired(),
      _id: yup.string().notRequired(),
      name: yup.string().notRequired()
    }),
    pickupAddress: yup.object().shape({
      city: yup.object().shape({
        _id: yup.string().notRequired(),
        name: yup.string().notRequired()
      }),
      zone: yup.string().notRequired(),
      district: yup.string().notRequired(),
      secondLine: yup.string().notRequired(),
      firstLine: yup
        .string()
        .label(
          fmt({
            id: 'location_form_labels.street'
          })
        )
        .required(
          fmt({
            id: 'form.required'
          })
        ),
      floor: yup.string().notRequired(),
      apartment: yup.string().notRequired(),
      buildingNumber: yup.string().notRequired(),
      isWorkAddress: yup.string().notRequired()
    }),
    receiver: yup.object().shape({
      firstName: yup
        .string()
        .label(
          fmt({
            id: 'contact_form_labels.first_name'
          })
        )
        .required(
          fmt({
            id: 'form.required'
          })
        ),
      lastName: yup
        .string()
        .label(
          fmt({
            id: 'contact_form_labels.last_name'
          })
        )
        .required(
          fmt({
            id: 'form.required'
          })
        ),

      phone: yup
        .string()
        .max(11, 'Phone is cannot exceed 11 digits!')
        .matches(
          /((^|, )([0-9]{0,10}$|01[0-2|5]{1}[0-9]{8}$))+$/,
          'Enter valid Number!'
        )
        .required('Customer phone number is required'),
      secondPhone: yup
        .string()
        .max(11, 'Second phone cannot exceed 11 digits!')
        .matches(
          /((^|, )([0-9]{0,10}$|01[0-2|5]{1}[0-9]{8}$))+$/,
          'Enter valid Number!'
        )
        .notRequired()
    }),
    dropOffAddress: yup.object().shape({
      secondLine: yup
        .string()
        .notRequired()
        .label(
          fmt({
            id: 'location_form_labels.landmarks'
          })
        )
        .max(
          100,
          fmt({
            id: 'form.maxLen'
          })
        ),
      district: yup.string().notRequired(),
      firstLine: yup
        .string()
        .label(
          fmt({
            id: 'location_form_labels.street'
          })
        )
        .max(
          150,
          fmt({
            id: 'form.maxLen'
          })
        )
        .required(
          fmt({
            id: 'form.required'
          })
        ),
      cityCode: yup
        .string()
        .label(
          fmt({
            id: 'location_form_labels.city'
          })
        )
        .required(
          fmt({
            id: 'form.required'
          })
        ),
      zone: yup
        .string()
        .label(
          fmt({
            id: 'location_form_labels.zone'
          })
        )
        .required(
          fmt({
            id: 'form.required'
          })
        ),
      floor: yup
        .string()
        .label(
          fmt({
            id: 'location_form_labels.floor'
          })
        )
        .notRequired(),
      apartment: yup
        .string()
        .label(
          fmt({
            id: 'location_form_labels.apartment'
          })
        )
        .notRequired(),
      buildingNumber: yup
        .string()
        .label(
          fmt({
            id: 'location_form_labels.building'
          })
        )
        .notRequired(),
      isWorkAddress: yup.string().notRequired()
    }),
    businessReference: yup.string().notRequired(),
    notes: yup.string().notRequired(),
    returnNotes: yup.string().notRequired(),
    codAmount: yup.string().notRequired(),
    description: yup
      .string()
      .label(
        fmt({
          id: 'shipments.upload_orders.table_fields.description'
        })
      )
      .max(
        200,
        fmt({
          id: 'form.maxLen'
        })
      )
      .when('type', {
        is: (val) => val !== 'Cash collection',
        then: (fieldSchema) =>
          fieldSchema.required(
            fmt({
              id: 'form.required'
            })
          )
      }),
    itemsCount: yup
      .string()
      .label(
        fmt({
          id: 'shipments.upload_orders.table_fields.number_of_items'
        })
      )
      .max(
        4,
        fmt({
          id: 'form.maxLen'
        })
      )
      .when('type', {
        is: (val) => val !== 'Cash collection',
        then: (fieldSchema) =>
          fieldSchema.required(
            fmt({
              id: 'form.required'
            })
          )
      }),
    returnDescription: yup
      .string()
      .label(
        fmt({
          id: 'shipments.upload_orders.table_fields.return_description'
        })
      )
      .max(
        200,
        fmt({
          id: 'form.maxLen'
        })
      )
      .when('type', {
        is: (val) => val === 'Exchange',
        then: (fieldSchema) =>
          fieldSchema.required(
            fmt({
              id: 'form.required'
            })
          )
      }),
    returnItemsCount: yup
      .string()
      .label(
        fmt({
          id: 'shipments.upload_orders.table_fields.return_number_of_items'
        })
      )
      .max(
        4,
        fmt({
          id: 'form.maxLen'
        })
      )
      .when('type', {
        is: (val) => val === 'Exchange',
        then: (fieldSchema) =>
          fieldSchema.required(
            fmt({
              id: 'form.required'
            })
          )
      }),
    allowToOpenPackage: yup.string().notRequired(),
    exchange_cash_collect: yup.string().notRequired(),
    exchange_refund: yup.string().notRequired()
  })
);

export const DELIVERY_TYPES_UPLOAD_CSV = {
  Deliver: 'SEND',
  'Cash collection': 'CASH_COLLECTION',
  Exchange: 'EXCHANGE',
  Return: 'CUSTOMER_RETURN_PICKUP'
};

export const DELIVERY_TYPES_CODES = {
  SEND: 10,
  CASH_COLLECTION: 15,
  CUSTOMER_RETURN_PICKUP: 25,
  EXCHANGE: 30
};
