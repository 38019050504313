import React, { useState, useEffect } from 'react';
import { Button, Input } from 'antd';

import { editOrder } from 'services/shipments';
import { formatDate, formatRole } from 'utils/shipmentDetails';
import { isUserAuthorized } from 'utils/helpers';
import aclMatrix from 'common/aclMatrix';

import { notify } from 'components/Notify/Notify';

import { ReactComponent as NotesEmptyStateIcon } from 'assets/imgRevamp/NewNotesEmptyStateIcon.svg';

import './Notes.less';

const Note = ({ key, content, takenBy, date, role }) => {
  return (
    <div className="br-notes__container" key={key}>
      <span className="br-notes__note-title">{content}</span>
      <span className="br-notes__note-subtitle">
        <span>{formatDate(date, true)}</span>
        <span>
          By: {takenBy} {formatRole(role)}
        </span>
      </span>
    </div>
  );
};

const Notes = ({ notes, delivery, setIsLoading }) => {
  const { TextArea } = Input;
  const [noteContent, setNoteContent] = useState('');
  const [deliveryNotes, setDeliveryNotes] = useState([]);

  useEffect(() => {
    setDeliveryNotes(notes.reverse());
  }, [delivery]);
  const handleOnAddNoteClick = async () => {
    setIsLoading(true);
    try {
      if (noteContent) {
        const user = JSON.parse(localStorage.getItem('userInfo'));

        await editOrder({
          id: delivery._id,
          updateData: noteContent,
          isNotes: true
        });
        setDeliveryNotes([
          {
            note: noteContent,
            userName: `${user.profile.firstName} ${
              user.profile.lastName || ''
            }`,
            time: new Date(),
            userRole: user.roles[0]
          },
          ...deliveryNotes
        ]);
        setNoteContent('');
      }
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  return (
    <div className="br-delivery-notes__contatiner">
      {deliveryNotes.length ? (
        <div className="br-note__content-container">
          {deliveryNotes.map((note, index) => (
            <Note
              key={index}
              content={note.note}
              takenBy={note.userName}
              date={note.time}
              role={note.userRole}
            />
          ))}
        </div>
      ) : (
        <div className="br-delivery-notes__empty-state">
          <NotesEmptyStateIcon />
          <span>The delivery has no comments yet</span>
          <span className="br-delivery-notes__empty-state-subtitle">
            Add your comment to view
          </span>
        </div>
      )}
      <div className="br-delivery-notes__add-note-section">
        <TextArea
          rows={3}
          autoSize={{ minRows: 2.2, maxRows: 2.2 }}
          placeholder="Write your comment here..."
          value={noteContent}
          onChange={(e) => setNoteContent(e.target.value)}
          className={noteContent ? 'br-notes-green-textarea' : ''}
        />
        <Button
          className={noteContent ? 'br-notes-green-button' : ''}
          onClick={handleOnAddNoteClick}
          disabled={!isUserAuthorized(aclMatrix.DELIVERY_NOTES)}
        >
          Add Note
        </Button>
      </div>
    </div>
  );
};

export default Notes;
