import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { Modal, Divider, Collapse } from 'antd';
import Icon from '@ant-design/icons';
import classnames from 'classnames';

import BRButton from 'components/BRButton/BRButton';
import MultiPackagesCount from '../MultiPackagesCount/MultiPackagesCount';

import { ReactComponent as Alert } from 'assets/bosta-icons/alert-triangle.svg';
import { ReactComponent as ChevronDown } from 'assets/bosta-icons/Chevron-down.svg';

import './MissingMultiPackagesModal.less';

const MissingMultiPackagesModal = ({ close, deliveries = [], ...props }) => {
  const [missingScanDeliveries, setMissingScanDeliveries] = useState({
    missingPackagesCount: 0,
    missingDeliveries: []
  });
  const [activeKeys, setActiveKeys] = useState([]);

  const intl = useIntl();

  const getMissingScanDeliveries = () => {
    const result = deliveries.reduce(
      (acc, delivery) => {
        const { multiPackages, scannedPackages = [] } = delivery;

        if (multiPackages !== scannedPackages.length) {
          acc.missingPackagesCount += multiPackages - scannedPackages.length;
          acc.missingDeliveries.push(delivery);
        }

        return acc;
      },
      { missingPackagesCount: 0, missingDeliveries: [] }
    );

    setMissingScanDeliveries(result);
  };

  useEffect(() => {
    if (deliveries.length) {
      getMissingScanDeliveries();
    }
  }, [deliveries]);

  const renderMultiPackagesItems = (delivery) => {
    const { multiPackages, trackingNumber, scannedPackages = [] } = delivery;

    const packagesNumbers = Array.from(
      { length: multiPackages },
      (_, index) => index + 1
    );

    return packagesNumbers.map((packageNumber) => {
      const isScanned = scannedPackages.includes(packageNumber);

      return (
        <div
          className="br-missing-multi-packages__item"
          key={`${trackingNumber}/${packageNumber}`}
        >
          <span className="body-medium">{`${trackingNumber}/${packageNumber}`}</span>
          <span
            className={classnames(
              'br-missing-multi-packages__item-scan-status',
              {
                'br-missing-multi-packages__item-scanned': isScanned
              }
            )}
          >
            {intl.formatMessage({
              id: `hubs.receive_new_pickup.${
                isScanned ? 'scanned' : 'not_scanned'
              }`
            })}
          </span>
        </div>
      );
    });
  };

  const renderMultiPackageItemHeader = (delivery) => {
    const isItemActive = activeKeys.includes(delivery.trackingNumber);
    return (
      <div className="br-missing-multi-packages-content__row content">
        <span className="br-missing-multi-packages__item-collapse__tracking-number body-medium">
          {delivery.trackingNumber}
          <Icon
            component={ChevronDown}
            className={classnames(
              'br-missing-multi-packages__item-collapse-icon ant-icon-md',
              {
                'br-missing-multi-packages__item-collapse-icon__active':
                  isItemActive
              }
            )}
          />
        </span>
        <MultiPackagesCount delivery={delivery} />
      </div>
    );
  };

  return (
    <Modal onCancel={close} footer={null} title={null} {...props}>
      <div className="br-missing-multi-packages__container">
        <div className="br-missing-multi-packages__header">
          <div className="br-missing-multi-packages__icon__container">
            <Icon
              component={Alert}
              className="br-missing-multi-packages__icon ant-icon-lg"
            />
          </div>
          <div className="br-missing-multi-packages__title display-xs">
            {intl.formatMessage(
              { id: 'missing_multi_package_modal.title' },
              {
                span: (children) => <span>{children}</span>,
                missingPackages: missingScanDeliveries.missingPackagesCount,
                multiPackages: missingScanDeliveries.missingDeliveries.length
              }
            )}
          </div>
        </div>
        <div className="br-missing-multi-packages__content">
          <div className="br-missing-multi-packages-content__row header">
            <span>
              {intl.formatMessage({
                id: 'missing_multi_package_modal.multi_package_orders'
              })}
            </span>
            <span>
              {intl.formatMessage({
                id: 'missing_multi_package_modal.scanned'
              })}
            </span>
          </div>
          <Divider />
          <div className="br-missing-multi-packages__content-items">
            <Collapse bordered={false} onChange={setActiveKeys}>
              {missingScanDeliveries.missingDeliveries.map((delivery) => {
                return (
                  <Collapse.Panel
                    key={delivery.trackingNumber}
                    showArrow={false}
                    header={renderMultiPackageItemHeader(delivery)}
                  >
                    {renderMultiPackagesItems(delivery)}
                  </Collapse.Panel>
                );
              })}
            </Collapse>
          </div>
        </div>
        <div className="br-missing-multi-packages__actions">
          <BRButton
            type="primary"
            label={intl.formatMessage({
              id: 'missing_multi_package_modal.back_to_scanning'
            })}
            onClick={close}
            block
          />
        </div>
      </div>
    </Modal>
  );
};

export default MissingMultiPackagesModal;
