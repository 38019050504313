import http from 'utils/http';
import { currentUserCountry } from 'common/countries/countries-mapping';

export const getBusiness = (id) => {
  return http.get(`/businesses/${id}/profile`);
};

export const switchCountry = (countryCode) => {
  return http.get(`/users/switch-country/${countryCode}`);
};

export const getActiveSessions = () => {
  return http.get('/users/list-active-sessions');
};

export const expireUserSession = (payload) => {
  return http.post('/users/expire-user-sessions', payload);
};

export const getCountryTimeZone = () => {
  return http.get(`/cities/country/time-zone/${currentUserCountry.id}`);
};
