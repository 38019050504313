export const sendSegment = (eventName, params, State = 'Success') => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const dataToSend = {
    Email: userInfo?.emails[0]?.address,
    'Admin Name': `${userInfo?.profile?.firstName} ${userInfo?.profile?.lastName}`,
    Source: 'Admin',
    State,
    ...params
  };
  window.analytics && window.analytics.track(eventName, dataToSend);
};
