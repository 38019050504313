import { injectIntl } from 'react-intl';
import { Row, Col, Input, Select, Button } from 'antd';
import Icon, { SearchOutlined } from '@ant-design/icons';

import { exportXlsx } from 'services/deliveries';
import { RTO_DELIVERIES_REPORT, BUSINESS, SHELVES } from 'constants/hubs';
import { sendSegment } from 'utils/segment';

import { notify } from 'components/Notify/Notify';

import { ReactComponent as DocumentDownload } from 'assets/imgRevamp/document-download.svg';

import './DispatchRTO.less';

const { Option } = Select;

const DispatchRTOHeader = ({
  intl,
  isLoading,
  setIsLoading,
  groupedBy,
  rtoList,
  hubs,
  rtoCount,
  selectedHub,
  setSelectedHub,
  currentUserHubId,
  setRTOSearchResults,
  selectedTrackingNumbers,
  setGroupedBy,
  handleClearSelectedHub
}) => {
  const renderedHubsOptions = hubs?.map(({ name, _id }) => (
    <Option key={_id} value={_id}>
      {name}
    </Option>
  ));

  const groupedByOptions = [
    {
      label: intl.formatMessage({
        id: `hubs.dispatch_rto.group_by.business`
      }),
      value: BUSINESS
    },
    {
      label: intl.formatMessage({
        id: `hubs.dispatch_rto.group_by.shelves`
      }),
      value: SHELVES
    }
  ];

  const renderedGroupedByOptions = groupedByOptions.map(({ label, value }) => (
    <Option key={value} value={value}>
      {label}
    </Option>
  ));

  const handleTrackingNumbersSearch = ({ target: { value } }) => {
    if (value) {
      if (!isNaN(value)) {
        const results = [];
        rtoList.forEach(({ deliveries, ...rest }) => {
          const filteredDeliveries = deliveries.filter(({ trackingNumber }) =>
            trackingNumber.includes(value)
          );
          if (filteredDeliveries.length) {
            results.push({
              ...rest,
              deliveries: filteredDeliveries
            });
          }
        });
        setRTOSearchResults(results);
      } else {
        notify(
          intl.formatMessage({
            id: 'hubs.dispatch_rto.scan.error_msgs.not_numeric'
          }),
          'error',
          true
        );
      }
    } else {
      setRTOSearchResults(rtoList);
    }
  };

  const exportDataAsXlsx = async () => {
    setIsLoading(true);
    try {
      const payload = {
        trackingNumbers: selectedTrackingNumbers,
        reportType: RTO_DELIVERIES_REPORT
      };

      const { msg } = await exportXlsx(payload);
      notify(msg, 'success');
    } catch ({ message }) {
      notify(message);
    }
    setIsLoading(false);
  };

  const handleOnExportClick = () => {
    sendSegment('EXPORT_ORDERS');
    if (selectedTrackingNumbers?.length) {
      exportDataAsXlsx();
    }
  };

  return (
    <div className="br-dispatch-rto_side-container_left_header">
      <Row>
        <Col span={12}>
          <h3 className="br-dispatch-rto_side-container_left_header_title">
            {intl.formatMessage({
              id: 'hubs.dispatch_rto.title'
            })}
          </h3>
        </Col>
        <Col span={12}>
          <Select
            labelInValue
            showSearch
            allowClear
            className="br-dispatch-rto_side-container_left_hub-dropdown"
            placeholder={intl.formatMessage({
              id: 'hubs.dispatch_rto.hub_dropdown_placeholder'
            })}
            value={selectedHub}
            optionFilterProp="children"
            disabled={currentUserHubId || isLoading}
            onSelect={({ label, value }) =>
              setSelectedHub({
                label,
                value
              })
            }
            onClear={handleClearSelectedHub}
            filterOption={(input, { children }) =>
              children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {renderedHubsOptions}
          </Select>
        </Col>
      </Row>
      <div className="br-dispatch-rto_side-container_left_header_actions-area">
        <div className="br-dispatch-rto_side-container_left_header_actions-area_input">
          <Input
            placeholder={intl.formatMessage({
              id: 'hubs.dispatch_rto.search_input_placeholder'
            })}
            prefix={<SearchOutlined />}
            disabled={isLoading || !rtoList.length}
            onChange={handleTrackingNumbersSearch}
          />
        </div>
        <div className="br-dispatch-rto_side-container_left_header_actions-area_btns">
          <Row>
            <Col span={14}>
              <div className="br-dispatch-rto_side-container_left_header_actions-area_btns_dropdown_label">
                {intl.formatMessage({
                  id: 'hubs.dispatch_rto.group_by.dropdown_placeholder'
                })}
                :
              </div>
              <Select
                labelInValue
                className="br-dispatch-rto_side-container_left_header_actions-area_btns_dropdown"
                placeholder={intl.formatMessage({
                  id: 'hubs.dispatch_rto.group_by.dropdown_placeholder'
                })}
                value={groupedBy}
                disabled={isLoading || !rtoList.length}
                onSelect={({ label, value }) => {
                  sendSegment('GROUP_BY', {
                    'Group by': label
                  });
                  setGroupedBy({ label, value });
                }}
              >
                {renderedGroupedByOptions}
              </Select>
            </Col>
            <Col span={10}>
              <Button
                className="br-dispatch-rto_side-container_left_header_actions-area_btns_export-btn"
                onClick={handleOnExportClick}
                disabled={isLoading || !rtoList.length}
                block
              >
                <Icon
                  component={DocumentDownload}
                  className="br-dispatch-rto_side-container_left_header_actions-area_btns_export-btn_icon"
                />
                {intl.formatMessage({
                  id: 'hubs.dispatch_rto.export_btn'
                })}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(DispatchRTOHeader);
