import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Collapse } from 'antd';
import classnames from 'classnames';

import DeliveryItem from 'components/Hub/PackagesDebrief/PackagesDebriefTabs/NewSwappingContainer/components/DeliveryItem/DeliveryItem';

const MutliPackageDeliveryItem = ({
  delivery,
  businessName,
  showExtraDataInReceivedTable,
  useWeights,
  handleSizeChange,
  handleOnRemoveReceived
}) => {
  const [activePanels, setActivePanels] = useState([]);

  const intl = useIntl();

  const handleOnPanelChange = (values) => {
    setActivePanels(values);
  };

  const renderMultiPackagesItems = (delivery) => {
    const { multiPackages, trackingNumber, scannedPackages = [] } = delivery;

    const packagesNumbers = Array.from(
      { length: multiPackages },
      (_, index) => index + 1
    );

    return packagesNumbers.map((packageNumber) => {
      const isScanned = scannedPackages.includes(packageNumber);

      return (
        <div className="br-swapping-container__multipackage-item">
          <span className="body-medium">{`${trackingNumber}/${packageNumber}`}</span>
          <span
            className={classnames(
              'br-swapping-container__multipackage-scan-status',
              {
                'br-swapping-container__multipackage-scanned': isScanned
              }
            )}
          >
            {intl.formatMessage({
              id: `hubs.receive_new_pickup.${
                isScanned ? 'scanned' : 'not_scanned'
              }`
            })}
          </span>
        </div>
      );
    });
  };

  return (
    <Collapse
      bordered={false}
      onChange={handleOnPanelChange}
      className="br-swapping-container__multipackage-collapse"
    >
      <Collapse.Panel
        key={delivery.trackingNumber}
        showArrow={false}
        header={
          <DeliveryItem
            delivery={delivery}
            businessName={businessName}
            showExtraDataInReceivedTable={showExtraDataInReceivedTable}
            useWeights={useWeights}
            handleSizeChange={handleSizeChange}
            handleOnRemoveReceived={handleOnRemoveReceived}
            isMultiPackagePanelActive={activePanels.includes(
              delivery.trackingNumber
            )}
          />
        }
      >
        {renderMultiPackagesItems(delivery)}
      </Collapse.Panel>
    </Collapse>
  );
};

export default MutliPackageDeliveryItem;
