import dayjs from 'dayjs';

import {
  states,
  stateColor,
  SIGN_AND_RETURN_PICKUP
} from 'constants/order-states';

import {
  orderTypes,
  PUDO_HUB_TYPE,
  PUDO_PROVIDER,
  PUDO_STATES
} from 'constants/shipments';
import { ORDER_TYPES_VALUE } from 'constants/shipment-details';

import { fmt, intl } from 'IntlWrapper/IntlWrapper';

class DeliveryStateFormatter {
  constructor() {
    this.isExchangeReturn = false;
  }
  getStateName = (delivery) => {
    const {
      type,
      state,
      trackingNumber,
      statesData,
      exceptionOutboundAction,
      outboundActions
    } = delivery;
    if (type?.code === 200) type.code = 10;
    const after = this.getCorrectAfterCode(delivery);

    if (!type || !after) return 'N/A';
    if (
      after >= 48 &&
      after !== 49 &&
      after !== 49.1 &&
      after !== 49.2 &&
      after !== 60
    ) {
      if (after === 48 && exceptionOutboundAction) {
        return states.OUTBOUND_ACTIONS(delivery)[
          exceptionOutboundAction?.action
        ].value;
      }
      return states.destructive(delivery)[after]?.value;
    }

    if (after === 47 || after === 47.1) {
      if (exceptionOutboundAction) {
        if (exceptionOutboundAction.exceptionCode === 12) {
          return states.OUTBOUND_ACTIONS(delivery)[12].value;
        }
        return states.OUTBOUND_ACTIONS(delivery)[
          exceptionOutboundAction?.action
        ].value;
      }
      if (state.lastExceptionCode) {
        return state.lastExceptionCode !== 11 &&
          states[type.code][after][state.lastExceptionCode]
          ? states[type.code][after][state.lastExceptionCode]?.value
          : states[type.code][after].default?.value;
      }
    } else
      return states[type.code][after] ? states[type.code][after].value : 'N/A';
  };

  getStateDescription = (delivery) => {
    const { state, type, exceptionOutboundAction } = delivery;
    const after = this.getCorrectAfterCode(delivery);
    if (!type || !after) return 'N/A';
    if (
      after >= 48 &&
      after !== 49 &&
      after !== 49.1 &&
      after !== 49.2 &&
      after !== 60
    ) {
      if (after === 48) {
        if (exceptionOutboundAction) {
          return states.OUTBOUND_ACTIONS(delivery)[
            exceptionOutboundAction?.action
          ].description;
        }
      }
      return states.destructive(delivery)[after]?.description;
    }

    if (after === 47 || after === 47.1) {
      if (exceptionOutboundAction) {
        if (exceptionOutboundAction.exceptionCode === 12) {
          return states.OUTBOUND_ACTIONS(delivery)[12].description;
        }
        return states.OUTBOUND_ACTIONS(delivery)[
          exceptionOutboundAction?.action
        ].description;
      }
      if (state.lastExceptionCode) {
        return state?.lastExceptionCode !== 11 &&
          states[type.code][after][state.lastExceptionCode]
          ? states[type.code][after][state.lastExceptionCode]?.description(
              delivery
            )
          : '';
      }
    } else
      return states[type.code][after]
        ? states[type.code][after]?.description(delivery)
        : 'N/A';
  };

  getStateColor = (delivery) => {
    let after = this.getCorrectAfterCode(delivery, true);
    const currentState = this.getStateName(delivery);

    if (
      currentState ===
        fmt({
          id: `orders.order_new_statues.canceled`
        }) ||
      currentState ===
        fmt({
          id: `orders.order_new_statues.ready_for_collection`
        }) ||
      currentState ===
        fmt({
          id: `orders.order_new_statues.rescheduled`
        })
    ) {
      after = 10;
    }
    if (
      currentState ===
        fmt({
          id: `orders.order_new_statues.new`
        }) ||
      currentState ===
        fmt({
          id: `orders.order_new_statues.pending_pickup`
        })
    ) {
      after = 9;
    }
    if (
      currentState ===
      fmt({
        id: `orders.order_new_statues.on_hold`
      })
    ) {
      after = 2;
    }
    if (after === 46 && delivery.type.code === 20) {
      after = 1;
    }

    return stateColor[after] ? stateColor[after] : 'NA';
  };

  formatSlaDate = (date, isRTO = false) => {
    const nowDate = Date.now();
    const scheduledDate = new Date(date);
    let dayDiff = Math.ceil(
      (scheduledDate.getTime() - nowDate) / (1000 * 3600 * 24)
    );

    if (dayDiff < 0) {
      dayDiff = `${fmt({
        id: 'orders.states_description.few_days'
      })}`;
    } else if (dayDiff === 1) {
      dayDiff = `${fmt({
        id: 'orders.states_description.tomorrow'
      })}`;
    } else if (dayDiff === 0) {
      dayDiff = `${fmt({
        id: 'orders.states_description.today'
      })}`;
    } else {
      dayDiff = `${fmt({
        id: 'orders.states_description.within'
      })} ${dayDiff} ${fmt({
        id: 'orders.states_description.days'
      })} `;
    }
    return dayDiff;
  };

  getCorrectAfterCode = (delivery, isColor = false) => {
    const {
      state,
      type,
      state_before,
      type_before,
      pendingPickup,
      outboundActions,
      integrationInfo
    } = delivery;
    let after = state?.code;
    switch (type?.code) {
      //send
      case 10:
        if (
          after === 10 &&
          (state_before === 45 ||
            (state_before === 47 && state.lastExceptionCode?.length))
        ) {
          after = 10.1;
        } else if (
          after === 24 &&
          integrationInfo?.provider === PUDO_PROVIDER &&
          state?.receivedAtWarehouse?.warehouse?.type === PUDO_HUB_TYPE
        ) {
          after = PUDO_STATES.RECEIVED_AT_WAREHOUSE;
        } else if (after === 24 && state.businessActionTaken === 1) {
          after = 24.2;
        } else if (after === 24 && state.businessActionTaken === 3) {
          after = 24.3;
        } else if (after === 24 && state.businessActionTaken === 4) {
          after = 24.4;
        } else if (
          after === 24 &&
          state.waitingForBusinessAction &&
          state?.lastExceptionCode
        ) {
          after = 47;
        } else if (after === 24 && type_before === 'RTO') {
          after = 10.2;
        } else if (after === 10 && pendingPickup) {
          after = 10.3;
        } else if (after === 49 && !delivery.sla && !delivery.scheduledAt) {
          after = 49.1;
        } else if (
          after === 24 &&
          (state_before === 45 ||
            (state_before === 47 && state.lastExceptionCode?.length))
        ) {
          after = 10.1;
        } else if (
          after === 47 &&
          state.lastExceptionCode === 4 &&
          !state.waitingForBusinessAction
        ) {
          delivery.state.lastExceptionCode = 4.1;
        } else if (
          after === 47 &&
          state.businessActionTaken === 3 &&
          !state.waitingForBusinessAction
        ) {
          delivery.state.lastExceptionCode = 13.1;
        } else if (
          after === 47 &&
          state.businessActionTaken === 4 &&
          !state.waitingForBusinessAction
        ) {
          delivery.state.lastExceptionCode = 14.1;
        } else if (
          after === 47 &&
          !state.waitingForBusinessAction &&
          state.lastExceptionCode === 13
        ) {
          delivery.state.lastExceptionCode = 13.2;
        } else if (
          after === 47 &&
          state.lastExceptionCode === 14 &&
          !state.waitingForBusinessAction
        ) {
          delivery.state.lastExceptionCode = 14.2;
        } else if (
          after === 41 &&
          integrationInfo?.provider === PUDO_PROVIDER
        ) {
          after = PUDO_STATES.READY_TO_PICK;
        } else if (
          after === 45 &&
          integrationInfo?.provider === PUDO_PROVIDER
        ) {
          after = PUDO_STATES.DELIVERD;
        }

        break;

      //cash collection
      case 15:
        if (
          after === 11 &&
          (state_before === 45 ||
            (state_before === 47 && state.lastExceptionCode?.length))
        ) {
          after = 10.1;
        } else if (
          after === 47 &&
          state.businessActionTaken === 3 &&
          !state.waitingForBusinessAction
        ) {
          delivery.state.lastExceptionCode = 13.1;
        } else if (
          after === 47 &&
          state.businessActionTaken === 4 &&
          !state.waitingForBusinessAction
        ) {
          delivery.state.lastExceptionCode = 14.1;
        } else if (
          after === 47 &&
          !state.waitingForBusinessAction &&
          state.lastExceptionCode === 13
        ) {
          delivery.state.lastExceptionCode = 13.2;
        } else if (
          after === 47 &&
          state.lastExceptionCode === 14 &&
          !state.waitingForBusinessAction
        ) {
          delivery.state.lastExceptionCode = 14.2;
        } else if (
          after === 48 &&
          outboundActions &&
          outboundActions[0]?.action === 'Return to shipper'
        ) {
          after = 48.1;
        }
        break;

      // RTO
      case 20:
        if (
          delivery.state.businessActionTaken === 2 &&
          (after === 23 || after === 47)
        ) {
          after = 24.1;
        }
        if (after === 49) {
          if (type_before === ORDER_TYPES_VALUE.EXCHANGE) {
            after = 49.1;
          }
        }
        if (
          after === 24 &&
          state.lastExceptionCode?.length &&
          [26, 27, 28, 29, 30].includes(state.lastExceptionCode)
        ) {
          after = 24.2;
        }
        if (
          delivery.state.waitingForBusinessAction &&
          ![24, 46, 60, 103].includes(after)
        ) {
          after = 24.1;
        } else if (after === 47 && delivery.state.exception[0].code === 24) {
          after = 47.24;
        }
        if (after === 47 && delivery.state.lastExceptionCode === 24) {
          after = 47.24;
        }
        if (after === 103 && delivery.state.lastExceptionCode === 24) {
          after = 103.1;
        }
        if (after === 105 && delivery.state.lastExceptionCode === 24) {
          after = 105.1;
        }
        if (after === 46 && integrationInfo?.provider === PUDO_PROVIDER) {
          after = PUDO_STATES.CLOSED;
        }
        break;

      // crp
      case 25:
        if (
          after === 10 &&
          (state_before === 22 ||
            state_before === 24 ||
            state_before === 23 ||
            (state_before === 47 && state.lastExceptionCode?.length))
        ) {
          after = 10.1;
        } else if (after === 20 && delivery.collectedFromConsignee) {
          after = 20.1;
        } else if (after === 49 && !delivery.sla && !delivery.scheduledAt) {
          after = 49.1;
        } else if (
          after === 47 &&
          state.businessActionTaken === 3 &&
          !state.waitingForBusinessAction
        ) {
          delivery.state.lastExceptionCode = 13.1;
        } else if (
          after === 47 &&
          state.businessActionTaken === 4 &&
          !state.waitingForBusinessAction
        ) {
          delivery.state.lastExceptionCode = 14.1;
        } else if (
          after === 47 &&
          !state.waitingForBusinessAction &&
          state.lastExceptionCode === 13
        ) {
          delivery.state.lastExceptionCode = 13.2;
        } else if (
          after === 47 &&
          state.lastExceptionCode === 14 &&
          !state.waitingForBusinessAction
        ) {
          delivery.state.lastExceptionCode = 14.2;
        } else if (
          after === 48 &&
          outboundActions &&
          outboundActions[0]?.action === 'Return to shipper'
        ) {
          after = 48.1;
        } else if (after === 47 && delivery.state.exception[0].code === 24) {
          after = 47.24;
        }
        if (after === 47 && delivery.state.lastExceptionCode === 24) {
          after = 47.24;
        }
        if (after === 103 && delivery.state.lastExceptionCode === 24) {
          after = 103.1;
        }
        if (after === 105 && delivery.state.lastExceptionCode === 24) {
          after = 105.1;
        }
        break;

      //exchange
      case 30:
        if (
          after === 24 &&
          ((state_before === 47 && state.lastExceptionCode?.length) ||
            state_before === 24 ||
            state_before === 23)
        ) {
          after = 10.1;
        } else if (after === 10 && pendingPickup) {
          after = 10.3;
        } else if (after === 20 && delivery.collectedFromConsignee) {
          after = 20.1;
        } else if (after === 24 && state.businessActionTaken === 1) {
          after = 24.2;
        } else if (after === 24 && state.businessActionTaken === 3) {
          after = 24.3;
        } else if (after === 24 && state.businessActionTaken === 4) {
          after = 24.4;
        } else if (
          after === 24 &&
          state.waitingForBusinessAction &&
          state?.lastExceptionCode
        ) {
          after = 47;
        } else if (after === 24 && type_before === 'RTO') {
          after = 10.2;
        } else if (after === 49 && !delivery.sla && !delivery.scheduledAt) {
          after = 49.3;
        } else if (after === 49) {
          after = 49.2;
        } else if (
          after === 47 &&
          state.lastExceptionCode === 4 &&
          !state.waitingForBusinessAction
        ) {
          delivery.state.lastExceptionCode = 4.1;
        } else if (
          after === 47 &&
          state.businessActionTaken === 3 &&
          !state.waitingForBusinessAction
        ) {
          delivery.state.lastExceptionCode = 13.1;
        } else if (
          after === 47 &&
          state.businessActionTaken === 4 &&
          !state.waitingForBusinessAction
        ) {
          delivery.state.lastExceptionCode = 14.1;
        } else if (
          after === 47 &&
          !state.waitingForBusinessAction &&
          state.lastExceptionCode === 13
        ) {
          delivery.state.lastExceptionCode = 13.2;
        } else if (
          after === 47 &&
          state.lastExceptionCode === 14 &&
          !state.waitingForBusinessAction
        ) {
          delivery.state.lastExceptionCode = 14.2;
        }
        if (after === 47 && delivery.state.lastExceptionCode === 24) {
          after = 47.24;
        }
        if (after === 103 && delivery.state.lastExceptionCode === 24) {
          after = 103.1;
        }
        if (after === 105 && delivery.state.lastExceptionCode === 24) {
          after = 105.1;
        }
        break;
      //sign&return
      case 35:
        if (after === 10 && pendingPickup) {
          after = 10.3;
        } else if (
          after === 47 &&
          state.lastExceptionCode === 4 &&
          !state.waitingForBusinessAction
        ) {
          delivery.state.lastExceptionCode = 4.1;
        } else if (
          after === 47 &&
          state.businessActionTaken === 3 &&
          !state.waitingForBusinessAction
        ) {
          delivery.state.lastExceptionCode = 13.1;
        } else if (
          after === 47 &&
          state.businessActionTaken === 4 &&
          !state.waitingForBusinessAction
        ) {
          delivery.state.lastExceptionCode = 14.1;
        } else if (
          after === 47 &&
          !state.waitingForBusinessAction &&
          state.lastExceptionCode === 13
        ) {
          delivery.state.lastExceptionCode = 13.2;
        } else if (
          after === 47 &&
          state.lastExceptionCode === 14 &&
          !state.waitingForBusinessAction
        ) {
          delivery.state.lastExceptionCode = 14.2;
        } else if (after === 20 && delivery.collectedFromConsignee) {
          after = 20.1;
        } else if (after === 24 && delivery.collectedFromConsignee) {
          after = 24.5;
        } else if (after === 30 && delivery.collectedFromConsignee) {
          after = 30.1;
        } else if (
          after === 20 &&
          delivery.pickupRequestType === SIGN_AND_RETURN_PICKUP
        ) {
          after = 20.2;
        } else if (
          after === 22 &&
          delivery.pickupRequestType === SIGN_AND_RETURN_PICKUP
        ) {
          after = 22.1;
        } else if (
          after === 23 &&
          delivery.pickupRequestType === SIGN_AND_RETURN_PICKUP
        ) {
          after = 23.1;
        } else if (
          after === 47 &&
          delivery.pickupRequestType === SIGN_AND_RETURN_PICKUP
        ) {
          after = 47.1;
        } else if (after === 47 && delivery.state.exception[0].code === 24) {
          after = 47.24;
        }
        if (after === 47 && delivery.state.lastExceptionCode === 24) {
          after = 47.24;
        }
        if (after === 103 && delivery.state.lastExceptionCode === 24) {
          after = 103.1;
        }
        if (after === 105 && delivery.state.lastExceptionCode === 24) {
          after = 105.1;
        }

      default:
        break;
    }

    if (isColor) {
      if (
        after === 47 &&
        (state?.lastExceptionCode === 6 ||
          state?.lastExceptionCode === 10 ||
          state?.lastExceptionCode === 8 ||
          state?.lastExceptionCode === 4 ||
          state?.lastExceptionCode === 9)
      ) {
        after = 49;
      }
    }
    return after;
  };

  _formatDate = (date, hideTime = false) => {
    let newDate;
    if (hideTime) {
      newDate = dayjs(date).tz().format('DD MMMM YYYY');
    } else {
      newDate = dayjs(date).tz().format('DD MMM YYYY  h:mm a');
    }
    return newDate;
  };

  _formatAfterAddress = (customerAddress) => {
    let formattedAddress = '';
    if (customerAddress?.firstLine)
      formattedAddress += `${fmt({ id: 'location_form_labels.street' })}: ${
        customerAddress.firstLine
      }, `;
    if (customerAddress?.secondLine)
      formattedAddress += `${fmt({ id: 'location_form_labels.landmarks' })}: ${
        customerAddress.secondLine
      }, `;
    if (customerAddress?.district?.name)
      formattedAddress += `${fmt({ id: 'location_form_labels.district' })}: ${
        intl.locale === 'en'
          ? customerAddress.district.name
          : customerAddress.district.nameAr
      }, `;
    // if (customerAddress.zone?.name) formattedAddress += `zone: ${customerAddress.zone.name}, `;
    if (customerAddress?.city?.name)
      formattedAddress += `${fmt({ id: 'location_form_labels.city' })}: ${
        intl.locale === 'en'
          ? customerAddress.city.name
          : customerAddress.city.nameAr
      }`;

    if (formattedAddress !== '') {
      return formattedAddress;
    } else {
      return fmt({ id: 'common.empty_field' });
    }
  };
}
export default new DeliveryStateFormatter();
