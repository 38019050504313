import dayjs from 'dayjs';

import { DAYS_OF_WEEK } from 'constants/helper';
import { PICKUP_CUTOFF_TIME } from 'constants/pickups';

export const pickupsStatus = {
  Created: ['Requested'],
  'In progress': ['Route Assigned', 'Picking up'],
  Completed: ['Picked up'],
  Canceled: ['Canceled', 'Exception']
};

export const mapStateToStatus = (state) => {
  return Object.keys(pickupsStatus).find((key) =>
    pickupsStatus[key].includes(state)
  );
};

export const disabledDate = (current, date, holidaysDates) => {
  const hours = date.getHours();
  const isFriday = dayjs(current).day() === DAYS_OF_WEEK.FRIDAY;

  const isHoliday = holidaysDates?.some((holiday) =>
    current.isSame(holiday, 'day')
  );

  if (isHoliday || isFriday) {
    return true;
  }

  if (hours >= PICKUP_CUTOFF_TIME) {
    return current && current.valueOf() <= date.setDate(date.getDate());
  }
  return current && current.valueOf() < date.setDate(date.getDate() - 1);
};

export const mapCancelReason = {
  'لم استطيع الوصول للبيزنس': 'business_unreachable',
  "Can't reach business location": 'business_unreachable',
  'Rescheduled as per business request': 'business_reschedule',
  'تم التاجيل بناء على طلب البيزنس': 'business_reschedule',
  'Orders have no tracking numbers': 'orders_no_tn',
  'الاوردرات بدون رقم تتبع': 'orders_no_tn',
  'البيزنس يحتاج فلاير': 'business_flyer',
  'Business has no packing': 'business_flyer',
  'Orders are not ready for pickup': 'orders_not_ready',
  'الاوردرات غير جاهزة': 'orders_not_ready',
  'Failed to pickup orders': 'orders_failed',
  'تم الفشل فى استلام الاوردرات': 'orders_failed',
  'The business canceled the pickup': 'business_cancelled',
  'البيزنس قام بالالغاء': 'business_cancelled',
  'Canceled By System - The business deleted the created orders, leaving no new orders remaining to be picked':
    'system_cancelled'
};

export const UTCWithZeroTime = (date) => dayjs.utc(date).format('YYYY-MM-DD');
