import { fmt } from 'components/IntlWrapper/IntlWrapper';

export const THREE_PL_INTEGRATION_PAGE_ACCESS = {
  EG: false,
  SA: true,
  DEFAULT: false
};

export const NEW_HUB_TRANSFER_PAGE_ACCESS = {
  EG: true,
  SA: false,
  DEFAULT: true
};

export const BARCODES_SHEET_TYPES = {
  A5: 'A5',
  ZEBRA: 'ZEBRA',
  CSV: 'CSV'
};

export const BARCODES_SHEET_TYPE_OPTIONS = {
  EG: [
    {
      label: fmt({ id: 'barcode_to_awb.types.a5_sheets' }),
      value: BARCODES_SHEET_TYPES.A5
    },
    {
      label: fmt({ id: 'barcode_to_awb.types.zebra_sheets' }),
      value: BARCODES_SHEET_TYPES.ZEBRA
    },
    {
      label: fmt({ id: 'barcode_to_awb.types.barcodes_csv' }),
      value: BARCODES_SHEET_TYPES.CSV
    }
  ],
  SA: [
    {
      label: fmt({ id: 'barcode_to_awb.types.barcodes_csv' }),
      value: BARCODES_SHEET_TYPES.CSV
    }
  ]
};

export const RECEIVE_FROM_BINS_AVAILABLE = {
  EG: true,
  SA: false
};

export const VALU_OPTION_AVAILABLE = {
  EG: true,
  SA: false,
  AE: false,
  QA: false
};

export const FAWRY_OPTION_AVAILABLE = {
  SA: false,
  EG: true,
  QA: false
};

export const POS_OPTION_AVAILABLE = {
  SA: true,
  EG: false
};
export const FIRST_MILE_VALIDATION_AVAILABLE = {
  SA: false,
  EG: true
};
