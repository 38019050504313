import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Table,
  Input,
  InputNumber,
  Form,
  Button,
  DatePicker,
  Select
} from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { getDebriefCashDepositTypes } from 'common/countries/countries-mapping';

const EditableContext = React.createContext();
const userInfo = JSON.parse(localStorage.getItem('userInfo'));

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  type,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    // form.setFieldsValue({
    //   [dataIndex]: record[dataIndex],
    // });
  };

  const save = async (e) => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values
      });
    } catch (errInfo) {}
  };
  let childNode = children;

  let formControl;
  switch (type) {
    case 'number':
      formControl = (
        <InputNumber
          style={{ width: 180 }}
          ref={inputRef}
          // onChange={save}
          onPressEnter={save}
          onBlur={save}
        />
      );
      break;
    case 'select':
      formControl = (
        <Select
          style={{ width: 180 }}
          onChange={save}
          // onBlur={save}
          ref={inputRef}
        >
          {getDebriefCashDepositTypes().map((el) => (
            <Select.Option value={el} key={el}>
              {el}
            </Select.Option>
          ))}
        </Select>
      );
      break;
    case 'date':
      formControl = (
        <DatePicker
          style={{ width: 180 }}
          ref={inputRef}
          onChange={save}
          // onBlur={save}
          format="YYYY-MM-DD"
          // defaultValue={moment().format('YYYY-MM-DD')}
          // value={moment().format('YYYY-MM-DD')}
        />
      );
      break;
    default:
      formControl = (
        <Input
          style={{ width: 180 }}
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          // onChange={save}
        />
      );
      break;
  }
  if (editable) {
    childNode = (
      /* editing ? */ <Form.Item
        style={{
          margin: 0
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`
          }
        ]}
        initialValue={record[dataIndex]}
        value={record[dataIndex]}
      >
        {formControl}
        {/* <Input ref={inputRef} onPressEnter={save} onBlur={save}/> */}
      </Form.Item>
    ); // : (children);
  }

  return <td {...restProps}>{childNode}</td>;
};

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: 'Deposit Type',
        dataIndex: 'depositType',
        width: '20%',
        type: 'select',
        editable: true
      },
      {
        title: 'Receipt No.',
        dataIndex: 'receiptNo',
        width: '20%',
        editable: true
      },
      {
        title: 'Receipt Date',
        dataIndex: 'receiptDate',
        width: '20%',
        type: 'date',
        editable: true
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        width: '20%',
        type: 'number',
        editable: true
      },
      {
        title: 'operation',
        dataIndex: 'operation',
        width: '10%',
        render: (text, record) => {
          const { dataSource } = this.state;
          return dataSource.length >= 1 ? (
            <DeleteOutlined
              title="Delete"
              style={{ color: '#d7262c' }}
              onClick={() => this.handleDelete(record.key)}
            />
          ) : null;
        }
      }
    ];
    this.state = {
      dataSource: [],
      count: 0
    };
  }

  handleDelete = (key) => {
    const { dataSource } = this.state;
    this.setState({
      dataSource: dataSource.filter((item) => item.key !== key)
    });
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    const newData = {
      key: count,
      depositType: '',
      receiptNo: '',
      receiptDate: dayjs(),
      amount: ''
    };
    this.setState({
      dataSource: [...dataSource, newData],
      count: count + 1
    });
  };

  handleSave = (row) => {
    const { dataSource } = this.state;
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    const { setData } = this.props;
    setData(newData);
    this.setState({
      dataSource: newData
    });
  };

  clear = () => {
    this.setState({ dataSource: [] });
  };

  render() {
    const { dataSource } = this.state;
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell
      }
    };
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          type: col.type,
          handleSave: this.handleSave
        })
      };
    });
    return (
      <div>
        <Table
          components={components}
          rowClassName={() => 'editable-row'}
          dataSource={dataSource}
          columns={columns}
          footer={() => (
            <div className="overflow-auto">
              <Button
                className="mb-2"
                type="primary"
                shape="circle"
                size="large"
                title="Add"
                style={{ backgroundColor: '#d7262c' }}
                onClick={this.handleAdd}
                disabled={this.props.disableButton}
              >
                <PlusOutlined />
              </Button>
            </div>
          )}
        />
      </div>
    );
  }
}

class CodDebrief extends React.Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
  }

  clear = () => {
    this.tableRef.current.clear();
  };

  disableConfirmCashCollectionButton = () => {
    const countryObject = JSON.parse(
      localStorage.getItem('userAutoCodCollection')
    );
    return countryObject ? countryObject.autoCODCollection : false;
  };
  render() {
    const {
      confirmCashCollection,
      setDebriefingData = () => {},
      debreifingData
    } = this.props;
    return (
      <>
        <EditableTable
          ref={this.tableRef}
          setData={setDebriefingData}
          data={debreifingData}
          disableButton={this.disableConfirmCashCollectionButton()}
        />
        <div>
          <Button
            className="primaryButton"
            onClick={confirmCashCollection}
            disabled={this.disableConfirmCashCollectionButton()}
          >
            Confirm Cash Collection
          </Button>
        </div>
      </>
    );
  }
}

export default CodDebrief;
